import React, { useState } from "react";
import { useAuth } from "../../shared/hooks/authentication/useAuth";
import { StyledLogoImg, StyledSignInForm, StyledSignInGrid, StyledSignInSide } from "./SignIn.styles";
import { LoadingButton } from "@mui/lab";
import { Alert, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Theme } from "@mui/material/styles";
import { Env } from "../../shared/Env";
import logoImg from "../../../img/sinnefo_logo.png";
import { Visibility, VisibilityOff } from "@mui/icons-material";

interface SignInForm {
  readonly username: string;
  readonly password: string;
}

export const SignIn: React.FC = () => {
  const { t } = useTranslation([ "signIn", "errors" ]);
  const { isLoading, error, signIn } = useAuth();
  const [ showPassword, setShowPassword ] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const { handleSubmit, control } = useForm<SignInForm>({
    defaultValues: {
      username: "",
      password: "",
    },
  });
  const onSubmit = (data: SignInForm, event?: React.BaseSyntheticEvent) => {
    event?.preventDefault();
    signIn(data.username, data.password);
  };

  return (
    <StyledSignInGrid>
      <StyledSignInSide
        sx={{
          boxShadow: 16,
          bgcolor: (theme: Theme) => theme.palette.primary.light,
        }}
      >
        <h1>{Env.appName}</h1>
        <Typography fontWeight={700}>{Env.appVersion}</Typography>
      </StyledSignInSide>
      <StyledSignInForm onSubmit={handleSubmit(onSubmit)}>
        <StyledLogoImg
          src={logoImg}
          loading="lazy"
        />
        {error && <Alert severity="error">{t("signIn:auth_error")}</Alert>}
        <Controller
          rules={{ required: true }}
          render={({
            field,
            fieldState: { error },
          }) => <TextField
            {...field}
            type="email"
            disabled={isLoading}
            label={t("signIn:email")}
            helperText={error ? error.message : null}
            error={!!error} />}
          name="username"
          control={control}
        />
        <Controller
          rules={{ required: true }}
          render={({
            field,
            fieldState: { error },
          }) =>
            <TextField
              {...field}
              type={showPassword ? "text" : "password"}
              disabled={isLoading}
              label={t("signIn:password")}
              helperText={error ? error.message : null}
              error={!!error}
              InputProps={{
                endAdornment:
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleClickShowPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>,

              }}
            />}
          name="password"
          control={control}
        />

        <LoadingButton type="submit" loading={isLoading} variant="contained" size="large">
          {t("signIn:action")}
        </LoadingButton>
      </StyledSignInForm>
    </StyledSignInGrid>);
};
