import React, { useRef } from "react";
import { Fade, Paper, Popover, PopoverActions } from "@mui/material";
import { CheckBoxTree } from "./CheckBoxTree";
import { PointOfSaleModel } from "../../../../../domain/features/organizations/models/PointOfSaleModel";
import { theme } from "../../../../../muiTheme";

interface OrganizationTreeModalProps {
  isModalOpen: boolean;
  handleClose: VoidFunction;
  pointsOfSale: undefined | PointOfSaleModel;
  disabled: boolean;
  isEdit: boolean;
  anchorEl: Element;
  selectedIds: number[];
  setSelectedIds?: React.Dispatch<React.SetStateAction<number[]>>;
  treeExpanded?: boolean;
}

const StyledPaper = ({ children }: { children?: React.ReactChild}) => 
  <Paper
    elevation={0}
    variant="outlined"
    sx={{
      //"&::-webkit-scrollbar": { display: "none" },
      //scrollbarWidth: "none",
      direction: "rtl",
      maxHeight: "80vh", 
      overflowY: "scroll", 
      background: theme.palette.grey[50],
      boxShadow: "0px 0px 4px rgba(0,0,0,0.1)",
      border: `1px solid ${theme.palette.grey[400]}`, 
      py: 1,
      px: 2,
    }}
  >
    {children}
  </Paper>;

export const OrganizationTreeModal: React.FC<OrganizationTreeModalProps> = ({
  isModalOpen,
  handleClose,
  pointsOfSale,
  disabled,
  anchorEl,
  selectedIds,
  setSelectedIds,
  isEdit,
  treeExpanded,
}) => {
  const popoverActions = useRef<PopoverActions>(null);
  return (
    <Popover 
      action={popoverActions}
      elevation={0}
      TransitionComponent={Fade}
      open={isModalOpen} 
      onClose={handleClose} 
      anchorEl={anchorEl} 
      anchorOrigin={{ vertical: "center", horizontal: "left" }}
      transformOrigin={{ vertical: "center", horizontal: "right" }}
      PaperProps={{ sx: { overflow: "visible" } }}
      marginThreshold={8}
      sx={{ p: 1 }}
    >
      <StyledPaper>
        <CheckBoxTree
          pointsOfSale={pointsOfSale}
          disabled={disabled}
          selectedIds={selectedIds}
          setSelectedIds={setSelectedIds}
          expanded={treeExpanded}
        />
      </StyledPaper>
    </Popover>
  );
};
