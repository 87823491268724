/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CsvCheckResponse } from '../models/CsvCheckResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ManagerApiToTestFileService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Test csv for import
   * @returns CsvCheckResponse OK
   * @throws ApiError
   */
  public post2({
    type = 'ITEM',
    formData,
  }: {
    type?: 'BUSINESS_PARTNER_GROUP' | 'BUSINESS_PARTNER' | 'ITEM_GROUP' | 'ITEM' | 'PRICE_LIST' | 'ORGANIZATION_TREE' | 'CONNECT_ELEMENT_TO_GROUP',
    formData?: {
      multipartFile: Blob;
    },
  }): CancelablePromise<CsvCheckResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/manager/csv/test/{type}',
      path: {
        'type': type,
      },
      formData: formData,
      mediaType: 'multipart/form-data',
      errors: {
        400: `Bad Request`,
        401: `Failed to decode authentication token`,
        404: `Entity Not found`,
        405: `Method Not Allowed`,
        409: `Data integrity violation`,
        417: `Expectation Failed`,
        500: `General I/O error`,
      },
    });
  }

}