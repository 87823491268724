import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";

import { IStyledTheme } from "../../../../shared/interfaces";

export const StyledAddPeriodForm = styled("form")(() => (
  { "& .MuiInputBase-root:first-of-type": { marginRight: "2rem" } }
));

export const StyledSearchWrap = styled("div")(() => (
  { marginBottom: "1rem" }
));

export const StyledBox = styled(Box)(({ theme }: IStyledTheme) => (
  {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50vw",
    backgroundColor: theme.palette.background.paper,
    boxShadow: 24,
    padding: "4rem",
    borderRadius: theme.shape.borderRadius,
  }
));

export const StyledCloseIcon = styled(CloseIcon)(() => (
  { 
    position: "absolute",
    right: "1rem",
    top: "1rem",
    cursor: "pointer",
  }
));

