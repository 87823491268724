import { deepClone } from "../../../utils/Functions";

/**
 * Allows you to listen a state machine
 */
export type StateMachineListener<TState> = (state: TState) => void;

/**
 * Allows you to remove listener from state machine
 */
export type ListenerRemover = () => void;

/**
 * Allows you to extract data from the state.
 */
export type StateMachineSelector<T, R> = (state: T) => R;

// Internal use of the library, not to be used
export abstract class StateMachineBase<TState> {
  abstract get state(): TState;

  /**
   * Allows you to extract data from the state, using a selector function.
   *
   * See hooks:
   * - @see useMachineStateSelector
   * - @see useMachineStateListener
   * Example:
   * const valueState = useMachineStateSelector(machine.select((state) => state.value);
   * @param selector
   */
  select<R>(selector: StateMachineSelector<TState, R>): StateMachineBase<R> {
    return new SelectMachineState(this, selector);
  }

  // Internal use of the library, not to be used
  abstract addListener(listener: StateMachineListener<TState>): ListenerRemover;
}

/**
 * A state machine that allows you to change the state by receiving updates
 *
 * Hooks:
 * - @see useStateMachineCreator
 * - @see useMachineStateSelector
 * - @see useMachineStateListener
 */
export class StateMachine<TState> extends StateMachineBase<TState> {
  private readonly __listeners: Array<StateMachineListener<TState>> = [];
  private __state: TState;

  /**
   * Implements the constructor by passing the initial state of the state machine
   *
   * Example:
   * class MyStateMachine extends StateMachine<TState> {
   *   constructor() {
   *     super(MyStateMachine.#initialState);
   *   }
   * }
   * @param initialState
   * @protected
   */
  protected constructor(initialState: TState) {
    super();
    this.__state = deepClone(initialState);
  }

  /**
   * Use it to check if a mutation can run or to complete the status update
   *
   * It should not be used externally unless you want to use it to initialize an object
   *
   * Example of using internal:
   * updateValue(value: string) {
   *   if (this.state.isLoading) return;
   *   // ...
   * }
   * Example of using external:
   * const machine = useStateMachineCreator(() => new FieldFormMachine());
   * const methods = useForm<IFormValues>({ defaultValues: { field: machine.state.field } });
   */
  get state(): TState {
    return deepClone(this.__state);
  }

  /**
   * Update current state with a new state.
   *
   * Example:
   * updateValue(value: string) {
   *   this.updateState({
   *     ...this.state,
   *     value,
   *   });
   * }
   * @param state It should be the entire previous state with the updates
   * @protected
   */
  protected updateState(state: TState): void {
    this.__state = deepClone(state);
    for (const listener of this.__listeners) {
      listener(deepClone(state));
    }
  }

  // Internal use of the library, not to be used
  addListener(listener: StateMachineListener<TState>): ListenerRemover {
    this.__listeners.push(listener);
    return () => {
      this.__listeners.splice(this.__listeners.indexOf(listener), 1);
    };
  }
}

class SelectMachineState<T, R> extends StateMachineBase<R> {
  private readonly __machine: StateMachineBase<T>;
  private readonly __selector: StateMachineSelector<T, R>;

  constructor(bloc: StateMachineBase<T>, selector: StateMachineSelector<T, R>) {
    super();
    this.__machine = bloc;
    this.__selector = selector;
  }

  get state(): R {
    return this.__selector(this.__machine.state);
  }

  addListener(listener: StateMachineListener<R>): ListenerRemover {
    return this.__machine.addListener((state) => {
      listener(this.__selector(state));
    });
  }
}
