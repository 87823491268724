import { Alert, Button, Stack, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useMutation } from "react-query";
import { GroupModel } from "../../../../domain/features/groups/models/GroupModel";
import { PromoRuleModelType } from "../../../../domain/features/promotions/models/PromoTypes";
import { UserModel } from "../../../../domain/features/users/models/UserModel";
import { groupActions } from "../../../shared/constants/Actions";
import { useToggler } from "../../../shared/hooks/useToggler";
import { StyledTableCell, StyledTableRow } from "../../promotions/promotions-table/PromotionsTable.styles";
import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";
import { AppUtils } from "../../../shared/AppUtils";
import { useTranslation } from "react-i18next";

interface GroupRowProps {
    item: GroupModel;
    user: UserModel | undefined;
    type: PromoRuleModelType;
    index: number;
    refetch: () => Promise<void>;
}
export const GroupRow = ({ item, user, type, index, refetch }: GroupRowProps) => {
  const { t } = useTranslation("groupsTable");
  const mutationKey = `editGroup.${type}.${index}.description`;
  const [ description, setDescription ] = useState(item.description);
  const { toggle, isOpen: isEditing, close } = useToggler();
  const { mutate, isLoading, error, isError } = useMutation(mutationKey, () => {
    return groupActions.changeDescription(user!.organizationId, type, {
      description: description,
      typeId: item.id,
    });
  }, { 
    async onSuccess() { 
      await refetch(); 
      close(); 
    }, 
  });
  const isSaveButtonDisabled = item.description === description || !description;
  return <>
    <StyledTableRow>
      <StyledTableCell>
        <Typography>{item.code}</Typography>
      </StyledTableCell>
      <StyledTableCell>
        {isEditing ? 
          <TextField 
            value={description} 
            size="small" 
            fullWidth
            onChange={(e) => setDescription(e.target.value)} 
          /> : 
          <Typography>
            {item.description}
          </Typography>
        }
      </StyledTableCell>
      <StyledTableCell>
        <Stack direction="row" spacing={1} justifyContent="center" justifySelf="flex-end">
          {isError && <Alert severity="error">{AppUtils.getErrorMessage(error)}</Alert>}
          {!isEditing && <Button variant="outlined" onClick={toggle}>
            {t("edit")}
          </Button>}
          {isEditing && <>
            <LoadingButton 
              loading={isLoading}
              variant={isSaveButtonDisabled ? "outlined" : "contained"}
              disabled={isSaveButtonDisabled} 
              disableElevation
              onClick={() => mutate()}
            >
              {t("save")}
            </LoadingButton>
            <Button variant="outlined" onClick={toggle}><CloseIcon /></Button>
          </>}
        </Stack>
      </StyledTableCell>
    </StyledTableRow>
  </>;
};