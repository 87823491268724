import { Alert, AlertTitle } from "@mui/material";
import { ReactNode } from "react";
import { AppUtils } from "../../AppUtils";
import { useSnackBarMessenger } from "./SnackBarMessenger";

interface ResultAlertProps {
  title: ReactNode;
  error?: unknown;
}

export const ResultAlert = ({ error, title }: ResultAlertProps) => {
  const messenger = useSnackBarMessenger();

  return <Alert onClose={messenger.close} severity={error === undefined ? "success" : "error"}>
    <AlertTitle>{title}</AlertTitle>
    {error && AppUtils.getErrorMessage(error)}
  </Alert>;
};
