/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OrganizationResponse } from '../models/OrganizationResponse';
import type { UserCollectionResponse } from '../models/UserCollectionResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class UserApiToManageOrganizationsService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get organization
   * @returns OrganizationResponse OK
   * @throws ApiError
   */
  public getOrganization({
    organizationId,
  }: {
    organizationId: number,
  }): CancelablePromise<OrganizationResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/user/organization/{organizationID}',
      path: {
        'organizationID': organizationId,
      },
      errors: {
        400: `Bad Request`,
        401: `Failed to decode authentication token`,
        404: `Entity Not found`,
        405: `Method Not Allowed`,
        409: `Data integrity violation`,
        417: `Expectation Failed`,
        500: `General I/O error`,
      },
    });
  }

  /**
   * Get all users info in organization
   * @returns UserCollectionResponse OK
   * @throws ApiError
   */
  public get({
    organizationId,
  }: {
    organizationId: number,
  }): CancelablePromise<UserCollectionResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/user/organization/{organizationID}/users',
      path: {
        'organizationID': organizationId,
      },
      errors: {
        400: `Bad Request`,
        401: `Failed to decode authentication token`,
        404: `Entity Not found`,
        405: `Method Not Allowed`,
        409: `Data integrity violation`,
        417: `Expectation Failed`,
        500: `General I/O error`,
      },
    });
  }

}