/* eslint-disable @typescript-eslint/no-empty-function,no-console */

class Logger {
  verbose(_message: unknown): void {}

  info(_message: unknown): void {}

  warn(_message: unknown, _error: unknown): void {}

  error(_message: unknown, _error: unknown): void {}
}

class ConsoleLogger extends Logger {
  verbose(message: unknown): void {
    console.log(message);
  }

  info(message: unknown): void {
    console.info(message);
  }

  warn(message: unknown, error: unknown): void {
    console.warn(error, message);
  }

  error(message: unknown, error: unknown): void {
    console.error(error, message);
  }
}

export const log: Logger = new ConsoleLogger();

