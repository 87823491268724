import { useMemo } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { isAppError } from "./domain/shared/failures/AppError";
import { SnackBarMessengerProvider } from "./presentation/shared/components/snack-bar/SnackBarMessenger";
import { useAuth } from "./presentation/shared/hooks/authentication/useAuth";
import { WrappedLoader } from "./presentation/shared/wrapped-loader/WrappedLoader";
import { AuthenticatedArea } from "./presentation/features/authenticated-area/AuthenticatedArea";
import { SignIn } from "./presentation/features/authentication/SignIn";

function App() {
  const { isDirty, isAuthenticated } = useAuth();

  // useQuery tries to fetch 2 times after the first error but do not try again if the error is (AppError) managed
  const queryClient = useMemo(() => new QueryClient({
    defaultOptions: {
      queries: {
        retry: (count, error) => {
          if (isAppError(error)) {
            return false;
          }
          return count < 2;
        },
      },
    },
  }), []);

  if (isDirty) {
    return <WrappedLoader />;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <SnackBarMessengerProvider>
        {isAuthenticated ? <AuthenticatedArea /> : <SignIn />}
      </SnackBarMessengerProvider>
    </QueryClientProvider>
  );
}

export default App;
