import { DependencyList, useEffect, useRef } from "react";

export const useValueChanges = <T>(value: T, listener: (value: T) => void, deps: DependencyList) => {
  const prev = useRef(value);

  useEffect(() => {
    if (prev.current === value) return;
    prev.current = value;
    listener(value);
  }, [ value, listener, prev, ...deps ]);
};
