import { Box, Button, Collapse, Stack } from "@mui/material";
import { Controller, UseFieldArrayRemove, useFormContext } from "react-hook-form";
import { GiftRulesFormMachine, RulesFormMachine } from "../../../../../../domain/features/promotions/state-machines/RulesFormMachine";
import { useMachineStateListener } from "../../../../../shared/hooks/useMachineStateListener";
import { useMachineStateSelector } from "../../../../../shared/hooks/useMachineStateSelector";
import { useStateMachineCreator } from "../../../../../shared/hooks/useStateMachineCreator";
import { useToggler } from "../../../../../shared/hooks/useToggler";
import { AutocompleteGroupItemsSingle } from "../filter-items-table/AutocompleteGroupItems";
import { FilterItemsTable } from "../filter-items-table/FilterItemsTable";
import { ExpandFiltersButton } from "../../../../../shared/expand-button/ExpandButton";
import { Delete } from "@mui/icons-material";
import { PromoRule } from "../../../../../../domain/features/promotions/state-machines/RuleFormState";
import { useTranslation } from "react-i18next";

interface GiftsFormItemProps {
  machine: GiftRulesFormMachine;
  index: number;
  remove: UseFieldArrayRemove;
  isReadOnly: boolean;
  initialRules: PromoRule<"COUPON" | "CAMPAIGN" | "PRICE">[];
  isAlone: boolean;
}

export const GiftsFormItem = ({ machine, index, remove, isReadOnly, initialRules, isAlone }: GiftsFormItemProps) => {
  const { t } = useTranslation("createPromo");
  const { isOpen, toggle } = useToggler(isReadOnly);
  const rulesMachine = useStateMachineCreator(() => {
    return new RulesFormMachine<"COUPON" | "CAMPAIGN" | "PRICE">({ initialType: "COUPON", initialRules, isReadOnly: isReadOnly });
  });
  const filtersLength = useMachineStateSelector(rulesMachine.select((state) => state.rules.length));
  const methods = useFormContext();
  useMachineStateListener(rulesMachine.select((state) => state.rules), (conditions) => {
    machine.changeConditions({ index, conditions });
  });
  useMachineStateListener(machine.select((state) => state.rules.at(index)), (rule) => {
    if (rule) {
      methods.setValue(`giftsRules.${index}`, {
        conditions: rule.conditions,
        itemID: rule.itemID,
        value: rule.value,
      });
    }
  });
  return <Stack alignItems="center" justifyContent="center" sx={{ p: 2 }}>
    <Stack direction="row" spacing={2} sx={{ width: "100%" }} alignItems="center">
      <Box sx={{ width: "100%" }}>
        <Controller
          name={`giftsRules.${index}.itemID`}
          render={({ field }) => 
            <AutocompleteGroupItemsSingle 
              onChange={(id) => machine.changeItemID({ index, id })}
              type="ITEM"
              disabled={isReadOnly}
              value={[ field.value ]}
              label={t("promo_autocomplete_label")}
              size="small"
            />
          }
        />
      </Box>
      <Box>
        <Stack direction="row" spacing={1}>
          <ExpandFiltersButton isOpen={isOpen} filtersLength={filtersLength} onClick={toggle} disabled={isReadOnly} />
          {!isReadOnly && <Button 
            disabled={isAlone}

            onClick={() => {
              remove(index);
              machine.removeRule(index);
            }}
            variant="outlined"
          >
            <Delete />
          </Button>}
        </Stack>
      </Box>
    </Stack>
    <Collapse in={isOpen} appear={false} sx={{ width: "100%" }} unmountOnExit>
      <Box sx={{ mt: "1rem", ml: "2px" }}>
        <Controller
          name={`giftRules.${index}.conditions`}
          render={() =>
            <FilterItemsTable
              isGifts={true}
              machine={rulesMachine}
              types={[ "COUPON", "CAMPAIGN", "PRICE" ]}
              withoutArticleField={false}
              isOpen={isOpen}
            />}
        />
      </Box>
    </Collapse>
  </Stack>;
};