/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { RuleResponse } from './RuleResponse';

export type DiscountResponse = {
  id: number;
  discount: number;
  max: number;
  rules: Array<RuleResponse>;
  dtype: DiscountResponse.dtype;
};

export namespace DiscountResponse {

  export enum dtype {
    AMOUNT = 'AMOUNT',
    PERCENTAGE = 'PERCENTAGE',
    FORCED_PRICE = 'FORCED_PRICE',
  }


}
