import { SxProps, Typography } from "@mui/material";
import { AsyncVoidFunction } from "../../../../../utils/Functions";
import { StyledTableRow, StyledTableCell } from "../../promotions-table/PromotionsTable.styles";
import { PromoPeriodModel } from "../../../../../domain/features/promotions/models/PromoPeriodModel";
import { useMutation } from "react-query";
import { useAuth } from "../../../../shared/hooks/authentication/useAuth";
import { promoPeriodActions } from "../../../../shared/constants/Actions";
import { StyledRowActionButton } from "../PeriodsPage.styles";
import React from "react";
import { useTranslation } from "react-i18next";
import { PromoModel } from "../../../../../domain/features/promotions/models/PromoModel";
import { useToggler } from "../../../../shared/hooks/useToggler";
import { PointOfSaleModel } from "../../../../../domain/features/organizations/models/PointOfSaleModel";
import { DragHandle as DragHandleIcon } from "@mui/icons-material";
import { OrganizationButton } from "../components/organization-button/OrganizationButton";
import { SortableHandle } from "react-sortable-hoc";

export interface ActivePeriodRowProps {
  period: PromoPeriodModel;
  refetchPeriods: AsyncVoidFunction;
  promotion:undefined|PromoModel;
  pointsOfSale: undefined | PointOfSaleModel;
  arrayIndex: number;
}

const DragHandle = SortableHandle<{style?: React.CSSProperties}>(({ style }, _) => 
  <DragHandleIcon style={{ cursor: "move" }} />
);

const SortableTableCell = (props: React.PropsWithChildren<any>) => <StyledTableCell {...props}
  sx={{
    display: "flex", 
    alignItems: "center", 
    justifyContent: "center", 
    height: "100%",
  }}
/>;

export const ActivePeriodRow: React.FC<ActivePeriodRowProps> = ({
  period,
  refetchPeriods,
  promotion,
  pointsOfSale,
  arrayIndex,
}) => {
  const { t } = useTranslation("promoPeriods");
  const { organization } = useAuth();
  const {
    isLoading: isDeactivating,
    mutate: deactivatePeriod,
  } = useMutation([ "deactivatePromoPeriod" ], async (periodId: number) => {
    await promoPeriodActions.deactivate(organization!, periodId);
    await refetchPeriods();
  });

  const {
    isOpen: isOrgTreeModalOpen,
    open: openOrgTreeModal,
    close: closeOrgTreeModal,
  } = useToggler();

  return (
    <StyledTableRow 
      sx={{
        position: "absolute", 
        display: "grid", 
        gridTemplateColumns: "repeat(7, 1fr)",
        top: (arrayIndex * 69.5),
      }}
    >
      <SortableTableCell>
        <DragHandle />
      </SortableTableCell>
      <SortableTableCell>
        <Typography>{promotion?.code}</Typography>
      </SortableTableCell>
      <SortableTableCell>
        <Typography noWrap={true}>{promotion?.description}</Typography>
      </SortableTableCell>
      <SortableTableCell>
        {period.startDate.toLocaleDateString()}
      </SortableTableCell>
      <SortableTableCell>
        {period.endDate.toLocaleDateString()}
      </SortableTableCell>
      <SortableTableCell>
        <OrganizationButton 
          isEdit={false}
          onClick={openOrgTreeModal} 
          isOpen={isOrgTreeModalOpen}
          selectedIds={period.activeIds}
          disabled={true}
          handleClose={closeOrgTreeModal}
          pointsOfSale={pointsOfSale}
          treeExpanded={true}
        />
      </SortableTableCell>
      <SortableTableCell>
        <StyledRowActionButton
          variant="outlined"
          loading={isDeactivating}
          onClick={() => deactivatePeriod(period.id)}
        >
          {t("deactivate")}
        </StyledRowActionButton>
      </SortableTableCell>
    </StyledTableRow>
  );
};
