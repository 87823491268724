import { Failure } from "../../domain/shared/failures/Failure";
import { isError } from "../../utils/Functions";

export class AppUtils {
  static getErrorMessage(error: unknown) {
    let message = "Unknown error";
    if (error instanceof Failure) {
      message = error.message;
    } else if (isError(error)) {
      message = `${error.name}: ${error.message}`;
    }
    if (message.includes("Method Not Allowed")) {
      message = "ATTENZIONE: Non hai la licenza necessaria per utilizzare questo tipo di promozione.";
    }
    return message;
  }
}
