import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { IStyledTheme } from "../../../../../shared/interfaces";

export const StyledTypography = styled(Typography)(({ theme }: IStyledTheme) => ({
  borderTop: `1px solid ${theme.palette.primary.light}`,
  margin: "4rem 0 2rem",
  padding: "1rem 3rem",
  backgroundColor: theme.palette.secondary.light,
  textAlign: "left",
  color: "#fff",
}));

export const StyledWrapper = styled("div")(() => ({ marginBottom: "2rem" }));
