import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

export const i18nInstance = i18n
  // load translation using http -> see /public/locales
  .use(Backend)
  // detect user language
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next);

// init i18next
void i18nInstance
  .init({
    detection: { order: [ "path", "navigator" ] }, // prioritize the language of user's browser
    fallbackLng: [ "en", "it", "de" ],
    supportedLngs: [ "en", "it", "de" ],
    react: {
      bindI18n: "languageChanged",
      bindI18nStore: "",
      transEmptyNodeValue: "",
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: [ "br", "strong", "i" ],
      useSuspense: true,
    },
    debug: false,
    backend: { allowMultiLoading: true },
    interpolation: {
      // prevent i18n from escaping, since it causes html entities not being rendered
      escape: (value) => value,
    },
  });

