/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ItemResponse = {
  id: number;
  itemCode: string;
  barCodes?: Array<string>;
  description: string;
  status?: ItemResponse.status;
  itemGroups?: Array<number>;
};

export namespace ItemResponse {

  export enum status {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
    BATCH = 'BATCH',
    SERIAL = 'SERIAL',
    NOT_WAREHOUSE = 'NOT_WAREHOUSE',
    WRONG_WAREHOUSE = 'WRONG_WAREHOUSE',
    NOT_FOUND = 'NOT_FOUND',
    NO_ITEMCODE = 'NO_ITEMCODE',
    SALE = 'SALE',
    PURCHASE = 'PURCHASE',
    DISCONTABLE = 'DISCONTABLE',
    REFUNDABLE = 'REFUNDABLE',
    RETAIL = 'RETAIL',
  }


}
