/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BridgeCollectionResponse } from '../models/BridgeCollectionResponse';
import type { BridgeResponse } from '../models/BridgeResponse';
import type { OrganizationPut } from '../models/OrganizationPut';
import type { TenantConfigPost } from '../models/TenantConfigPost';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AdminApiToManageBridgeConnectionService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Add organizations to bridges
   * @returns void
   * @throws ApiError
   */
  public put7({
    requestBody,
  }: {
    requestBody?: OrganizationPut,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/admin/bridges/organizations',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Failed to decode authentication token`,
        404: `Entity Not found`,
        405: `Method Not Allowed`,
        409: `Data integrity violation`,
        417: `Expectation Failed`,
        500: `General I/O error`,
      },
    });
  }

  /**
   * Create bridge
   * @returns void
   * @throws ApiError
   */
  public postBridge({
    bridgeName,
    email,
  }: {
    bridgeName: string,
    email: string,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/admin/bridge',
      query: {
        'bridgeName': bridgeName,
        'email': email,
      },
      errors: {
        400: `Bad Request`,
        401: `Failed to decode authentication token`,
        404: `Entity Not found`,
        405: `Method Not Allowed`,
        409: `Data integrity violation`,
        417: `Expectation Failed`,
        500: `General I/O error`,
      },
    });
  }

  /**
   * Force sync with bridge
   * @returns void
   * @throws ApiError
   */
  public postSync({
    tenant,
    bridgeName,
    type,
  }: {
    tenant: string,
    bridgeName: string,
    type?: 'ITEM' | 'DOCUMENT' | 'ORDER' | 'INVOICE' | 'BUSINESS_PARTNER' | 'WAREHOUSE' | 'BUSINESS_PARTNER_GROUP' | 'ITEM_GROUP' | 'PRICE_LIST' | 'INVENTORY_REPORT' | 'WAREHOUSE_LOCATION' | 'LANGUAGE',
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/admin/bridge/{bridgeName}/sync/{tenant}',
      path: {
        'tenant': tenant,
        'bridgeName': bridgeName,
      },
      query: {
        'type': type,
      },
      errors: {
        400: `Bad Request`,
        401: `Failed to decode authentication token`,
        404: `Entity Not found`,
        405: `Method Not Allowed`,
        409: `Data integrity violation`,
        417: `Expectation Failed`,
        500: `General I/O error`,
      },
    });
  }

  /**
   * Reset data bridge
   * @returns void
   * @throws ApiError
   */
  public postResetData({
    tenant,
    bridgeName,
  }: {
    tenant: string,
    bridgeName: string,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/admin/bridge/{bridgeName}/reset/{tenant}',
      path: {
        'tenant': tenant,
        'bridgeName': bridgeName,
      },
      errors: {
        400: `Bad Request`,
        401: `Failed to decode authentication token`,
        404: `Entity Not found`,
        405: `Method Not Allowed`,
        409: `Data integrity violation`,
        417: `Expectation Failed`,
        500: `General I/O error`,
      },
    });
  }

  /**
   * Add tenant for organization
   * @returns void
   * @throws ApiError
   */
  public postTenant({
    tenant,
    bridgeName,
    requestBody,
  }: {
    tenant: string,
    bridgeName: string,
    /** Request examples **/
    requestBody: TenantConfigPost,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/admin/bridge/{bridgeName}/organization/{tenant}',
      path: {
        'tenant': tenant,
        'bridgeName': bridgeName,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Failed to decode authentication token`,
        404: `Entity Not found`,
        405: `Method Not Allowed`,
        409: `Data integrity violation`,
        417: `Expectation Failed`,
        500: `General I/O error`,
      },
    });
  }

  /**
   * Get all bridges
   * @returns BridgeCollectionResponse OK
   * @throws ApiError
   */
  public get12(): CancelablePromise<BridgeCollectionResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/admin/bridges',
      errors: {
        400: `Bad Request`,
        401: `Failed to decode authentication token`,
        404: `Entity Not found`,
        405: `Method Not Allowed`,
        409: `Data integrity violation`,
        417: `Expectation Failed`,
        500: `General I/O error`,
      },
    });
  }

  /**
   * Get bridge
   * @returns BridgeResponse OK
   * @throws ApiError
   */
  public getBridge({
    bridgeName,
  }: {
    bridgeName: string,
  }): CancelablePromise<BridgeResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/admin/bridge/{bridgeName}',
      path: {
        'bridgeName': bridgeName,
      },
      errors: {
        400: `Bad Request`,
        401: `Failed to decode authentication token`,
        404: `Entity Not found`,
        405: `Method Not Allowed`,
        409: `Data integrity violation`,
        417: `Expectation Failed`,
        500: `General I/O error`,
      },
    });
  }

}