export const promoAmountModelTypes = [ "AMOUNT", "PERCENTAGE", "FORCED_PRICE" ] as const;
export type PromoAmountModelType = typeof promoAmountModelTypes[number];

export const promoApplyToModelTypes = [ "ITEM", "ITEM_GROUP", "ENTIRE_RECEIPT" ] as const;
export type PromoApplyToModelType = typeof promoApplyToModelTypes[number];

export const promoRuleModelItemTypes = [ "ITEM", "ITEM_GROUP", "COUPON", "CAMPAIGN" ] as const;
export type PromoRuleModelItemType = typeof promoRuleModelItemTypes[number];

export const promoRuleModelTypes = [ 
  "ITEM", 
  "ITEM_GROUP", 
  "BUSINESS_PARTNER", 
  "BUSINESS_PARTNER_GROUP", 
  "COUPON", 
  "CAMPAIGN", 
  "PRICE",
  "LOYALTY_LEVEL",
] as const;
export type PromoRuleModelType = typeof promoRuleModelTypes[number];

export const promoRuleModelConditions = [ "EQUAL", "NOT_EQUAL", "CONTAIN", "NOT_CONTAIN" ] as const;
export type PromoRuleModelCondition = typeof promoRuleModelConditions[number];

export const promoModelTypes = [ "TOTAL", "LESS_PRICE", "HIGH_PRICE", "ITEMS", "POINTS", "GIFTS" ] as const;
export type PromoModelType = typeof promoModelTypes[number];