import { DependencyList, useMemo } from "react";

/**
 * Allows you to create a state machine
 *
 * @param creator Returns a new instance of the state machine
 * @param deps
 */
export function useStateMachineCreator<TMachine>(creator: () => TMachine, deps: DependencyList | undefined = []): TMachine {
  return useMemo(creator, deps);
}
