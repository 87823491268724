/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CsvResponse } from '../models/CsvResponse';
import type { ItemCollectionResponse } from '../models/ItemCollectionResponse';
import type { ItemDelete } from '../models/ItemDelete';
import type { ItemPatch } from '../models/ItemPatch';
import type { ItemPost } from '../models/ItemPost';
import type { ItemPut } from '../models/ItemPut';
import type { ItemResponse } from '../models/ItemResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ManagerApiToManageItemsService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get items in organization
   * @returns ItemCollectionResponse OK
   * @throws ApiError
   */
  public get5({
    organizationId,
    pageNumber,
    numOfElement,
    itemIDs,
  }: {
    organizationId: number,
    pageNumber?: number,
    numOfElement?: number,
    itemIDs?: Array<number>,
  }): CancelablePromise<ItemCollectionResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/manager/organization/{organizationID}/items',
      path: {
        'organizationID': organizationId,
      },
      query: {
        'pageNumber': pageNumber,
        'numOfElement': numOfElement,
        'itemIDs': itemIDs,
      },
      errors: {
        400: `Bad Request`,
        401: `Failed to decode authentication token`,
        404: `Entity Not found`,
        405: `Method Not Allowed`,
        409: `Data integrity violation`,
        417: `Expectation Failed`,
        500: `General I/O error`,
      },
    });
  }

  /**
   * Add items to item group
   * @returns void
   * @throws ApiError
   */
  public put1({
    organizationId,
    requestBody,
  }: {
    organizationId: number,
    requestBody: ItemPut,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/manager/organization/{organizationID}/items',
      path: {
        'organizationID': organizationId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Failed to decode authentication token`,
        404: `Entity Not found`,
        405: `Method Not Allowed`,
        409: `Data integrity violation`,
        417: `Expectation Failed`,
        500: `General I/O error`,
      },
    });
  }

  /**
   * Remove items from item group
   * @returns void
   * @throws ApiError
   */
  public delete1({
    organizationId,
    requestBody,
  }: {
    organizationId: number,
    requestBody: ItemDelete,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/manager/organization/{organizationID}/items',
      path: {
        'organizationID': organizationId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Failed to decode authentication token`,
        404: `Entity Not found`,
        405: `Method Not Allowed`,
        409: `Data integrity violation`,
        417: `Expectation Failed`,
        500: `General I/O error`,
      },
    });
  }

  /**
   * Import items from csv file
   * @returns CsvResponse OK
   * @throws ApiError
   */
  public postCsv({
    organizationId,
    formData,
  }: {
    organizationId: number,
    formData?: {
      file: Blob;
    },
  }): CancelablePromise<CsvResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/manager/organization/{organizationID}/items/csv',
      path: {
        'organizationID': organizationId,
      },
      formData: formData,
      mediaType: 'multipart/form-data',
      errors: {
        400: `Bad Request`,
        401: `Failed to decode authentication token`,
        404: `Entity Not found`,
        405: `Method Not Allowed`,
        409: `Data integrity violation`,
        417: `Expectation Failed`,
        500: `General I/O error`,
      },
    });
  }

  /**
   * Create a new item in the organization
   * @returns ItemResponse OK
   * @throws ApiError
   */
  public post1({
    organizationId,
    requestBody,
  }: {
    organizationId: number,
    requestBody: ItemPost,
  }): CancelablePromise<ItemResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/manager/organization/{organizationID}/item',
      path: {
        'organizationID': organizationId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Failed to decode authentication token`,
        404: `Entity Not found`,
        405: `Method Not Allowed`,
        409: `Data integrity violation`,
        417: `Expectation Failed`,
        500: `General I/O error`,
      },
    });
  }

  /**
   * Update item in the organization
   * @returns ItemResponse OK
   * @throws ApiError
   */
  public patch4({
    organizationId,
    itemCode,
    requestBody,
  }: {
    organizationId: number,
    itemCode: string,
    requestBody: ItemPatch,
  }): CancelablePromise<ItemResponse> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/manager/organization/{organizationID}/item/{itemCode}',
      path: {
        'organizationID': organizationId,
        'itemCode': itemCode,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Failed to decode authentication token`,
        404: `Entity Not found`,
        405: `Method Not Allowed`,
        409: `Data integrity violation`,
        417: `Expectation Failed`,
        500: `General I/O error`,
      },
    });
  }

  /**
   * Get all items in item group
   * @returns ItemCollectionResponse OK
   * @throws ApiError
   */
  public getAllInGroup({
    organizationId,
    itemGroupId,
  }: {
    organizationId: number,
    itemGroupId: number,
  }): CancelablePromise<ItemCollectionResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/manager/organization/{organizationID}/itemGroup/{itemGroupID}/items',
      path: {
        'organizationID': organizationId,
        'itemGroupID': itemGroupId,
      },
      errors: {
        400: `Bad Request`,
        401: `Failed to decode authentication token`,
        404: `Entity Not found`,
        405: `Method Not Allowed`,
        409: `Data integrity violation`,
        417: `Expectation Failed`,
        500: `General I/O error`,
      },
    });
  }

  /**
   * Get item
   * @returns ItemResponse OK
   * @throws ApiError
   */
  public getItem1({
    organizationId,
    itemId,
  }: {
    organizationId: number,
    itemId: number,
  }): CancelablePromise<ItemResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/manager/organization/{organizationID}/item/{itemID}',
      path: {
        'organizationID': organizationId,
        'itemID': itemId,
      },
      errors: {
        400: `Bad Request`,
        401: `Failed to decode authentication token`,
        404: `Entity Not found`,
        405: `Method Not Allowed`,
        409: `Data integrity violation`,
        417: `Expectation Failed`,
        500: `General I/O error`,
      },
    });
  }

  /**
   * Get item by code
   * @returns ItemResponse OK
   * @throws ApiError
   */
  public get6({
    organizationId,
    itemCode,
  }: {
    organizationId: number,
    itemCode: string,
  }): CancelablePromise<ItemResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/manager/organization/{organizationID}/item/itemCode/{itemCode}',
      path: {
        'organizationID': organizationId,
        'itemCode': itemCode,
      },
      errors: {
        400: `Bad Request`,
        401: `Failed to decode authentication token`,
        404: `Entity Not found`,
        405: `Method Not Allowed`,
        409: `Data integrity violation`,
        417: `Expectation Failed`,
        500: `General I/O error`,
      },
    });
  }

}