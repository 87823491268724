import { isNullish } from "../../../../utils/Functions";
import { ConditionPost, ConditionResponse, RuleResponse } from "../../../generated/api";
import { UnsupportedMappingError } from "../../../shared/failures/MappingsError";
import { PromoGiftRuleModel } from "../models/PromoModelCommons";
import { PromoBasicRuleModel, PromoRuleModel } from "../models/PromoRuleModel";
import { PromoRuleModelCondition, PromoRuleModelType } from "../models/PromoTypes";

export abstract class PromoRuleMappings {
  static responseToGiftRule = (response: RuleResponse): PromoGiftRuleModel => {
    const itemRule = response.conditions.find((rule) => rule.type === ConditionResponse.type.ITEM);
    if (!itemRule) {
      throw new Error("ITEM rule not found but required when type === GIFTS");
    }
    return {
      conditions: response.conditions.filter((e) => e !== itemRule).map((e) => this.responseToModel(e, [ "CAMPAIGN", "COUPON", "PRICE" ])),
      itemID: itemRule.fieldIDs![0],
      value: response.value,
    };
  };
  static responseToBasicModel = (response: ConditionResponse): PromoBasicRuleModel => {
    const type = response.type;
    const filter = response.filter;
    const fieldIDs = response.fieldIDs;
    if (type === ConditionResponse.type.PRICE_LIST) {
      throw new UnsupportedMappingError("rule.type", type, "type");
    }
    if (filter === "MATCH" || filter === "NOT_MATCH") {
      throw new UnsupportedMappingError("rule.filter", filter, "condition");
    }
    if (isNullish(fieldIDs)) {
      throw new UnsupportedMappingError("rule.fieldIDs", fieldIDs, "ids");
    }
    return {
      condition: filter,
      ids: fieldIDs,
      value: response.value,
    };
  };

  static responseToModel = <TType extends PromoRuleModelType>(
    response: ConditionResponse,
    supportedTypes: ReadonlyArray<TType>
  ): PromoRuleModel<TType> => {
    const type = response.type;
    if (!supportedTypes.includes(type as TType)) {
      throw new UnsupportedMappingError("rule.type", type, "type");
    }
    return {
      type: type as TType,
      ...PromoRuleMappings.responseToBasicModel(response),
    };
  };

  static modelToResponse = (model: PromoRuleModel<PromoRuleModelType>): ConditionPost => {
    return {
      filter: PromoRuleMappings.#conditionModelToResponse(model.condition),
      type: PromoRuleMappings.#typeModelToResponse(model.type),
      fieldIDs: model.ids,
      value: model.value,
    };
  };

  static #conditionModelToResponse = (condition: PromoRuleModelCondition): ConditionPost.filter => {
    switch (condition) {
    case "EQUAL":
      return ConditionPost.filter.EQUAL;
    case "NOT_EQUAL":
      return ConditionPost.filter.NOT_EQUAL;
    case "CONTAIN":
      return ConditionPost.filter.CONTAIN;
    case "NOT_CONTAIN":
      return ConditionPost.filter.NOT_CONTAIN;
    }
  };

  static #typeModelToResponse = (type: PromoRuleModelType): ConditionPost.type => {
    switch (type) {
    case "ITEM":
      return ConditionPost.type.ITEM;
    case "ITEM_GROUP":
      return ConditionPost.type.ITEM_GROUP;
    case "BUSINESS_PARTNER":
      return ConditionPost.type.BUSINESS_PARTNER;
    case "BUSINESS_PARTNER_GROUP":
      return ConditionPost.type.BUSINESS_PARTNER_GROUP;
    case "COUPON":
      return ConditionPost.type.COUPON;
    case "CAMPAIGN":
      return ConditionPost.type.CAMPAIGN;
    case "PRICE":
      return ConditionPost.type.PRICE;
    case "LOYALTY_LEVEL":
      return ConditionPost.type.LOYALTY_LEVEL;  
    }
  };
}