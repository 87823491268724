import React, { useEffect, useState } from "react";
import { Typography, Button, TextField, Autocomplete, BaseTextFieldProps, Alert, Card, CardHeader, CardContent, useTheme, Stack } from "@mui/material";
import Modal from "@mui/material/Modal";
import { DatePicker } from "@mui/lab";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";

import { StyledAddPeriodForm, StyledBox, StyledCloseIcon, StyledSearchWrap } from "./CreatePromoPeriod.styles";
import { useAuth } from "../../../../shared/hooks/authentication/useAuth";
import { PromoModel } from "../../../../../domain/features/promotions/models/PromoModel";
import { organizationActions, promoPeriodActions } from "../../../../shared/constants/Actions";
import { AppUtils } from "../../../../shared/AppUtils";
import { PromoPeriodModel } from "../../../../../domain/features/promotions/models/PromoPeriodModel";
import { CheckBoxTree } from "../organizations-tree/CheckBoxTree";
import { isNullish } from "../../../../../utils/Functions";

interface PromoInfoModalProps {
  isModalOpen: boolean;
  onClose: (period?: PromoPeriodModel) => void;
  promotions:Array<PromoModel>;
}

export interface AddPeriodForm {
  readonly startDate: Date;
  readonly endDate: Date;
  readonly promoId?: number;
}

export const CreatePromoPeriod: React.FC<PromoInfoModalProps> = ({ isModalOpen, onClose, promotions }) => {
  const { t } = useTranslation([ "promoPeriods", "errors" ]);
  const { handleSubmit, control, getValues } = useForm<AddPeriodForm>({
    defaultValues: {
      startDate: new Date(),
      endDate: new Date(),
    },
  });

  const theme = useTheme();
  const { organization, user } = useAuth();

  const {
    isLoading: isLoadingPointsOfSale,
    data: pointsOfSale,
    error: pointsOfSaleError,
  } = useQuery("fetchPointsOfSale", () => {
    return organizationActions.readPointsOfSale(user!.organizationId);
  }, { enabled: !isNullish(user?.organizationId) });

  const [ selectedIds, setSelectedIds ] = useState<number[]>([]);
  // saved period
  const { isSuccess, refetch: savePeriod, error: savePeriodError, data: savedPeriod } = useQuery([ "savePeriod" ], async () => {
    const data = getValues();
    return promoPeriodActions.save(organization!, data.promoId!, undefined, { 
      startDate: data.startDate, 
      endDate: data.endDate,
      activeIds: selectedIds,
    } );
  }, { enabled: false });

  const onSubmit = async (data: AddPeriodForm, event?: React.BaseSyntheticEvent) => {
    event?.preventDefault();
    await savePeriod();
    setSelectedIds([]);
  };

  useEffect(() => {
    if (isSuccess || savedPeriod) {
      onClose(savedPeriod);
    }

  },[ isSuccess, savedPeriod ]);

  const startDate = useWatch({
    control,
    name: "startDate",
  });

  return (
    <Modal open={isModalOpen} onClose={() => { setSelectedIds([]); onClose(undefined); }} sx={{ backdropFilter: "blur(4px)" }}>
      <StyledBox>
        <Typography align="center" variant="h6" component="h2" sx={{ mb: "2rem" }}>
          {t("promoPeriods:add_title").toUpperCase()}
        </Typography>
        <StyledCloseIcon onClick={() => { setSelectedIds([]); onClose(undefined); }} />
        <Card elevation={0} variant="outlined" sx={{ marginBottom: "1rem" }}>
          <CardHeader
            title={<Typography align="center" fontSize="medium">{t("promoPeriods:selling_points")}</Typography>} 
            sx={{ backgroundColor: theme.palette.secondary.light, color: "white", p: 1 }}
            disableTypography 
          />
          <CardContent sx={{ maxHeight: "40vh", overflowY: "scroll" }}>
            <CheckBoxTree 
              disabled={false}
              selectedIds={selectedIds}
              setSelectedIds={setSelectedIds}
              pointsOfSale={pointsOfSale}
            />
          </CardContent>
        </Card>
        <StyledAddPeriodForm onSubmit={handleSubmit(onSubmit)}>
          <StyledSearchWrap>
            <Controller
              name="promoId"
              rules={{ required: t("errors:select_promo", { ns: "errors" }) }}
              render={({ field, fieldState: { error } }) => {
                return <Autocomplete<PromoModel, false, true, false>
                  {...field}
                  size="small"
                  value={promotions.find((it) => {
                    return field.value === it.id;
                  })
                  }
                  onChange={(ev, autocomplete) => {
                    // propagate autocomplete changes to controller field
                    field.onChange(autocomplete.id);
                  }}
                  options={promotions}
                  getOptionLabel={(item) => item.description}
                  renderInput={(params) =>
                    <TextField
                      {...params}
                      label={t("promoPeriods:search")}
                      helperText={error ? error.message : null}
                      error={!!error}
                    />
                  }
                />;
              }}
              control={control}
            />
          </StyledSearchWrap>
          <Stack direction="row" spacing={2}>
            <Controller
              rules={{ required: true }}
              render={({ field }) =>
                <DatePicker
                  disablePast
                  {...field}
                  
                  renderInput={(props: BaseTextFieldProps) => <TextField size="small" fullWidth {...props} />}
                />}
              name="startDate"
              control={control}
            />
            <Controller
              rules={{ required: true }}
              render={({ field }) =>
                <DatePicker
                  disablePast
                  minDate={startDate}
                  {...field}
                  renderInput={(props: BaseTextFieldProps) => <TextField size="small" fullWidth {...props} />}
                />}
              name="endDate"
              control={control}
            />
          </Stack>
          {savePeriodError && <Alert severity="error">{AppUtils.getErrorMessage(savePeriodError)}</Alert>}
          <Stack justifyContent="center" alignItems="center" sx={{ marginTop: "2rem" }}>
            <Button sx={{ width: "30%" }} type="submit" variant="contained">{t("promoPeriods:save_btn")}</Button>
          </Stack>
        </StyledAddPeriodForm>
      </StyledBox>
    </Modal>
  );
};
