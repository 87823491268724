/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PromotionCollectionResponse } from '../models/PromotionCollectionResponse';
import type { PromotionPatch } from '../models/PromotionPatch';
import type { PromotionPeriodCollectionPatch } from '../models/PromotionPeriodCollectionPatch';
import type { PromotionPeriodCollectionResponse } from '../models/PromotionPeriodCollectionResponse';
import type { PromotionPeriodPost } from '../models/PromotionPeriodPost';
import type { PromotionPeriodResponse } from '../models/PromotionPeriodResponse';
import type { PromotionPost } from '../models/PromotionPost';
import type { PromotionResponse } from '../models/PromotionResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ManagerApiToManagePromotionsService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Create or update promotion in the organization
   * @returns PromotionResponse OK
   * @throws ApiError
   */
  public post({
    organizationId,
    requestBody,
    promotionId,
  }: {
    organizationId: number,
    requestBody: PromotionPost,
    promotionId?: number,
  }): CancelablePromise<PromotionResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/manager/organization/{organizationID}/promotion',
      path: {
        'organizationID': organizationId,
      },
      query: {
        'promotionID': promotionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Failed to decode authentication token`,
        404: `Entity Not found`,
        405: `Method Not Allowed`,
        409: `Data integrity violation`,
        417: `Expectation Failed`,
        500: `General I/O error`,
      },
    });
  }

  /**
   * Create or update promotion period in the promotion
   * @returns PromotionPeriodResponse OK
   * @throws ApiError
   */
  public postPromotionPeriod({
    organizationId,
    promotionId,
    requestBody,
    promotionPeriodId,
  }: {
    organizationId: number,
    promotionId: number,
    requestBody: PromotionPeriodPost,
    promotionPeriodId?: number,
  }): CancelablePromise<PromotionPeriodResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/manager/organization/{organizationID}/promotion/{promotionID}/promotionPeriod',
      path: {
        'organizationID': organizationId,
        'promotionID': promotionId,
      },
      query: {
        'promotionPeriodID': promotionPeriodId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Failed to decode authentication token`,
        404: `Entity Not found`,
        405: `Method Not Allowed`,
        409: `Data integrity violation`,
        417: `Expectation Failed`,
        500: `General I/O error`,
      },
    });
  }

  /**
   * Modify promotion periods priorities
   * @returns PromotionPeriodCollectionResponse OK
   * @throws ApiError
   */
  public patch2({
    organizationId,
    requestBody,
  }: {
    organizationId: number,
    requestBody: PromotionPeriodCollectionPatch,
  }): CancelablePromise<PromotionPeriodCollectionResponse> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/manager/organization/{organizationID}/promotionPeriods/priorities',
      path: {
        'organizationID': organizationId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Failed to decode authentication token`,
        404: `Entity Not found`,
        405: `Method Not Allowed`,
        409: `Data integrity violation`,
        417: `Expectation Failed`,
        500: `General I/O error`,
      },
    });
  }

  /**
   * Deactivate promotion period
   * @returns void
   * @throws ApiError
   */
  public deactivatePromotionPeriod({
    organizationId,
    promotionPeriodId,
  }: {
    organizationId: number,
    promotionPeriodId: number,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/manager/organization/{organizationID}/promotionPeriod/{promotionPeriodID}',
      path: {
        'organizationID': organizationId,
        'promotionPeriodID': promotionPeriodId,
      },
      errors: {
        400: `Bad Request`,
        401: `Failed to decode authentication token`,
        404: `Entity Not found`,
        405: `Method Not Allowed`,
        409: `Data integrity violation`,
        417: `Expectation Failed`,
        500: `General I/O error`,
      },
    });
  }

  /**
   * Activate promotion period
   * @returns void
   * @throws ApiError
   */
  public activePromotionPeriod({
    organizationId,
    promotionPeriodId,
  }: {
    organizationId: number,
    promotionPeriodId: number,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/manager/organization/{organizationID}/promotionPeriod/{promotionPeriodID}',
      path: {
        'organizationID': organizationId,
        'promotionPeriodID': promotionPeriodId,
      },
      errors: {
        400: `Bad Request`,
        401: `Failed to decode authentication token`,
        404: `Entity Not found`,
        405: `Method Not Allowed`,
        409: `Data integrity violation`,
        417: `Expectation Failed`,
        500: `General I/O error`,
      },
    });
  }

  /**
   * Get promotion
   * @returns PromotionResponse OK
   * @throws ApiError
   */
  public get4({
    organizationId,
    promotionId,
  }: {
    organizationId: number,
    promotionId: number,
  }): CancelablePromise<PromotionResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/manager/organization/{organizationID}/promotion/{promotionID}',
      path: {
        'organizationID': organizationId,
        'promotionID': promotionId,
      },
      errors: {
        400: `Bad Request`,
        401: `Failed to decode authentication token`,
        404: `Entity Not found`,
        405: `Method Not Allowed`,
        409: `Data integrity violation`,
        417: `Expectation Failed`,
        500: `General I/O error`,
      },
    });
  }

  /**
   * Deactivate promotion
   * @returns void
   * @throws ApiError
   */
  public deactivatePromotion({
    organizationId,
    promotionId,
  }: {
    organizationId: number,
    promotionId: number,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/manager/organization/{organizationID}/promotion/{promotionID}',
      path: {
        'organizationID': organizationId,
        'promotionID': promotionId,
      },
      errors: {
        400: `Bad Request`,
        401: `Failed to decode authentication token`,
        404: `Entity Not found`,
        405: `Method Not Allowed`,
        409: `Data integrity violation`,
        417: `Expectation Failed`,
        500: `General I/O error`,
      },
    });
  }

  /**
   * Activate promotion
   * @returns void
   * @throws ApiError
   */
  public activePromotion({
    organizationId,
    promotionId,
  }: {
    organizationId: number,
    promotionId: number,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/manager/organization/{organizationID}/promotion/{promotionID}',
      path: {
        'organizationID': organizationId,
        'promotionID': promotionId,
      },
      errors: {
        400: `Bad Request`,
        401: `Failed to decode authentication token`,
        404: `Entity Not found`,
        405: `Method Not Allowed`,
        409: `Data integrity violation`,
        417: `Expectation Failed`,
        500: `General I/O error`,
      },
    });
  }

  /**
   * Modify promotion description
   * @returns PromotionResponse OK
   * @throws ApiError
   */
  public patch3({
    organizationId,
    promotionId,
    requestBody,
  }: {
    organizationId: number,
    promotionId: number,
    requestBody: PromotionPatch,
  }): CancelablePromise<PromotionResponse> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/manager/organization/{organizationID}/promotion/{promotionID}/description',
      path: {
        'organizationID': organizationId,
        'promotionID': promotionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Failed to decode authentication token`,
        404: `Entity Not found`,
        405: `Method Not Allowed`,
        409: `Data integrity violation`,
        417: `Expectation Failed`,
        500: `General I/O error`,
      },
    });
  }

  /**
   * Get all promotion templates
   * @returns PromotionCollectionResponse OK
   * @throws ApiError
   */
  public get2({
    organizationId,
    pageNumber,
    numOfElement,
    templateIDs,
  }: {
    organizationId: number,
    pageNumber?: number,
    numOfElement?: number,
    templateIDs?: Array<number>,
  }): CancelablePromise<PromotionCollectionResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/manager/organization/{organizationID}/templates',
      path: {
        'organizationID': organizationId,
      },
      query: {
        'pageNumber': pageNumber,
        'numOfElement': numOfElement,
        'templateIDs': templateIDs,
      },
      errors: {
        400: `Bad Request`,
        401: `Failed to decode authentication token`,
        404: `Entity Not found`,
        405: `Method Not Allowed`,
        409: `Data integrity violation`,
        417: `Expectation Failed`,
        500: `General I/O error`,
      },
    });
  }

  /**
   * Get promotions (active/inactive)
   * @returns PromotionCollectionResponse OK
   * @throws ApiError
   */
  public get3({
    organizationId,
    status,
    pageNumber,
    numOfElement,
    promotionIDs,
  }: {
    organizationId: number,
    status?: boolean,
    pageNumber?: number,
    numOfElement?: number,
    promotionIDs?: Array<number>,
  }): CancelablePromise<PromotionCollectionResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/manager/organization/{organizationID}/promotions',
      path: {
        'organizationID': organizationId,
      },
      query: {
        'status': status,
        'pageNumber': pageNumber,
        'numOfElement': numOfElement,
        'promotionIDs': promotionIDs,
      },
      errors: {
        400: `Bad Request`,
        401: `Failed to decode authentication token`,
        404: `Entity Not found`,
        405: `Method Not Allowed`,
        409: `Data integrity violation`,
        417: `Expectation Failed`,
        500: `General I/O error`,
      },
    });
  }

  /**
   * Get active (with or without future active) or all promotion periods
   * @returns PromotionPeriodCollectionResponse OK
   * @throws ApiError
   */
  public getPromotionPeriodsOfPromotion({
    organizationId,
    active,
    includeFutureActive,
  }: {
    organizationId: number,
    active?: boolean,
    includeFutureActive?: boolean,
  }): CancelablePromise<PromotionPeriodCollectionResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/manager/organization/{organizationID}/promotionPeriods',
      path: {
        'organizationID': organizationId,
      },
      query: {
        'active': active,
        'includeFutureActive': includeFutureActive,
      },
      errors: {
        400: `Bad Request`,
        401: `Failed to decode authentication token`,
        404: `Entity Not found`,
        405: `Method Not Allowed`,
        409: `Data integrity violation`,
        417: `Expectation Failed`,
        500: `General I/O error`,
      },
    });
  }

  /**
   * Get all promotion periods of promotion
   * @returns PromotionPeriodCollectionResponse OK
   * @throws ApiError
   */
  public getPromotionPeriods({
    organizationId,
    promotionId,
  }: {
    organizationId: number,
    promotionId: number,
  }): CancelablePromise<PromotionPeriodCollectionResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/manager/organization/{organizationID}/promotion/{promotionID}/promotionPeriods',
      path: {
        'organizationID': organizationId,
        'promotionID': promotionId,
      },
      errors: {
        400: `Bad Request`,
        401: `Failed to decode authentication token`,
        404: `Entity Not found`,
        405: `Method Not Allowed`,
        409: `Data integrity violation`,
        417: `Expectation Failed`,
        500: `General I/O error`,
      },
    });
  }

  /**
   * Delete promotion period
   * @returns void
   * @throws ApiError
   */
  public deletePromotionPeriod({
    organizationId,
    promotionPeriodId,
  }: {
    organizationId: number,
    promotionPeriodId: number,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/manager/organization/{organizationID}/promotionPeriod/{promotionPeriodID}/delete',
      path: {
        'organizationID': organizationId,
        'promotionPeriodID': promotionPeriodId,
      },
      errors: {
        400: `Bad Request`,
        401: `Failed to decode authentication token`,
        404: `Entity Not found`,
        405: `Method Not Allowed`,
        409: `Data integrity violation`,
        417: `Expectation Failed`,
        500: `General I/O error`,
      },
    });
  }

}