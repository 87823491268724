import { PromoModel } from "../../../domain/features/promotions/models/PromoModel";
import { fromPairs, groupBy, head, toPairs } from "ramda";

export const groupPromotionsById = (promotions:PromoModel[]):Record<string, PromoModel> => {
  // We know that grouping by id the promotions will build an object {id:promoModel}
  // but of course the {@link groupBy} function cannot know, hence the resulting type is Record<string, PromoModel[]>
  const groupedPromotions:Record<string, PromoModel[]> = groupBy<PromoModel>(
    (promo) => promo.id.toString(), promotions ?? []
  );

  // some code to make typescript understand that we effectively have 1 promo model for each grouped promo id
  const groupedPromotionsPairs:Array<[string, PromoModel[]]> = toPairs(groupedPromotions);

  const promotionsPairs = groupedPromotionsPairs.map<[number,PromoModel]>(([ id, promotions ]) => {
    return [ parseInt(id), head(promotions)! ];
  });

  return fromPairs(promotionsPairs);
};
