export class Env {
  static apiBaseUrl: string = Env.#getString("REACT_APP_API_BASE_URL");
  static appName: string = Env.#getString("REACT_APP_PRODUCT_NAME");
  static appVersion: string = Env.#getString("REACT_APP_VERSION");

  static #getString(key: string) {
    const value = process.env[key];
    if (value === undefined) throw Error(`Missing '${key}' in env`);
    return value;
  }
}
