import { is, equals, isNil, clone, omit, includes, sortBy as rSortBy, Ord } from "ramda";

export type AsyncVoidFunction = () => Promise<unknown>;

export const noop = () => {
  // nothing
};

export const throwNoop = () => {
  throw new Error("This function must not be called");
};

export const isError = (value:unknown): value is Error => {
  return is(Error, value);
};

export const withoutUndefined = <T>(list: Array<undefined | T>): Array<T> => {
  return list.filter((e) => e !== undefined) as Array<T>;
};

export const contains = <T>(list: Array<T>, element: T): boolean => {
  return includes(element, list);
};

export const containsAll = <T>(list: Array<T>, elements: Array<T>): boolean => {
  return elements.every((element) => contains(list, element));
};

export const omitKey = <T, K extends string>(key:K, value: T) => {
  return omit<T, K>([ key ], value);
};

export const isEmpty = (value: string | Array<unknown>): boolean => {
  return value.length === 0;
};

export { isNil as isNullish, clone as deepClone, equals as isEqual };

export const groupBy = <V, K>(list: Array<V>, comparator: (value: V) => K): Map<K, Array<V>> => {
  const map = new Map<K, Array<V>>();
  for (const element of list) {
    const key = comparator(element);
    const group = map.get(key);
    if (group === undefined) {
      map.set(key, [ element ]);
    } else {
      group.push(element);
    }
  }
  return map;
};

export const sortBy = <V, K extends Ord>(list: Array<V>, comparator: (value: V) => K) => {
  return rSortBy(comparator, list);
};

/**
 * This is a copy/paste taken from the autogenerated http client.
 * We need it to store user credentials headers after a successful authentication, and cannot store username
 * and password in clear. Hence we store the auth header (still bad but not as bad) in the localStorage, encoded in
 * base64 (same policy used from the generated client)
 * @param str
 */
export const base64 = (str: string): string => {
  try {
    return btoa(str);
  } catch (err) {
    return Buffer.from(str).toString("base64");
  }
};
