import { insert, remove, update, without as rWithout } from "ramda";

/**
 * Inserts an item at the specified index
 * @param array
 * @param index
 * @param item
 */
export const insertAt = <T>(array: Array<T>, index = 0, item: T):T[] => {
  return insert<T>(index, item, array);
};

/**
 * Removes an element at the specified index
 * @param array
 * @param index
 */
export const removeAt = <T>(array: Array<T>, index = 0):T[] => {
  return remove<T>(index, 1, array);
};

/**
 * Replaces an item (or a set of items) inside the provided array, starting at @param index
 *
 * @param array
 * @param index index from where the replacement should start
 * @param item the new value that will be replaced inside the @param array
 */
export const replaceAt = <T>(array: Array<T>, index = 0, item: T):T[] => {
  return update<T>(index, item, array);
};

export const without = <T>(array: Array<T>, ...values: Array<T>): T[] => {
  return rWithout(values, array);
};