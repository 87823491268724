/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BusinessPartnerCollectionResponse } from '../models/BusinessPartnerCollectionResponse';
import type { BusinessPartnerDelete } from '../models/BusinessPartnerDelete';
import type { BusinessPartnerPatch } from '../models/BusinessPartnerPatch';
import type { BusinessPartnerPost } from '../models/BusinessPartnerPost';
import type { BusinessPartnerPut } from '../models/BusinessPartnerPut';
import type { BusinessPartnerResponse } from '../models/BusinessPartnerResponse';
import type { CsvResponse } from '../models/CsvResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ManagerApiToManageBusinessPartnerService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get business partners in organization
   * @returns BusinessPartnerCollectionResponse OK
   * @throws ApiError
   */
  public getBusinessPartners({
    organizationId,
    pageNumber,
    numOfElement,
    businessPartnerIDs,
  }: {
    organizationId: number,
    pageNumber?: number,
    numOfElement?: number,
    businessPartnerIDs?: Array<number>,
  }): CancelablePromise<BusinessPartnerCollectionResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/manager/organization/{organizationID}/businessPartners',
      path: {
        'organizationID': organizationId,
      },
      query: {
        'pageNumber': pageNumber,
        'numOfElement': numOfElement,
        'businessPartnerIDs': businessPartnerIDs,
      },
      errors: {
        400: `Bad Request`,
        401: `Failed to decode authentication token`,
        404: `Entity Not found`,
        405: `Method Not Allowed`,
        409: `Data integrity violation`,
        417: `Expectation Failed`,
        500: `General I/O error`,
      },
    });
  }

  /**
   * Add business partners to business partner groups
   * @returns void
   * @throws ApiError
   */
  public put2({
    organizationId,
    requestBody,
  }: {
    organizationId: number,
    requestBody: BusinessPartnerPut,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/manager/organization/{organizationID}/businessPartners',
      path: {
        'organizationID': organizationId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Failed to decode authentication token`,
        404: `Entity Not found`,
        405: `Method Not Allowed`,
        409: `Data integrity violation`,
        417: `Expectation Failed`,
        500: `General I/O error`,
      },
    });
  }

  /**
   * Remove business partners from business partners groups
   * @returns void
   * @throws ApiError
   */
  public deleteBusinessPartnerFromGroup({
    organizationId,
    requestBody,
  }: {
    organizationId: number,
    requestBody: BusinessPartnerDelete,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/manager/organization/{organizationID}/businessPartners',
      path: {
        'organizationID': organizationId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Failed to decode authentication token`,
        404: `Entity Not found`,
        405: `Method Not Allowed`,
        409: `Data integrity violation`,
        417: `Expectation Failed`,
        500: `General I/O error`,
      },
    });
  }

  /**
   * Get business partner
   * @returns BusinessPartnerResponse OK
   * @throws ApiError
   */
  public get7({
    organizationId,
    businessPartnerId,
  }: {
    organizationId: number,
    businessPartnerId: number,
  }): CancelablePromise<BusinessPartnerResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/manager/organization/{organizationID}/businessPartner/{businessPartnerID}',
      path: {
        'organizationID': organizationId,
        'businessPartnerID': businessPartnerId,
      },
      errors: {
        400: `Bad Request`,
        401: `Failed to decode authentication token`,
        404: `Entity Not found`,
        405: `Method Not Allowed`,
        409: `Data integrity violation`,
        417: `Expectation Failed`,
        500: `General I/O error`,
      },
    });
  }

  /**
   * Activate business partner
   * @returns void
   * @throws ApiError
   */
  public putBusinessPartner1({
    organizationId,
    businessPartnerId,
  }: {
    organizationId: number,
    businessPartnerId: number,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/manager/organization/{organizationID}/businessPartner/{businessPartnerID}',
      path: {
        'organizationID': organizationId,
        'businessPartnerID': businessPartnerId,
      },
      errors: {
        400: `Bad Request`,
        401: `Failed to decode authentication token`,
        404: `Entity Not found`,
        405: `Method Not Allowed`,
        409: `Data integrity violation`,
        417: `Expectation Failed`,
        500: `General I/O error`,
      },
    });
  }

  /**
   * Deactivate business partner
   * @returns void
   * @throws ApiError
   */
  public deleteBusinessPartner1({
    organizationId,
    businessPartnerId,
  }: {
    organizationId: number,
    businessPartnerId: number,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/manager/organization/{organizationID}/businessPartner/{businessPartnerID}',
      path: {
        'organizationID': organizationId,
        'businessPartnerID': businessPartnerId,
      },
      errors: {
        400: `Bad Request`,
        401: `Failed to decode authentication token`,
        404: `Entity Not found`,
        405: `Method Not Allowed`,
        409: `Data integrity violation`,
        417: `Expectation Failed`,
        500: `General I/O error`,
      },
    });
  }

  /**
   * Update business partner in the organization
   * @returns BusinessPartnerResponse OK
   * @throws ApiError
   */
  public patchBusinessPartner({
    organizationId,
    businessPartnerId,
    requestBody,
  }: {
    organizationId: number,
    businessPartnerId: number,
    requestBody: BusinessPartnerPatch,
  }): CancelablePromise<BusinessPartnerResponse> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/manager/organization/{organizationID}/businessPartner/{businessPartnerID}',
      path: {
        'organizationID': organizationId,
        'businessPartnerID': businessPartnerId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Failed to decode authentication token`,
        404: `Entity Not found`,
        405: `Method Not Allowed`,
        409: `Data integrity violation`,
        417: `Expectation Failed`,
        500: `General I/O error`,
      },
    });
  }

  /**
   * Import business partners from csv file
   * @returns CsvResponse OK
   * @throws ApiError
   */
  public postBusinessPartnerCsv1({
    organizationId,
    formData,
  }: {
    organizationId: number,
    formData?: {
      file: Blob;
    },
  }): CancelablePromise<CsvResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/manager/organization/{organizationID}/businessPartners/csv',
      path: {
        'organizationID': organizationId,
      },
      formData: formData,
      mediaType: 'multipart/form-data',
      errors: {
        400: `Bad Request`,
        401: `Failed to decode authentication token`,
        404: `Entity Not found`,
        405: `Method Not Allowed`,
        409: `Data integrity violation`,
        417: `Expectation Failed`,
        500: `General I/O error`,
      },
    });
  }

  /**
   * Create new business partner in the organization
   * @returns BusinessPartnerResponse OK
   * @throws ApiError
   */
  public postBusinessPartner({
    organizationId,
    requestBody,
  }: {
    organizationId: number,
    requestBody: BusinessPartnerPost,
  }): CancelablePromise<BusinessPartnerResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/manager/organization/{organizationID}/businessPartner',
      path: {
        'organizationID': organizationId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Failed to decode authentication token`,
        404: `Entity Not found`,
        405: `Method Not Allowed`,
        409: `Data integrity violation`,
        417: `Expectation Failed`,
        500: `General I/O error`,
      },
    });
  }

  /**
   * Get all business partners in business partner group
   * @returns BusinessPartnerCollectionResponse OK
   * @throws ApiError
   */
  public getAll({
    organizationId,
    businessPartnerGroupId,
  }: {
    organizationId: number,
    businessPartnerGroupId: number,
  }): CancelablePromise<BusinessPartnerCollectionResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/manager/organization/{organizationID}/businessPartnerGroup/{businessPartnerGroupID}/businessPartners',
      path: {
        'organizationID': organizationId,
        'businessPartnerGroupID': businessPartnerGroupId,
      },
      errors: {
        400: `Bad Request`,
        401: `Failed to decode authentication token`,
        404: `Entity Not found`,
        405: `Method Not Allowed`,
        409: `Data integrity violation`,
        417: `Expectation Failed`,
        500: `General I/O error`,
      },
    });
  }

}