import { Alert, AlertTitle, LinearProgress, Table, TableBody, TableHead, TableRow, Typography } from "@mui/material";
import { isEmpty } from "ramda";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { SortableContainer ,  SortableElement  } from "react-sortable-hoc";
import { PromoPeriodModel } from "../../../../../domain/features/promotions/models/PromoPeriodModel";
import {
  StyledActivePromotions,
  StyledAlertSpacer,
  StyledTableCell,
  StyledTableContainer,
} from "../../promotions-table/PromotionsTable.styles";
import { ActivePeriodRow, ActivePeriodRowProps } from "./ActivePeriodRow";
import { useDebouncedCallback } from "use-debounce";
import { promoPeriodActions } from "../../../../shared/constants/Actions";
import { useAuth } from "../../../../shared/hooks/authentication/useAuth";
import { useMutation } from "react-query";
import { insertAt, removeAt } from "../../../../../utils/Array";
import { useSnackBarMessenger } from "../../../../shared/components/snack-bar/SnackBarMessenger";
import { ResultAlert } from "../../../../shared/components/snack-bar/ResultAlert";
import { PromoModel } from "../../../../../domain/features/promotions/models/PromoModel";
import { PointOfSaleModel } from "../../../../../domain/features/organizations/models/PointOfSaleModel";

interface ActivePeriodsTableProps {
  periods: Array<PromoPeriodModel>;
  refetchPeriods: () => Promise<unknown>;
  promotions: Record<number, PromoModel>;
  pointsOfSale:undefined | PointOfSaleModel;
}

const PRIORITY_SAVE_DELAY = 500;
const TableBodySortable = SortableContainer<{displayRowCheckbox: boolean}>(({ children, displayRowCheckbox }, _) => 
  <TableBody sx={{ position: "relative" }}>{children}</TableBody>
);

const SortableRow = SortableElement<ActivePeriodRowProps >((props, _) => 
  <ActivePeriodRow {...props} />
);

(TableBodySortable as Record<string, any>).muiName = "TableBody";
export const ActivePeriodsTable: React.FC<ActivePeriodsTableProps> = ({
  periods,
  refetchPeriods,
  promotions,
  pointsOfSale,
}) => {
  const { t } = useTranslation("promoPeriods");
  const { organization } = useAuth();
  const messenger = useSnackBarMessenger();
  const [ currentItems, setCurrentItems ] = useState<Array<PromoPeriodModel>>(periods);
  const [ canSave, setCanSave ] = useState(false);
  const debounceCanSave = useDebouncedCallback(setCanSave, PRIORITY_SAVE_DELAY);
  const {
    isLoading: isUpdatingOrder,
    mutate: updateOrder,
  } = useMutation("updateOrder", async (items: Array<PromoPeriodModel>) => {
    await promoPeriodActions.updateActivesOrder(organization!, items);
    messenger.show({ content: <ResultAlert title={t("priority_save_success")} /> });
    await refetchPeriods();
  });

  useEffect(() => {
    if (!canSave) {
      return;
    }
    updateOrder(currentItems);
    setCanSave(false);
  }, [ canSave ]);

  // restore initial state when 'periods' changed
  useEffect(() => {
    setCurrentItems(periods);
    setCanSave(false);
  }, [ periods ]);

  // a little function to help us with reordering the result
  const reorder = (items: Array<PromoPeriodModel>, startIndex: number, endIndex: number) => {
    const itemMoved = items[startIndex];
    return insertAt(removeAt(items, startIndex), endIndex, itemMoved);
  };

  if (isEmpty(periods)) {
    return <Typography p={4} align="center">{t("empty_list")}</Typography>;
  }

  return (
    <StyledActivePromotions>
      <StyledAlertSpacer>
        {debounceCanSave.isPending() &&
          <Alert severity="info"><AlertTitle>{t("priority_in_draft")}</AlertTitle></Alert>}
      </StyledAlertSpacer>
      {isUpdatingOrder && <LinearProgress />}
      <StyledTableContainer sx={{ border: 0 }}>
        <Table>
          <TableHead>
            <TableRow 
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(7, 1fr)",
                width: "100%",
              }}
            >
              <StyledTableCell>{t("change_priority")}</StyledTableCell>
              <StyledTableCell>{t("promo_code")}</StyledTableCell>
              <StyledTableCell>{t("promo_desc")}</StyledTableCell>
              <StyledTableCell>{t("valid_from")}</StyledTableCell>
              <StyledTableCell>{t("valid_until")}</StyledTableCell>
              <StyledTableCell>{t("unit_org")}</StyledTableCell>
              <StyledTableCell>{t("operations")}</StyledTableCell>
            </TableRow>
          </TableHead>
        </Table>
      </StyledTableContainer>
      <StyledTableContainer>
        <Table 
          sx={{ 
            height: (window.innerHeight - 128) - 40, 
            border: 0,
            overflowY: "scroll",
          }}
        >
          <TableBodySortable 
            displayRowCheckbox={false} 
            lockAxis="y"
            onSortEnd={({ oldIndex, newIndex }) => {
              if (oldIndex === newIndex) {
                return;
              }
              const items = reorder(
                currentItems,
                oldIndex,
                newIndex,
              );
          
              setCurrentItems(items);
              debounceCanSave(true);
            }}
            useDragHandle
          >
            {currentItems?.map((period, index) => {
              if (!promotions[period.promotionId]) {
                // This is probably happening because we're missing some kind of validation
                // frontend or backend side: when a promotion becomes non-active, its periods should be removed..
                // eslint-disable-next-line no-console
                console.warn("Inconsistent state, found active period with inactive promotion", period, promotions);
              }
              return <SortableRow
                arrayIndex={index}
                index={index}
                key={period.id}
                period={period}
                pointsOfSale={pointsOfSale}
                promotion={promotions[period.promotionId]}
                refetchPeriods={refetchPeriods}
              />;
            })}
          </TableBodySortable>
        </Table>
      </StyledTableContainer>
    </StyledActivePromotions>
  );
};
