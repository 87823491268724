import { base64, isNullish, omitKey } from "../../../../utils/Functions";
import { log } from "../../../../utils/Logger";
import { AppClient } from "../../../generated/api";
import { OrganizationActions } from "../../organizations/actions/OrganizationActions";
import { OrganizationModel } from "../../organizations/models/OrganizationModel";
import { UserActions } from "../../users/actions/UserActions";
import { UserModel } from "../../users/models/UserModel";

export interface AuthUserModel {
  readonly user: UserModel;
  readonly organization: OrganizationModel;
}

export class AuthActions {
  constructor(
    private readonly appClient: AppClient,
    private readonly userActions: UserActions,
    private readonly organizationActions: OrganizationActions,
  ) {}

  async readCurrentUser(): Promise<undefined | AuthUserModel> {
    const localAuthenticationRaw = localStorage.getItem(AUTHENTICATION_STORAGE_KEY);
    if (isNullish(localAuthenticationRaw)) return undefined;

    const localAuthentication: AuthenticationStore = JSON.parse(localAuthenticationRaw);

    if(!isExpired(localAuthentication)) {
      this.#updateAppClientCredentials({ type: "token", token: localAuthentication.token });

      // try to log-in without credentials
      try {
        return this.#signIn();
      } catch (error) {
        log.error("Auth failed", error);
        return undefined;
      }
    } else {
      clearCredentials();
      return undefined;
    }
  }

  async signIn(username: string, password: string): Promise<AuthUserModel> {
    this.#updateAppClientCredentials({ type: "basic", username, password });

    const data = await this.#signIn();
    storeCredentials(username, password);
    return data;
  }

  signOut(): void {
    clearCredentials();
    this.#updateAppClientCredentials({ type: "none" });
  }

  async #signIn(): Promise<AuthUserModel> {
    try {
      const user = await this.userActions.readCurrent();
      const organization = await this.organizationActions.read(user.organizationId);

      return { user, organization };
    } catch (error) {
      this.#updateAppClientCredentials({ type: "none" });
      clearCredentials();

      throw error;
    }
  }

  #updateAppClientCredentials(data: {type: "none"} | {type: "basic"; username: string; password: string} | {type: "token"; token: string}) {
    this.appClient.request.config.USERNAME = data.type === "basic" ? data.username : undefined;
    this.appClient.request.config.PASSWORD = data.type === "basic" ? data.password : undefined;
    if (data.type === "token") {
      this.appClient.request.config.HEADERS = {
        ...this.appClient.request.config.HEADERS,
        Authorization: `Basic ${data.token}`,
      };
    } else {
      this.appClient.request.config.HEADERS = omitKey("Authentication", this.appClient.request.config.HEADERS);
    }
  }
}

const AUTHENTICATION_STORAGE_KEY = "authentication";
const AUTHENTICATION_LIFE_TIME_HOURS = 24;
interface AuthenticationStore {
  /**
   * Authentication token
   */
  token: string;
  /**
   * ISO date of the moment the authentication store was created
   */
  createdAt: string;
}

const isExpired = (authentication:AuthenticationStore):boolean => {
  const now = new Date();
  const authenticationDate = new Date(authentication.createdAt);
  const dateDiffMillis = Math.abs(now.getTime() - authenticationDate.getTime());
  const dateDiffHours = dateDiffMillis / 36e5;
  return dateDiffHours >= AUTHENTICATION_LIFE_TIME_HOURS;
};

const storeCredentials = (username:string, password:string) => {
  // This is the way our generated client is passing over username and password as headers in the request
  // sadly headers cannot be retrieved from the generated client
  const token = base64(`${username}:${password}`);
  const authenticationStore:AuthenticationStore = { token, createdAt: new Date().toISOString() };
  localStorage.setItem(AUTHENTICATION_STORAGE_KEY, JSON.stringify(authenticationStore));
};

const clearCredentials = () => {
  localStorage.removeItem(AUTHENTICATION_STORAGE_KEY);
};