import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

export const StyledSignInGrid = styled("div")(() => (
  {
    display: "grid",
    height: "100vh",
    overflow: "hidden",
    gridTemplateColumns: "1fr 1fr",
  }
));
export const StyledSignInSide = styled(Box)(() => (
  {
    display: "grid",
    placeItems: "center",
    alignContent: "center",
    color: "white",
    fontFamily: "'Red Hat Mono', monospace",
  }
));

export const StyledSignInForm = styled("form")(() => (
  {
    display: "grid",
    width: "50%",
    margin: "0 auto",
    alignContent: "center",
    gap: "2rem",
  }
));

export const StyledLogoImg = styled("img")(() => (
  { maxWidth: "200px", margin: "0 auto" }
));
