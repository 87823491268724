import { useQuery } from "react-query";
import { PromoRuleModelType } from "../../../../domain/features/promotions/models/PromoTypes";
import { UserModel } from "../../../../domain/features/users/models/UserModel";
import { groupActions } from "../../../shared/constants/Actions";
import { isNullish } from "../../../../utils/Functions";
import { Alert, Box, Pagination, Stack, Table, TableBody, TableHead, TextField, Typography } from "@mui/material";
import { ArrayUtils } from "../../../../domain/utils/ArrayUtils";
import { useEffect, useState } from "react";
import { GroupModel } from "../../../../domain/features/groups/models/GroupModel";
import { StyledTableCell, StyledTableContainer } from "../../promotions/promotions-table/PromotionsTable.styles";
import { GroupRow } from "./GroupRow";
import { useValueChanges } from "../../../shared/hooks/basic/UseValueChanges";
import { AppUtils } from "../../../shared/AppUtils";
import { WrappedLoader } from "../../../shared/wrapped-loader/WrappedLoader";
import { useTranslation } from "react-i18next";

interface GroupTableProps {
  type: PromoRuleModelType;
  user: UserModel | undefined;
  isOpen: boolean;
}

const getRowsCount = () => 50;
export const GroupTable = ({ type, user, isOpen }: GroupTableProps) => {
  const { t } = useTranslation("groupsTable");
  const { 
    data, 
    isLoading, 
    refetch, 
    error, 
    isError,
  } = useQuery(`fetchGroup.${type}`, () => {
    return groupActions.readAll(user!.organizationId, type);
  }, { enabled: !isNullish(user?.organizationId) });
  const [ chunks, setChunks ] = useState<GroupModel[][]>([]);
  const [ index, setIndex ] = useState(0);
  useValueChanges(isOpen, () => {
    return refetch();
  }, [ isOpen, refetch ]);
  useEffect(() => {
    if (data) {
      const SIZE = getRowsCount();
      const chunks = ArrayUtils.splitToChunks(data, SIZE);
      setChunks(chunks);
    }
    return () => {
      setChunks([]);
    };
  }, [ data ]);
  if (isLoading) {
    return <WrappedLoader />;
  }
  return <>
    <Box sx={{ position: "absolute", right: 0, top: "64px", zIndex: 10 }}>
      {data && data.length > 0 && <Stack 
        spacing={2} 
        sx={{ padding: "1rem", width: "100%" }} 
        direction="row" 
        justifyContent="center"
        alignItems="center"
      >
        {chunks.length > 1 && 
          <Pagination 
            count={chunks.length} 
            onChange={(e, i) => setIndex(--i)} 
            shape="rounded"
            boundaryCount={1}
            siblingCount={0}
          />
        }
        <TextField 
          label={t("filter_search")}
          size="small" 
          onChange={(e) => {
            if (!data) {
              return;
            }
            const SIZE = getRowsCount();
            const value = e.target.value.toLowerCase();
            if (!value) {
              const chunks = ArrayUtils.splitToChunks(data, SIZE);
              setIndex(0);
              return setChunks(chunks);
            }
            const filtered = data.filter((item) => {
              const description = item.description.toLowerCase();
              return description.includes(value);
            });
            setChunks(ArrayUtils.splitToChunks(filtered, SIZE));
            setIndex(0);
          }} 
        />
      </Stack>}
    </Box>
    <StyledTableContainer style={{ borderRight: 0, borderLeft: 0, height: window.innerHeight - 125 }}>
      <Table stickyHeader>
        <TableHead>
          <StyledTableCell>
            {t("code")}
          </StyledTableCell>
          <StyledTableCell>
            {t("description")}
          </StyledTableCell>
          <StyledTableCell>
            {t("operations")}
          </StyledTableCell>
        </TableHead>
        <TableBody>
          {chunks?.[index]?.map((item, index) => 
            <GroupRow 
              item={item} 
              user={user}
              type={type}
              key={item.id}
              index={index}
              refetch={async () => {await refetch();}}
            />)
          }
        </TableBody>
      </Table>
      {data && !data?.length && <Typography m={2}>{t("no_element_found")}</Typography>}
    </StyledTableContainer>
    {isError && <Alert severity="error">{AppUtils.getErrorMessage(error)}</Alert>}
  </>;
};