/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DataLoaderConfigurationPost } from '../models/DataLoaderConfigurationPost';
import type { DataLoaderConfigurationPut } from '../models/DataLoaderConfigurationPut';
import type { SynchronisationResponse } from '../models/SynchronisationResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AdminApiToManageSynchronisationService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Update synchronisation element for organization
   * @returns SynchronisationResponse OK
   * @throws ApiError
   */
  public putSynchronisation({
    organizationId,
    synchronisationId,
    requestBody,
    minutesSyncInterval,
  }: {
    organizationId: string,
    synchronisationId: string,
    requestBody: DataLoaderConfigurationPut,
    minutesSyncInterval?: string,
  }): CancelablePromise<SynchronisationResponse> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/admin/organization/{organizationID}/synchronisation/{synchronisationID}',
      path: {
        'organizationID': organizationId,
        'synchronisationID': synchronisationId,
      },
      query: {
        'minutesSyncInterval': minutesSyncInterval,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Failed to decode authentication token`,
        404: `Entity Not found`,
        405: `Method Not Allowed`,
        409: `Data integrity violation`,
        417: `Expectation Failed`,
        500: `General I/O error`,
      },
    });
  }

  /**
   * Create a new synchronisation element for organization
   * @returns SynchronisationResponse OK
   * @throws ApiError
   */
  public postSynchronisation({
    organizationId,
    minutesSyncInterval,
    requestBody,
  }: {
    organizationId: string,
    minutesSyncInterval: string,
    requestBody: DataLoaderConfigurationPost,
  }): CancelablePromise<SynchronisationResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/admin/organization/{organizationID}/element',
      path: {
        'organizationID': organizationId,
      },
      query: {
        'minutesSyncInterval': minutesSyncInterval,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Failed to decode authentication token`,
        404: `Entity Not found`,
        405: `Method Not Allowed`,
        409: `Data integrity violation`,
        417: `Expectation Failed`,
        500: `General I/O error`,
      },
    });
  }

  /**
   * Delete synchronisation element for organization
   * @returns any OK
   * @throws ApiError
   */
  public deleteSynchronisation({
    organizationId,
    synchronisationId,
  }: {
    organizationId: string,
    synchronisationId: string,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/admin/organization/{organizationID}/delete/synchronisation/{synchronisationID}',
      path: {
        'organizationID': organizationId,
        'synchronisationID': synchronisationId,
      },
      errors: {
        400: `Bad Request`,
        401: `Failed to decode authentication token`,
        404: `Entity Not found`,
        405: `Method Not Allowed`,
        409: `Data integrity violation`,
        417: `Expectation Failed`,
        500: `General I/O error`,
      },
    });
  }

}