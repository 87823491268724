export abstract class ArrayUtils {
  static splitToChunks<T>(array: T[], maxLength: number): T[][] {
    if (array.length > maxLength) {
      const chunks: T[][] = [];
      for (let i = 0, len = array.length; i < len; i += maxLength) {
        chunks.push(array.slice(i, i + maxLength));
      }
      return chunks;
    }
    return [ array ];
  }
}