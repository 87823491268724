import { Delete } from "@mui/icons-material";
import { FormControl, Grow, IconButton, MenuItem, Select, TextField } from "@mui/material";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  PromoRuleModelCondition,
  promoRuleModelConditions,
  PromoRuleModelType,
} from "../../../../../../domain/features/promotions/models/PromoTypes";
import { RulesFormMachine } from "../../../../../../domain/features/promotions/state-machines/RulesFormMachine";
import { useValueChanges } from "../../../../../shared/hooks/basic/UseValueChanges";
import { useMachineStateListener } from "../../../../../shared/hooks/useMachineStateListener";
import { useMachineStateSelector } from "../../../../../shared/hooks/useMachineStateSelector";
import { StyledTableCell, StyledTableRow } from "../../../promotions-table/PromotionsTable.styles";
import { useAuth } from "../../../../../shared/hooks/authentication/useAuth";
import { AutocompleteGroupItems, AutocompleteGroupItemsSingle } from "./AutocompleteGroupItems";
import { FormRulesValue } from "./FilterItemsTable";
import { groupActions } from "../../../../../shared/constants/Actions";
import { isNullish } from "../../../../../../utils/Functions";
import { useEffect } from "react";
import { NumericInput } from "../../../../../shared/numeric-input/NumericInput";

interface FilterItemRowProps<TType extends PromoRuleModelType> {
  machine: RulesFormMachine<TType>;
  index: number;
  types: ReadonlyArray<TType>;
  withoutArticleField?: boolean;
  currentType?: TType;
  condition?: PromoRuleModelCondition;
  isRepeatable?: boolean;
  isPoints?: boolean;
  isGifts?: boolean;
  remove: (n: number) => void;
}

export const FilterItemRow = <TType extends PromoRuleModelType>({
  machine,
  index,
  types,
  withoutArticleField,
  currentType,
  isRepeatable,
  isPoints,
  isGifts,
  remove
}: FilterItemRowProps<TType>) => {
  const { t } = useTranslation("createPromo");

  const { user } = useAuth();

  const methods = useFormContext();

  useMachineStateListener(machine.select((state) => state.rules.at(index)), (rule) => {
    if (rule) {
      methods.setValue(`rules.${index}`, {
        type: rule.type,
        condition: rule.condition,
        ids: rule.ids,
        value: rule.value,
      });
    }
  });

  const state = useMachineStateSelector(machine.select((state) => {
    return { isReadonly: state.isReadonly };
  }));

  // we need to react to the rule type changes
  const ruleType: TType = useWatch({ name: `rules.${index}.type` });

  let conditionType: PromoRuleModelCondition = useWatch({ name: `rules.${index}.condition` });

  if (isPoints) {
    conditionType = "CONTAIN";
  }
  else if (isGifts) {
    conditionType = "EQUAL";
  }

  // values we want to update inside the state machine
  const ruleIds: number[] = useWatch({ name: `rules.${index}.ids` });

  const ruleValue: number = useWatch({ name: `rules.${index}.value` });

  useValueChanges(ruleType, (value) => {
    machine.changeType(index, value);
    // machine.changeIds(index, []);
    // TODO update view
  }, [ machine, index ]);

  useValueChanges(ruleIds, (value) => {
    machine.changeIds(index, value);
  }, [ machine, index ]);

  useValueChanges(conditionType, (value) => {
    machine.changeCondition(index, value);
  }, [ machine, index ]);

  useValueChanges(ruleValue, (value) => {
    machine.changeValue(index, value);
  }, [ machine, index ]);

  useEffect(() => {
    if (isPoints) {
      machine.changeCondition(index, "CONTAIN");
    }
  }, [ isPoints, index, machine ]);

  return <Grow appear={!state.isReadonly} in={true} unmountOnExit={true}>
    <StyledTableRow>
      {!withoutArticleField && <StyledTableCell sx={{ width: "20%" }}>
        <Controller
          render={({ field }) => {
            return <FormControl size="small" sx={{ width: "100%" }}>
              <Select
                readOnly={state.isReadonly}
                {...field}
              >
                {types
                  .filter((item) => {
                    if (isRepeatable) {
                      return ![ "COUPON", "CAMPAIGN" ].includes(item);
                    }
                    else if (isGifts) {
                      return [ "COUPON", "CAMPAIGN", "PRICE" ].includes(item);
                    }
                    return item !== "PRICE";
                  })
                  .map((item, i) =>
                    <MenuItem key={i} value={item}>
                      {t(`rule_types.${item}`)}
                    </MenuItem>,
                  )}
              </Select>
            </FormControl>;
          }}
          name={`rules.${index}.type`}
        />
      </StyledTableCell>
      }

      {!isGifts && !isPoints && <StyledTableCell sx={{ width: "20%" }}>
        <Controller
          render={({ field }) => {
            return <FormControl size="small" sx={{ width: "100%" }}>
              <Select
                readOnly={state.isReadonly}
                {...field}
              >
                {promoRuleModelConditions
                  .filter((item) => isGifts ? item === "EQUAL" : item)
                  .map((item, i) =>
                    <MenuItem key={i} value={item}>
                      {t(`rule_conditions.${item}`)}
                    </MenuItem>
                  )}
              </Select>
            </FormControl>;
          }}
          name={`rules.${index}.condition`}
        />
      </StyledTableCell>}
      <StyledTableCell sx={{ width: "60%" }}>
        <Controller<FormRulesValue>
          name={`rules.${index}.ids`}
          render={({ field }) => {
            if (isGifts) {
              if (ruleType === "PRICE") {
                return <></>;
              }
              return <AutocompleteGroupItemsSingle
                onChange={(value) => field.onChange([ value ])}
                type={ruleType}
                disabled={ruleType === "PRICE"}
                value={field.value as [number]}
                label={t("promo_autocomplete_label")}
                size="small"
              />;
            }
            return <AutocompleteGroupItems
              readOnly={state.isReadonly}
              value={field.value as number[]}
              onChange={(value: number[]) => field.onChange(value)}
              label={t("promo_autocomplete_label")}
              searchOptions={async (userInput) => {
                if (isNullish(user)) {
                  return [];
                }
                return await groupActions.search(user.organizationId, {
                  type: currentType ?? ruleType,
                  userInput,
                });
              }}
              fetchOptions={async (ids) => {
                if(isNullish(user)) {
                  return [];
                }
                return groupActions.readAll(user.organizationId, currentType ?? ruleType, { ids });
              }}
            />;
          }}
        />
      </StyledTableCell>
      {(withoutArticleField || isGifts) && <StyledTableCell sx={{ width: "20%" }}>
        <Controller
          name={`rules.${index}.value`}
          render={({ field }) => {
            if (ruleType === "PRICE") {
              return <NumericInput 
                fullWidth
                size="small"
                onChange={(e) => field.onChange(e)}
                label="€"
                precision={2}
                decimalChar=","
                thousandChar="."
                value={field.value || 0}
              />;
            }
            return <TextField 
              fullWidth
              type="number"
              size="small"
              value={conditionType !== "CONTAIN" && !isGifts ? 0 : field.value || 0}
              onChange={(e) => field.onChange(e)}
              disabled={conditionType !== "CONTAIN" && !isGifts}
            />;
          }}
        />
      </StyledTableCell>}
      <StyledTableCell>
        <IconButton disabled={state.isReadonly} onClick={() => { machine.removeRule(index); remove(index)}}>
          <Delete />
        </IconButton>
      </StyledTableCell>
    </StyledTableRow>
  </Grow> ;
};
