/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PromotionResponse } from '../models/PromotionResponse';
import type { PromotionTemplatePost } from '../models/PromotionTemplatePost';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AdminApiToManagePromotionsService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Create promotion template
   * @returns PromotionResponse OK
   * @throws ApiError
   */
  public postTemplate({
    requestBody,
    promotionTemplateId,
  }: {
    requestBody: PromotionTemplatePost,
    promotionTemplateId?: number,
  }): CancelablePromise<PromotionResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/admin/template',
      query: {
        'promotionTemplateID': promotionTemplateId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Failed to decode authentication token`,
        404: `Entity Not found`,
        405: `Method Not Allowed`,
        409: `Data integrity violation`,
        417: `Expectation Failed`,
        500: `General I/O error`,
      },
    });
  }

}