import { Collapse, Divider, FormControlLabel, TextField } from "@mui/material";
import { isEmpty } from "ramda";
import { Controller, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  PromoRuleModelItemType,
  promoRuleModelItemTypes,
} from "../../../../../../domain/features/promotions/models/PromoTypes";
import { RulesFormMachine } from "../../../../../../domain/features/promotions/state-machines/RulesFormMachine";
import { CheckboxField } from "../../../../../shared/form/fields/basic/CheckboxField";
import { useValueChanges } from "../../../../../shared/hooks/basic/UseValueChanges";
import { useMachineStateListener } from "../../../../../shared/hooks/useMachineStateListener";
import { useMachineStateSelector } from "../../../../../shared/hooks/useMachineStateSelector";
import { useStateMachineCreator } from "../../../../../shared/hooks/useStateMachineCreator";
import { useToggler } from "../../../../../shared/hooks/useToggler";
import { NumericInput } from "../../../../../shared/numeric-input/NumericInput";
import { usePromoStore } from "../../stores/PromoStore";
import { IFormValues } from "../../types";
import { FilterItemsTable } from "../filter-items-table/FilterItemsTable";
import { StyledGrid } from "../minimum-total-receipt/MinimumTotalReceipt.styles";
import { ExpandFiltersButton } from "../../../../../shared/expand-button/ExpandButton";
import { Box } from "@mui/system";

export const RepeatArticlesFields = ({ isAmount }: { isAmount?: boolean }) => {
  const { t } = useTranslation("createPromo");
  const { machine } = usePromoStore();
  const { isOpen, toggle, close } = useToggler();
  const repeatArticlesOrAmounts = isAmount ? "repeatAmounts" : "repeatArticles";

  const rulesMachine = useStateMachineCreator(() => {
    return new RulesFormMachine<PromoRuleModelItemType>({
      initialType: promoRuleModelItemTypes[0],
      initialRules: machine.state[repeatArticlesOrAmounts].data.rules,
      isReadOnly: machine.state[repeatArticlesOrAmounts].isReadOnly,
    });
  });

  const state = useMachineStateSelector(machine.select((state) => {
    const minArticlesOrExpense = isAmount ? state.minTotalExpense : state.minTotalArticles;
    const mustShowTable = !isEmpty(state[repeatArticlesOrAmounts].data.rules) && state[repeatArticlesOrAmounts].isReadOnly;

    return {
      isItems: state.type.value === "ITEMS",
      isReadonly: state[repeatArticlesOrAmounts].isReadOnly || minArticlesOrExpense.isChecked,
      isGroupVisible: state[repeatArticlesOrAmounts].isVisible,
      isGroupDisabled: state[repeatArticlesOrAmounts].isDisabled,
      isDataDisabled: state[repeatArticlesOrAmounts].data.isDisabled,
      filters: state[repeatArticlesOrAmounts].data.rules.length,
      mustShowTable,
    };
  }));

  const repeat = useWatch<IFormValues, "repeatAmounts" | "repeatArticles">({ name: repeatArticlesOrAmounts });
  const repeatCheck = useWatch<IFormValues, "repeatAmountsCheck" | "repeatArticlesCheck">({ name: `${repeatArticlesOrAmounts}Check` });

  useValueChanges(repeat, (value) => {
    if (isAmount) {
      machine.changeRepeatAmounts(value);
    }
    else {
      machine.changeRepeatArticles(value);
    }
  }, [ machine, isAmount ]);

  useValueChanges(repeatCheck, (value) => {
    if (isAmount) {
      machine.changeRepeatAmountsCheck(value);
      if (!value) {
        machine.changeRepeatAmounts(0);
      }
    }
    else {
      machine.changeRepeatArticlesCheck(value);
      if (!value) {
        machine.changeRepeatArticles(0);
      }
    }
    if (!value) {
      rulesMachine.changeRules(state.isReadonly, []);
      close();
    }
  }, [ machine, isAmount ]);

  // useMachineStateListener(machine.select((state) => {
  //   return {
  //     isReadOnly: isAmount ? state.minTotalArticles.isReadOnly : state.minTotalExpense.isReadOnly ,
  //     rules: isAmount ? state.minTotalExpense.data.rules : state.minTotalArticles.data.rules,
  //   };
  // }), (data) => {
  //   rulesMachine.changeRules(data.isReadOnly, data.rules);
  // });

  useMachineStateListener(rulesMachine, (state) => {
    if (!isAmount) {
      machine.changeRepeatArticlesRules(state.rules);
    }
    else {
      machine.changeRepeatAmountsRules(state.rules);
    }
  });

  if (!state.isGroupVisible) {
    return null;
  }

  return <>
    {isAmount && <Divider style={{ marginBottom: "1rem" }} />}
    <Box>
      <StyledGrid style={{ marginBottom: "1rem" }}>
        <Controller<IFormValues, "repeatAmountsCheck" | "repeatArticlesCheck">
          name={`${repeatArticlesOrAmounts}Check`}
          render={({ field }) =>
            <FormControlLabel
              sx={{ fontSize: "1.1rem" }}
              control={<CheckboxField readOnly={state.isReadonly} {...field} />}
              disabled={state.isReadonly}
              label={isAmount ? t("receipt_amount") : t("articles_amount")}
            />}
        />
        <Box sx={{ w: "20%" }}>
          <Controller<IFormValues, typeof repeatArticlesOrAmounts>
            name={repeatArticlesOrAmounts}
            render={({ field }) =>
              isAmount ? 
                <NumericInput
                  {...field}
                  size="small"
                  precision={2}
                  decimalChar=","
                  thousandChar="."
                  label={t("promo_amount_label") + " (€)"}
                  variant="outlined"
                  disabled={state.isDataDisabled}
                  InputProps={{ readOnly: state.isReadonly }}
                /> :
                <TextField
                  {...field}
                  size="small"
                  type="number"
                  label={t("quantity")}
                  disabled={state.isDataDisabled}
                  inputProps={{ inputMode: "decimal" }}
                />}
          />
        </Box> 

        {!state.isItems && 
      <ExpandFiltersButton 
        isOpen={isOpen} 
        filtersLength={state.filters}  
        onClick={toggle} 
        disabled={state.isReadonly || !repeatCheck} 
      />
        }
      </StyledGrid>
      <Collapse appear={!state.isReadonly} in={state.mustShowTable || !state.isItems && isOpen} unmountOnExit>
        <Box sx={{ mb: 2 }}>
          <FilterItemsTable
            isRepeatable={true}
            machine={rulesMachine}
            types={promoRuleModelItemTypes}
          />
        </Box>
      </Collapse>
    </Box>
  </>;
};