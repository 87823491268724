import React, { useRef, useState } from "react";
import { Autocomplete, Box, CircularProgress, TextField } from "@mui/material";
import { useQuery } from "react-query";
import { useDebouncedCallback } from "use-debounce";

import { GroupModel } from "../../../../../../domain/features/groups/models/GroupModel";
import { useAuth } from "../../../../../shared/hooks/authentication/useAuth";
import { groupActions } from "../../../../../shared/constants/Actions";
import { PromoRuleModelType } from "../../../../../../domain/features/promotions/models/PromoTypes";
import { isNullish } from "../../../../../../utils/Functions";

export interface AutocompleteGroupItemsProps {
  readOnly?: boolean;
  /**
   * Label to be shown when the field is idle
   */
  label: string;
  /**
   * This method will be called when user starts typing
   * @param userInput
   */
  searchOptions: (userInput: string) => Promise<GroupModel[]>;
  /**
   * This method will be called for map items to options
   * @param userInput
   */
  fetchOptions: (items: number[]) => Promise<GroupModel[]>;
  /**
   * This method will be called when the user picks a value from the list
   * @param items
   */
  onChange: (items: number[]) => void;
  /**
   * Possible value to be provided
   */
  value: number[];
  multiple?: boolean;
}

export interface AutocompleteGroupItemsSingleProps {
  onChange: (arg: number | undefined) => void;
  type: PromoRuleModelType;
  disabled: boolean;
  value: [number];
  label: string;
  size?: "small" | "medium";
}

/**
 * A wrap on {@link Autocomplete} mui component, implements a search-as-you-type feature.
 * The user can only choose from the list in order to give values, he cannot send random text
 * The user can choose multiple values from the list
 * @param onChange
 * @param label
 * @param getOptions
 * @param value
 * @constructor
 */
export const AutocompleteGroupItems: React.FC<AutocompleteGroupItemsProps> = ({
  readOnly,
  onChange,
  label,
  searchOptions,
  fetchOptions,
  value,
}: AutocompleteGroupItemsProps) => {
  // This doesn't work because it gets killed when the state machine updates the rules array
  const items = useRef<Array<GroupModel>>([]);

  const shouldFetch = value.some((id) => {
    return !items.current.some((e) => id === e.id);
  });

  useQuery([ "fetchItems", value ], async () => {
    if (value.length < 1) return [];
    return await fetchOptions(value);
  }, {
    enabled: shouldFetch,
    onSuccess: (options) => {
      items.current = options;
    },
  });

  const [ userInput, setUserInput ] = useState("");
  
  // search details
  const debounceTime = 500;
  const isSearchEnabled = userInput.length >= 2;

  const debouncedUserInput = useDebouncedCallback<(value: string) => void>(setUserInput, debounceTime);

  const { data: options, error, isLoading } = useQuery([ "searchOptions", userInput ], () => {
    return searchOptions(userInput);
  }, { enabled: isSearchEnabled, retry: false, initialData: () => [] });

  return <Autocomplete<GroupModel, true, false, true>
    fullWidth
    size="small"
    multiple={true}
    autoSelect
    freeSolo
    readOnly={readOnly}
    loading={isLoading}
    value={items.current}
    onInputChange={(event: React.SyntheticEvent, value: string, reason: string) => {
      if (reason === "input") {
        debouncedUserInput(value);
      }
    }}
    isOptionEqualToValue={(option, value) => !(option && value)}
    getOptionLabel={(option) => `${option.description}`}
    options={options!}
    filterOptions={(options) => options}
    onChange={(ev, autocomplete) => {
      if (autocomplete.every((i) => typeof i !== "string")) {
        const _autocomplete = autocomplete as GroupModel[];
        items.current = _autocomplete;
        onChange(_autocomplete.map((e) => e.id));
      }
    }}
    renderOption={(props, option) =>
      <Box component="li" {...props} key={option.code}>
        {option.description}
      </Box>
    }
    renderInput={(params) =>
      <TextField
        {...params}
        multiline={false}
        label={label}
        error={!!error}
        InputProps={{
          ...params.InputProps,
          endAdornment:
            <React.Fragment>
              {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
              {params.InputProps.endAdornment}
            </React.Fragment>,
        }}
      />
    }
  />;
};

export const AutocompleteGroupItemsSingle = ({ onChange, type, value, disabled, label, size }: AutocompleteGroupItemsSingleProps) => {
  const { user } = useAuth();
  const [ userInput, setUserInput ] = useState("");
  const debouncedUserInput = useDebouncedCallback(setUserInput, 500);
  const item = useRef<GroupModel | string>({ code: "", description: "", id: NaN });
  const { data: options } = useQuery([ `searchOptionsSingle.${type}`, userInput ], () => {
    if (!user) {
      return;
    }
    return groupActions.search(user.organizationId, {
      type,
      userInput,
    });
  }, {
    enabled: userInput.length > 0,
    initialData: [],
    retry: false,
  });
  useQuery([ "fetchItem", value ], async () => {
    if (typeof value[0] !== "number" || !user) {
      return;
    }
    return groupActions.readOne(user.organizationId, type, { id: value[0] });
  }, {
    enabled: typeof item.current === "object" && item.current?.id !== value[0],
    onSuccess: (option) => {
      if (!isNullish(option)) {
        item.current = option;
      }
    },
  });
  return <Autocomplete<GroupModel, false, true, true>
    freeSolo
    fullWidth
    size={size}
    disabled={disabled}
    multiple={false}
    options={options ?? []}
    value={item.current}
    onInputChange={(event, value, reason) => {
      if (reason === "input" && value) {
        return debouncedUserInput(value);
      }
      setUserInput(value);
    }}
    onChange={(e, option) => {
      item.current = option;
      if (typeof option === "object" && typeof option?.id === "number") {
        return onChange(option.id);
      }
      return onChange(undefined);
    }}
    isOptionEqualToValue={(option, value) => option.id === value.id}
    renderInput={(props) => <TextField {...props} label={label} />}
    renderOption={(props, option) =>
      <Box component="li" {...props} key={option.id}>
        {option.description}
      </Box>
    }
    getOptionLabel={(option) => typeof option !== "string" ? option.description : ""}
    filterOptions={(option) => option}
  />;
};
