/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UserResponse = {
  id: number;
  email: string;
  authority: UserResponse.authority;
  organizationID?: number;
  active: boolean;
  organizationTrees: Array<number>;
};

export namespace UserResponse {

  export enum authority {
    SERVICE = 'SERVICE',
    USER = 'USER',
    MANAGER = 'MANAGER',
    ADMIN = 'ADMIN',
  }


}
