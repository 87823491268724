import { Container, TableCell, tableCellClasses, TableContainer, TableRow } from "@mui/material/";
import { styled } from "@mui/material/styles";
import { IStyledTheme } from "../../../shared/interfaces";

export const StyledTableCell = styled(TableCell)(({ theme }: IStyledTheme) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.common.white,
    padding: "0.5rem 2rem",
    border: 0,
  },
  [`&.${tableCellClasses.body}`]: { fontSize: 14 },
  textAlign: "center",
}));

export const StyledTableRow = styled(TableRow)(({ theme }: IStyledTheme) => ({
  "&:nth-of-type(odd)": { backgroundColor: theme.palette.grey[50] },
  "&:nth-of-type(even)": { backgroundColor: theme.palette.common.white },
  // hide last border
  "&:last-child td, &:last-child th": { border: 0 },
  width: "100%",
}));

export const StyledTableContainer = styled(TableContainer)(
  ({ theme }: IStyledTheme) => ({
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    border: `1px solid ${theme.palette.divider}`,
  })
);

export const StyledEmptyIcon = styled("div")(
  { width: "2.5rem" }
);

export const StyledContainer = styled(Container)(() => (
  {
    //position: "relative",
    paddingTop: "2rem",
  })
);

export const StyledActivePromotions = styled("div")(() => (
  { position: "relative" })
);

export const StyledAlertSpacer = styled("div")(() => (
  {
    position: "absolute",
    right: 0,
    top: "-90px",

  })
);