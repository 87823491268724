import { AuthActions } from "../../../domain/features/auth/actions/AuthActions";
import { GroupActions } from "../../../domain/features/groups/actions/GroupActions";
import { OrganizationActions } from "../../../domain/features/organizations/actions/OrganizationActions";
import { PromoActions } from "../../../domain/features/promotions/actions/PromoActions";
import { PromoPeriodActions } from "../../../domain/features/promotions/actions/PromoPeriodActions";
import { UserActions } from "../../../domain/features/users/actions/UserActions";
import { appClient } from "./clients/AppClient";
import {
  promoRepository,
  organizationsRepository,
  usersRepository,
  promoPeriodRepository,
  groupRepository, itemsRepository, businessPartnersRepository,
} from "./Repositories";

export const userActions: UserActions = new UserActions(usersRepository);

export const promoActions = new PromoActions(promoRepository);

export const promoPeriodActions = new PromoPeriodActions(promoPeriodRepository, organizationsRepository);

export const organizationActions = new OrganizationActions(organizationsRepository);

export const groupActions: GroupActions = new GroupActions(groupRepository, itemsRepository, businessPartnersRepository);

export const authActions = new AuthActions(appClient, userActions, organizationActions);
