import { groupBy, sortBy } from "../../../../utils/Functions";
import { OrganizationModel } from "../../organizations/models/OrganizationModel";
import { PromoPeriodMappings } from "../mappings/PromoPeriodMappings";
import { PromoPeriodModel } from "../models/PromoPeriodModel";
import { PromoPeriodSaveModel } from "../models/PromoPeriodSaveModel";
import { PromoPeriodsReadAllForCalendarModel } from "../models/PromoPeriodsReadAllForCalendarModel";
import { PromoPeriodRepository } from "../repositories/PromoPeriodRepository";
import { OrganizationsRepository } from "../../organizations/repositories/OrganizationsRepository";

/**
 * Period status:
 * - Activated when it has been activated via the {@see activate} / {@see deactivate} method
 * - Consumed when it is active and the date of the period is in progress or has passed
 */
export class PromoPeriodActions {
  constructor(
    private readonly periodRepository: PromoPeriodRepository,
    private readonly organizationRepository: OrganizationsRepository,
  ) {}

  /**
   * Create or update a promo period
   * You can only change a period when it is deactivated and has not yet been consumed
   * @param organization
   * @param promotionId
   * @param periodId If it is 'undefined' the period will be created
   * @param saveModel
   */
  async save(
    organization: OrganizationModel,
    promotionId: number,
    periodId: undefined | number,
    saveModel: PromoPeriodSaveModel,
  ): Promise<PromoPeriodModel> {
    const { timeZone } = organization;

    const requestBody = PromoPeriodMappings.saveModelToPost(timeZone, saveModel);

    const response = await this.periodRepository.save(organization.id, promotionId, periodId, { requestBody });

    return PromoPeriodMappings.responseToModel(timeZone, response);
  }

  /**
   * You can activate a period at any time
   * @param organization
   * @param promoPeriodId
   */
  async active(organization: OrganizationModel, promoPeriodId: number): Promise<void> {
    await this.periodRepository.active(organization.id, promoPeriodId);
  }

  /**
   * You can deactivate a period at any time
   * @param organization
   * @param promoPeriodId
   */
  async deactivate(organization: OrganizationModel, promoPeriodId: number): Promise<void> {
    await this.periodRepository.deactivate(organization.id, promoPeriodId);
  }

  /**
   * You can only cancel a period when it is deactivated and has not yet been used up
   * @param organization
   * @param promoPeriodId
   */
  async delete(organization: OrganizationModel, promoPeriodId: number): Promise<void> {
    await this.periodRepository.delete(organization.id, promoPeriodId);
  }

  async updateActivesOrder(organization: OrganizationModel, periods: Array<PromoPeriodModel>): Promise<void> {
    const patches = PromoPeriodMappings.modelsToPatches(periods);

    await this.periodRepository.updateActiveOrder(organization.id, patches);
  }

  async readAll(organization: OrganizationModel, params: { active?: boolean; futureActive?: boolean }): Promise<Array<PromoPeriodModel>> {
    const response = await this.periodRepository.readAll(organization.id, params);

    return response.promotionPeriods?.map((period) => {
      return PromoPeriodMappings.responseToModel(organization.timeZone, period);
    }) ?? [];
  }

  async readAllForCalendar(organization: OrganizationModel): Promise<PromoPeriodsReadAllForCalendarModel> {
    const response = await this.periodRepository.readAll(organization.id, {
      active: true,
      futureActive: true,
    });

    const sellingPoints = await this.organizationRepository.readPointsOfSale(organization.id);

    const periods = response.promotionPeriods ?? [];

    const groupedPeriods = groupBy(periods, (period) => period.active);

    const activesPeriods = groupedPeriods.get(true) ?? [];
    const activesSortedPeriods = sortBy(activesPeriods, (promo) => promo.priority);

    const inactivePeriods = groupedPeriods.get(false) ?? [];
    const inactiveSortedPeriods = sortBy(inactivePeriods, (promo) => promo.startDate);

    const actives = PromoPeriodMappings.responsesToModel(organization.timeZone, activesSortedPeriods);
    const inactive = PromoPeriodMappings.responsesToModel(organization.timeZone, inactiveSortedPeriods);

    const ensureChildren = (promoPeriod:PromoPeriodModel) => {
      return {
        ...promoPeriod,
        activeIds: PromoPeriodMappings.includeChildren(promoPeriod.activeIds, sellingPoints),
      };
    };
    return {
      actives:actives.map(ensureChildren),
      inactive:inactive.map(ensureChildren),
    };
  }

  async readAllByPromo(organization: OrganizationModel, promotionId: number): Promise<Array<PromoPeriodModel>> {
    const response = await this.periodRepository.readAllByPromo(organization.id, promotionId);

    return response.promotionPeriods?.map((period) => {
      return PromoPeriodMappings.responseToModel(organization.timeZone, period);
    }) ?? [];
  }

  /**
   * Sets selling points for the provided period
   *
   * @param organization
   * @param promotionId
   * @param periodId
   * @param saveModel
   */
  async setSellingPoints(
    organization: OrganizationModel,
    promotionId: number,
    periodId: number,
    saveModel: PromoPeriodSaveModel,
  ): Promise<PromoPeriodModel> {

    const sellingPoints = await this.organizationRepository.readPointsOfSale(organization.id);
    const { activeIds: sellingPointsToActivate } = saveModel;
    const activeIds = PromoPeriodMappings.excludeChildSellingPoints(sellingPointsToActivate ?? [], sellingPoints);

    return await this.save(
      organization,
      promotionId,
      periodId,
      {
        ...saveModel,
        activeIds,
      },
    );
  }
}

