/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UserResponse } from '../models/UserResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CommonApiToManageUsersService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get user info
   * @returns UserResponse OK
   * @throws ApiError
   */
  public get8(): CancelablePromise<UserResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/common/user',
      errors: {
        400: `Bad Request`,
        401: `Failed to decode authentication token`,
        404: `Entity Not found`,
        405: `Method Not Allowed`,
        409: `Data integrity violation`,
        417: `Expectation Failed`,
        500: `General I/O error`,
      },
    });
  }

}