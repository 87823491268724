import { UserResponse } from "../../../generated/api";
import { UserModel } from "../models/UserModel";

export class UserMappings {
  static responseToModel(response: UserResponse, organizationId: number): UserModel {
    return {
      id: response.id,
      email: response.email,
      organizationId,
    };
  }
}