import { ManagerApiToManageItemsService } from "../../../generated/api";

export class ItemsRepository {
  constructor(private readonly service: ManagerApiToManageItemsService) {}

  readOne(organizationId: number, id: number) {
    return this.service.getItem1({ organizationId, itemId: id });
  }
  readAll(organizationId: number, { ids } : {ids?: Array<number>} = {}) {
    return this.service.get5({
      organizationId,
      itemIDs: ids,
    });
  }
}