import { useState } from "react";

export const useToggler = (defaultValue = false) => {
  const [ isOpen, setIsOpen ] = useState(defaultValue);

  const close = () => setIsOpen(false);
  const open = () => setIsOpen(true);
  const toggle = () => setIsOpen(!isOpen);

  return {
    close,
    isOpen,
    open,
    toggle,
  };
};
