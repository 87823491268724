import { Add } from "@mui/icons-material";
import {
  Button,
  Container,
  SxProps,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { isEmpty } from "ramda";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  PromoRuleModelCondition,
  PromoRuleModelType,
} from "../../../../../../domain/features/promotions/models/PromoTypes";
import { RulesFormState } from "../../../../../../domain/features/promotions/state-machines/RuleFormState";
import { RulesFormMachine } from "../../../../../../domain/features/promotions/state-machines/RulesFormMachine";
import { theme } from "../../../../../../muiTheme";
import { useMachineStateSelector } from "../../../../../shared/hooks/useMachineStateSelector";
import {
  //StyledTableCell,
  StyledTableContainer,
} from "../../../promotions-table/PromotionsTable.styles";
import { FilterItemRow } from "./FilterItemRow";

interface FilterItemsTableProps<TType extends PromoRuleModelType> {
  machine: RulesFormMachine<TType>;
  types: ReadonlyArray<TType>;
  withoutArticleField?: boolean;
  currentType?: TType;
  isRepeatable?: boolean;
  isPoints?: boolean;
  isGifts?: boolean;
  isOpen?: boolean;
}

export interface FormRulesValue {
  rules: Array<{
    type:PromoRuleModelType;
    condition:PromoRuleModelCondition;
    ids: Array<number>;
    value?: number;
  }>;
}

const StyledTableCell = ({ isReadonly, children, sx }: { isReadonly: boolean; children?: React.ReactChild; sx?: SxProps }) => {
  return (
    <TableCell
      sx={{
        [`&.${tableCellClasses.head}`]: {
          background: isReadonly ? theme.palette.grey[600] : theme.palette.secondary.light,
          color: theme.palette.common.white,
          padding: "0.5rem 2rem",
          border: 0,
          textAlign: "center",
        },
        [`&.${tableCellClasses.body}`]: { fontSize: 14 },
        textAlign: "center",
        ...sx,
      }}
    >
      {children}
    </TableCell>
  );
};

export const FilterItemsTable = <TType extends PromoRuleModelType>({
  types,
  machine,
  withoutArticleField,
  currentType,
  isRepeatable,
  isPoints,
  isGifts,
}: FilterItemsTableProps<TType>) => {
  const { t } = useTranslation("createPromo");
  const methods = useForm({ defaultValues: mapMachineStateToFormValues(machine.state) });
  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: "rules",
  });

  const state = useMachineStateSelector(machine.select((state) => {
    return { isReadonly: state.isReadonly, rules: state.rules };
  }));

  return (
    <FormProvider {...methods}>
      <Container disableGutters>
        <StyledTableContainer style={{ borderRadius: theme.shape.borderRadius }}>
          <Table>
            <TableHead>
              <TableRow>
                {!withoutArticleField && <StyledTableCell isReadonly={state.isReadonly}>{t("article")}</StyledTableCell>}
                {!isGifts && !isPoints && <StyledTableCell isReadonly={state.isReadonly}>{t("condition")}</StyledTableCell>}
                <StyledTableCell isReadonly={state.isReadonly}>{t("value")}</StyledTableCell>
                {(withoutArticleField || isGifts) && <StyledTableCell isReadonly={state.isReadonly}>{t("quantity")}</StyledTableCell>}
                <StyledTableCell isReadonly={state.isReadonly}>{t("operations")}</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {fields.map((field, index) =>
                <FilterItemRow<TType>
                  key={field.id}
                  machine={machine}
                  index={index}
                  types={types}
                  withoutArticleField={withoutArticleField}
                  currentType={currentType}
                  isRepeatable={isRepeatable}
                  isPoints={isPoints}
                  isGifts={isGifts}
                  remove={remove}
                />
              )}
            </TableBody>
            {!state.isReadonly && <TableRow
              sx={{ 
                borderTop: `1px solid ${theme.palette.divider}`, 
                backgroundColor: theme.palette.grey[50], 
              }}
            >
              <StyledTableCell isReadonly={state.isReadonly} sx={{ border: 0 }} />
              {!isPoints && <StyledTableCell isReadonly={state.isReadonly} sx={{ border: 0 }} />}
              <StyledTableCell isReadonly={state.isReadonly} sx={{ border: 0 }} />
              <StyledTableCell isReadonly={state.isReadonly} sx={{ border: 0 }}>
                <Button 
                  disableElevation  
                  variant="contained"
                  color="inherit" 
                  onClick={() => { machine.addRule(); append(RulesFormMachine.getInitialRule({ type: types[0] })); }}>
                  <Add />
                </Button> 
              </StyledTableCell>
            </TableRow>}
          </Table>
          {state.isReadonly && isEmpty(fields) ? <Typography m={2}>{t("no_selected_rules")}</Typography> : null}
        </StyledTableContainer>
      </Container>
    </FormProvider>
  );
};

const mapMachineStateToFormValues = (state: RulesFormState<PromoRuleModelType>): FormRulesValue => {
  return {
    rules: state.rules.map((rule) => {
      return {
        type: rule.type,
        condition: rule.condition,
        ids: rule.ids,
        value: rule.value,
      };
    }),
  };
};
