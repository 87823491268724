import React from "react";
import { Controller, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Box, Collapse, FormControlLabel } from "@mui/material";

import {
  PromoRuleModelItemType,
  promoRuleModelItemTypes,
} from "../../../../../../domain/features/promotions/models/PromoTypes";
import { RulesFormMachine } from "../../../../../../domain/features/promotions/state-machines/RulesFormMachine";
import { useValueChanges } from "../../../../../shared/hooks/basic/UseValueChanges";
import { useMachineStateListener } from "../../../../../shared/hooks/useMachineStateListener";
import { useMachineStateSelector } from "../../../../../shared/hooks/useMachineStateSelector";
import { useStateMachineCreator } from "../../../../../shared/hooks/useStateMachineCreator";
import { useToggler } from "../../../../../shared/hooks/useToggler";
import { usePromoStore } from "../../stores/PromoStore";
import { IFormValues } from "../../types";
import { FilterItemsTable } from "../filter-items-table/FilterItemsTable";
import { StyledGrid, StyledMinAmountWrapper } from "./MinimumTotalReceipt.styles";
import { CheckboxField } from "../../../../../shared/form/fields/basic/CheckboxField";
import { NumericInput } from "../../../../../shared/numeric-input/NumericInput";
import { isEmpty } from "ramda";
import { ExpandFiltersButton } from "../../../../../shared/expand-button/ExpandButton";

export const MinimumTotalReceipt: React.FC = () => {
  const { t } = useTranslation("createPromo");
  const { isOpen, toggle, close } = useToggler();
  const { machine } = usePromoStore();

  const state = useMachineStateSelector(machine.select((state) => {
    const mustShowTable = !isEmpty(state.minTotalExpense.data.rules) && state.minTotalExpense.isReadOnly;
    return {
      isGroupReadonly: state.minTotalExpense.isReadOnly,
      isDataDisabled: state.minTotalExpense.data.isDisabled,
      isDisabled: state.minTotalExpense.isDisabled,
      mustShowTable,
      filtersLength: state.minTotalExpense.data.rules.length,
    };
  }));

  const rulesMachine = useStateMachineCreator(() => {
    return new RulesFormMachine<PromoRuleModelItemType>({
      initialType: promoRuleModelItemTypes[0],
      initialRules: machine.state.minTotalExpense.data.rules,
      isReadOnly: state.isGroupReadonly,
    });
  });

  const minTotalExpenseCheck = useWatch<IFormValues, "minTotalExpenseCheck">({ name: "minTotalExpenseCheck" });

  const minTotalExpense = useWatch<IFormValues, "minTotalExpense">({ name: "minTotalExpense" });

  useValueChanges(minTotalExpenseCheck, (value) => {
    machine.changeMinTotalExpenseCheck(value);
    if (!value) {
      machine.changeMinTotalExpense(0);
      machine.changeMinTotalExpenseRules([]);
      close();
    }
  }, [ machine ]);

  useValueChanges(minTotalExpense, (value) => {
    machine.changeMinTotalExpense(value);
  }, [ machine ]);

  useMachineStateListener(machine.select((state) => {
    return {
      isReadOnly: state.minTotalExpense.isReadOnly,
      rules: state.minTotalExpense.data.rules,
    };
  }), (data) => {
    rulesMachine.changeRules(data.isReadOnly, data.rules);
  });

  useMachineStateListener(rulesMachine, (state) => {
    machine.changeMinTotalExpenseRules(state.rules);
  });

  return (
    <Box style={{ marginTop: "2rem", marginBottom: "2rem" }}>
      <StyledGrid>
        <Controller<IFormValues, "minTotalExpenseCheck">
          name="minTotalExpenseCheck"
          render={({ field }) => {
            return <FormControlLabel
              disabled={state.isGroupReadonly || state.isDisabled}
              control={<CheckboxField readOnly={state.isGroupReadonly} {...field} />}
              label={t("receipt_amount")}
            />;
          }}
        />
        <StyledMinAmountWrapper>
          <Controller<IFormValues, "minTotalExpense">
            name="minTotalExpense"
            render={({ field }) =>
              <NumericInput
                size="small"
                precision={2}
                decimalChar=","
                thousandChar="."
                label={t("promo_amount_label") + " (€)"}
                disabled={state.isDataDisabled}
                variant="outlined"
                InputProps={{ readOnly: state.isGroupReadonly }}
                {...field}
              />}
          />
        </StyledMinAmountWrapper>
        <ExpandFiltersButton 
          disabled={state.isGroupReadonly || !minTotalExpenseCheck} 
          onClick={toggle}
          isOpen={isOpen}
          filtersLength={state.filtersLength}
        />
      </StyledGrid>
      <Collapse appear={!state.isGroupReadonly} in={state.mustShowTable || isOpen} unmountOnExit>
        <Box sx={{ mt: 2 }}>
          <FilterItemsTable<PromoRuleModelItemType>
            machine={rulesMachine}
            types={promoRuleModelItemTypes.filter((t) => ![ "COUPON", "CAMPAIGN" ].includes(t))}
          />
        </Box>
      </Collapse>
    </Box>
  );
};

