export abstract class Pages {
  static routeHome = "/" as const;
  static home = "/";

  static routeCalendar = `${this.routeHome}calendar` as const;
  static calendar = `${this.home}calendar`;

  static promoPeriods = `${this.home}promo-periods`;

  static routePromoCreate = `${this.routeHome}promotion` as const;
  static promoCreate = `${this.home}promotion`;

  static routePromoEdit = `${this.promoCreate}/:id` as const;
  static groups = `${this.routeHome}groups` as const;
  static groupCreate = `${this.home}group`;
  static groupEdit = `${this.groupCreate}/:id`;
  static promoEdit(promoId: number) {
    return `${this.promoCreate}/${promoId}`;
  }
}
