/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ManagerApiToManageUsersService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Add point of sale to user
   * @returns void
   * @throws ApiError
   */
  public put({
    organizationId,
    email,
    pointOfSaleCode,
  }: {
    organizationId: number,
    email: string,
    pointOfSaleCode?: string,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/manager/organization/{organizationID}/user/{email}/pointOfSale',
      path: {
        'organizationID': organizationId,
        'email': email,
      },
      query: {
        'pointOfSaleCode': pointOfSaleCode,
      },
      errors: {
        400: `Bad Request`,
        401: `Failed to decode authentication token`,
        404: `Entity Not found`,
        405: `Method Not Allowed`,
        409: `Data integrity violation`,
        417: `Expectation Failed`,
        500: `General I/O error`,
      },
    });
  }

  /**
   * Remove point of sale to user
   * @returns void
   * @throws ApiError
   */
  public delete({
    organizationId,
    email,
    pointOfSaleCode,
  }: {
    organizationId: number,
    email: string,
    pointOfSaleCode?: string,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/manager/organization/{organizationID}/user/{email}/pointOfSale',
      path: {
        'organizationID': organizationId,
        'email': email,
      },
      query: {
        'pointOfSaleCode': pointOfSaleCode,
      },
      errors: {
        400: `Bad Request`,
        401: `Failed to decode authentication token`,
        404: `Entity Not found`,
        405: `Method Not Allowed`,
        409: `Data integrity violation`,
        417: `Expectation Failed`,
        500: `General I/O error`,
      },
    });
  }

  /**
   * Clone users from another user
   * @returns void
   * @throws ApiError
   */
  public patch({
    organizationId,
    email,
    emails,
  }: {
    organizationId: number,
    email: string,
    emails?: Array<string>,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/manager/organization/{organizationID}/user/{email}/clone',
      path: {
        'organizationID': organizationId,
        'email': email,
      },
      query: {
        'emails': emails,
      },
      errors: {
        400: `Bad Request`,
        401: `Failed to decode authentication token`,
        404: `Entity Not found`,
        405: `Method Not Allowed`,
        409: `Data integrity violation`,
        417: `Expectation Failed`,
        500: `General I/O error`,
      },
    });
  }

}