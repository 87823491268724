import { DateTime, Duration, SystemZone } from "luxon";

export class DateTimeUtils {
  static readonly dayDuration = Duration.fromDurationLike({ days: 1 });

  /**
   * @param timeZone Ex. "Europe/Roma"
   * @param seconds timestamp with {@see timeZone}
   * @return date with local time zone
   */
  static fromSeconds = (timeZone: string, seconds: number): Date => {
    const localDateTime = DateTime.fromSeconds(seconds);
    const remoteDateTime = localDateTime.setZone(timeZone, { keepLocalTime: true });
    return remoteDateTime.toJSDate();
  };

  /**
   * @param timeZone Ex. "Europe/Roma"
   * @param date with local zone
   * @return timestamp with {@param timeZone}
   */
  static toSeconds = (timeZone: string, date: Date): number => {
    const remoteDateTime = DateTime.fromJSDate(date, { zone: timeZone });
    const localDateTime = remoteDateTime.setZone(SystemZone.instance, { keepLocalTime: true });
    return localDateTime.toSeconds();
  };
}