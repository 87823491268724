import React from "react";
import { useWatch, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  PromoRuleModelItemType,
  promoRuleModelItemTypes,
} from "../../../../../../domain/features/promotions/models/PromoTypes";
import { RulesFormMachine } from "../../../../../../domain/features/promotions/state-machines/RulesFormMachine";
import { useValueChanges } from "../../../../../shared/hooks/basic/UseValueChanges";
import { useMachineStateListener } from "../../../../../shared/hooks/useMachineStateListener";
import { useMachineStateSelector } from "../../../../../shared/hooks/useMachineStateSelector";
import { useStateMachineCreator } from "../../../../../shared/hooks/useStateMachineCreator";
import { useToggler } from "../../../../../shared/hooks/useToggler";
import { usePromoStore } from "../../stores/PromoStore";
import { IFormValues } from "../../types";
import { Box, Collapse, FormControlLabel, TextField } from "@mui/material";
import { FilterItemsTable } from "../filter-items-table/FilterItemsTable";
import { CheckboxField } from "../../../../../shared/form/fields/basic/CheckboxField";
import { StyledGrid, StyledMinAmountWrapper } from "../minimum-total-receipt/MinimumTotalReceipt.styles";
import { isEmpty } from "ramda";
import { ExpandFiltersButton } from "../../../../../shared/expand-button/ExpandButton";

export const MinimumQuantityItems: React.FC = () => {
  const { t } = useTranslation("createPromo");
  const { toggle, isOpen, close } = useToggler();
  const { machine } = usePromoStore();

  const state = useMachineStateSelector(machine.select((state) => {
    // if filter has items inside and mode is read only -> open table
    const mustShowTable = !isEmpty(state.minTotalArticles.data.rules) && state.minTotalArticles.isReadOnly;

    return {
      isGroupReadonly: state.minTotalArticles.isReadOnly,
      isGroupDisabled: state.minTotalArticles.isDisabled,
      isDataDisabled: state.minTotalArticles.data.isDisabled,
      filtersLength: state.minTotalArticles.data.rules.length,
      mustShowTable,
    };
  }));

  const rulesMachine = useStateMachineCreator(() => {
    return new RulesFormMachine<PromoRuleModelItemType>({
      initialType: promoRuleModelItemTypes[0],
      initialRules: machine.state.minTotalArticles.data.rules,
      isReadOnly: state.isGroupReadonly,
    });
  });

  const minTotalArticlesCheck = useWatch<IFormValues, "minTotalArticlesCheck">({ name: "minTotalArticlesCheck" });

  const minTotalArticles = useWatch<IFormValues, "minTotalArticles">({ name: "minTotalArticles" });

  useValueChanges(minTotalArticlesCheck, (value) => {
    machine.changeMinTotalArticlesCheck(value);
    if (!value) {
      machine.changeMinTotalArticles(0);
      machine.changeMinTotalArticlesRules([]);
      close();
    }
  }, [ machine ]);

  useValueChanges(minTotalArticles, (value) => {
    machine.changeMinTotalArticles(value);
  }, [ machine ]);

  useMachineStateListener(machine.select((state) => {
    return {
      isReadOnly: state.minTotalArticles.isReadOnly,
      rules: state.minTotalArticles.data.rules,
    };
  }), (data) => {
    rulesMachine.changeRules(data.isReadOnly, data.rules);
  });

  useMachineStateListener(rulesMachine, (state) => {
    machine.changeMinTotalArticlesRules(state.rules);
  });

  return (
    <Box sx={{ mb: 2 }}>
      <StyledGrid>
        <Controller<IFormValues, "minTotalArticlesCheck">
          name="minTotalArticlesCheck"
          render={({ field }) =>
            <FormControlLabel
              disabled={state.isGroupReadonly || state.isGroupDisabled}
              control={<CheckboxField readOnly={state.isGroupReadonly} {...field} />}
              label={t("articles_amount")} />}
        />

        <StyledMinAmountWrapper>
          <Controller<IFormValues, "minTotalArticles">
            name="minTotalArticles"
            render={({ field }) =>
              <TextField
                size="small"
                type="number"
                label={t("quantity")}
                disabled={state.isDataDisabled}
                inputProps={{ inputMode: "decimal" }}
                {...field}
              />}
          />
        </StyledMinAmountWrapper>
        <ExpandFiltersButton 
          disabled={state.isGroupReadonly || !minTotalArticlesCheck} 
          onClick={toggle}
          isOpen={isOpen}
          filtersLength={state.filtersLength}
        />
      </StyledGrid>
      <Collapse appear={!state.isGroupReadonly} in={isOpen || state.mustShowTable} unmountOnExit>
        <Box sx={{ mt: 2 }}>
          <FilterItemsTable<PromoRuleModelItemType>
            types={promoRuleModelItemTypes.filter((type) => ![ "COUPON", "CAMPAIGN" ].includes(type))}
            machine={rulesMachine}
          />
        </Box>
      </Collapse>
    </Box>

  );
};
