import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { HashRouter } from "react-router-dom";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import itLocale from "date-fns/locale/it";
import enLocale from "date-fns/locale/en-US";

import App from "./App";
import { theme } from "./muiTheme";
import { LocalizationProvider } from "@mui/lab";
import { authActions } from "./presentation/shared/constants/Actions";
import { ProvideAuth } from "./presentation/shared/hooks/authentication/useAuth";
import ScrollToTop from "./presentation/shared/scroll-to-top/ScrollToTop";
import { useTranslation } from "react-i18next";

type LocalesProviderTypes = {
    [key: string]: Locale;
};

const localesProvider: LocalesProviderTypes = {
  it: itLocale,
  en: enLocale,
};

export const LocalizedApp = () => {
  const { i18n: { language } } = useTranslation();
  const currentLocale = localesProvider[language];
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={currentLocale}>
      <HashRouter>
        <ScrollToTop>
          <React.StrictMode>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <ProvideAuth authActions={authActions}>
                <App />
              </ProvideAuth>
            </ThemeProvider>
          </React.StrictMode>
        </ScrollToTop>
      </HashRouter>
    </LocalizationProvider>
  );
};

