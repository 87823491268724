import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandCircleDown from "@mui/icons-material/ExpandCircleDown";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { Badge, Button } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/system";
import {theme} from "../../../muiTheme"

interface ExpandButtonProps {
  onClick: () => void;
  isOpen: boolean;
  filtersLength: number;
  disabled?: boolean;
}

export const ExpandFiltersButton: React.FC<ExpandButtonProps> = (props) => {
  const { t } = useTranslation("createPromo");
  return <Box>
    <Badge 
      sx={{
        "& .MuiBadge-badge": {
          backgroundColor: props.disabled ? theme.palette.grey[600] : undefined,
        },
      }}
      color="error"
      badgeContent={props.filtersLength}
    >
      <Button 
        onClick={props.onClick}
        variant="outlined"
        disabled={props.disabled} 
        endIcon={props.isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      >
        {t("filter")}
      </Button>
    </Badge>
  </Box>;
};