import { BusinessPartnerResponse, GroupCollectionResponse, GroupResponse, ItemResponse } from "../../../generated/api";
import { GroupModel } from "../models/GroupModel";
import { PromoRuleModelType } from "../../promotions/models/PromoTypes";

export abstract class GroupMappings {
  static responseToModel(response: GroupCollectionResponse, type: PromoRuleModelType): GroupModel[] {
    switch (type) {
    case "ITEM":
      return response.items?.map(GroupMappings.groupItemResponseToGroupModel) ?? [];
    case "ITEM_GROUP":
      return response.itemGroups?.map(GroupMappings.groupResponseToGroupModel) ?? [];
    case "BUSINESS_PARTNER":
      return response.businessPartners?.map(GroupMappings.businessPartnerResponseToGroupModel) ?? [];
    case "BUSINESS_PARTNER_GROUP":
      return response.businessPartnerGroups?.map(GroupMappings.groupResponseToGroupModel) ?? [];
    case "COUPON":
      return response.coupons?.map(GroupMappings.groupResponseToGroupModel) ?? [];
    case "CAMPAIGN":
      return response.campaigns?.map(GroupMappings.groupResponseToGroupModel) ?? [];
    case "PRICE":
      return response.prices?.map(GroupMappings.groupResponseToGroupModel) ?? [];
    case "LOYALTY_LEVEL":
      return response.loyaltyLevels?.map(GroupMappings.groupResponseToGroupModel) ?? [];
    }
  }

  static groupItemResponseToGroupModel = (item: ItemResponse): GroupModel => {
    return {
      id: item.id,
      code: item.itemCode,
      description: item.description,
    };
  };

  static groupResponseToGroupModel = (item: GroupResponse): GroupModel => {
    return {
      id: item.id,
      code: item.code,
      description: item.description,
    };
  };

  static businessPartnerResponseToGroupModel = (item: BusinessPartnerResponse): GroupModel => {
    return {
      id: item.id,
      code: item.bpCode,
      description: item.description,
    };
  };
}
