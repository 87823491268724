import { LoadingButton } from "@mui/lab";
import { Alert, FormControl, InputLabel, MenuItem, Modal, Select, Stack, TextField, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { PromoRuleModelType } from "../../../../domain/features/promotions/models/PromoTypes";
import { UserModel } from "../../../../domain/features/users/models/UserModel";
import { AppUtils } from "../../../shared/AppUtils";
import { groupActions } from "../../../shared/constants/Actions";
import { StyledBox, StyledCloseIcon } from "../../promotions/periods/create-promo-period/CreatePromoPeriod.styles";

interface CreateGroupItemProps {
    isOpen: boolean;
    close: () => void;
    types: PromoRuleModelType[];
    user: UserModel | undefined;
}

interface CreateGroupItemForm {
    groupItemType: PromoRuleModelType;
    groupItemCode: string;
    groupItemDescription: string;
}

export const CreateGroupItem = ({ isOpen, close, types, user }: CreateGroupItemProps) => {
  const { t } = useTranslation("createPromo");
  const { t: t2 } = useTranslation("groupsTable");
  const methods = useForm<CreateGroupItemForm>();
  const resetAndClose = () => {
    methods.reset();
    close();
  };
  const { mutate, isLoading, isError, error } = useMutation("createGroupItem", () => {
    const type = methods.getValues("groupItemType");
    const code = methods.getValues("groupItemCode");
    const description = methods.getValues("groupItemDescription");
    return groupActions.addOne(user!.organizationId, type, {
      code,
      description,
    });
  }, { onSuccess: resetAndClose });
  return <>
    <Modal
      open={isOpen}
      sx={{ backdropFilter: "blur(4px)" }}
    >
      <StyledBox>
        <StyledCloseIcon onClick={resetAndClose} />
        <Typography align="center" variant="h6" component="h2" sx={{ mb: "2rem" }}>
          {t2("add_element").toUpperCase()}
        </Typography>
        <Stack spacing={2} alignItems="center">
          <Controller<CreateGroupItemForm> {...methods}
            name="groupItemType"
            render={({ field }) => <FormControl fullWidth size="small">
              <InputLabel id="create-group-item-label">{t2("type")}</InputLabel>
              <Select {...field} label="Tipo" labelId="create-group-item-label">
                {types.map((type) => <MenuItem value={type}>{t(`rule_types.${type}`)}</MenuItem>)}
              </Select>
            </FormControl>}
          />
          <Controller<CreateGroupItemForm> {...methods}
            name="groupItemCode"
            render={({ field }) => <TextField {...field} label={t2("code")} size="small" fullWidth />}
          />
          <Controller<CreateGroupItemForm> {...methods}
            name="groupItemDescription"
            render={({ field }) => <TextField {...field} label={t2("description")} size="small" fullWidth sx={{ mb: "2rem" }} />}
          />
          <LoadingButton 
            loading={isLoading} 
            variant="contained" 
            sx={{ width: "30%" }}
            style={{ marginTop: "2rem" }}
            onClick={() => mutate()}
          >
            {t2("save")}
          </LoadingButton>
          {isError && <Alert severity="error">{AppUtils.getErrorMessage(error)}</Alert>}
        </Stack>
      </StyledBox>
    </Modal>
  </>;
};