/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CsvResponse } from '../models/CsvResponse';
import type { GroupCollectionResponse } from '../models/GroupCollectionResponse';
import type { GroupPatch } from '../models/GroupPatch';
import type { GroupPost } from '../models/GroupPost';
import type { GroupResponse } from '../models/GroupResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ManagerApiToManageGroupService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get bp group, price list, item groups, coupons, campaigns, prices or loyalty level in organization
   * @returns GroupCollectionResponse OK
   * @throws ApiError
   */
  public getGroups({
    organizationId,
    type,
    pageNumber,
    numOfElement,
    groupIDs,
  }: {
    organizationId: number,
    type: 'BusinessPartnerGroups' | 'ItemGroups' | 'PriceLists' | 'Coupons' | 'Campaigns' | 'Prices' | 'LoyaltyLevels',
    pageNumber?: number,
    numOfElement?: number,
    groupIDs?: Array<number>,
  }): CancelablePromise<GroupCollectionResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/manager/organization/{organizationID}/{type}',
      path: {
        'organizationID': organizationId,
        'type': type,
      },
      query: {
        'pageNumber': pageNumber,
        'numOfElement': numOfElement,
        'groupIDs': groupIDs,
      },
      errors: {
        400: `Bad Request`,
        401: `Failed to decode authentication token`,
        404: `Entity Not found`,
        405: `Method Not Allowed`,
        409: `Data integrity violation`,
        417: `Expectation Failed`,
        500: `General I/O error`,
      },
    });
  }

  /**
   * Create a new item group, price list, bp group, coupon, campaign, price or loyalty level in the organization
   * @returns GroupResponse OK
   * @throws ApiError
   */
  public postGroup({
    organizationId,
    type,
    requestBody,
  }: {
    organizationId: number,
    type: 'BusinessPartnerGroups' | 'ItemGroups' | 'PriceLists' | 'Coupons' | 'Campaigns' | 'Prices' | 'LoyaltyLevels',
    requestBody: GroupPost,
  }): CancelablePromise<GroupResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/manager/organization/{organizationID}/{type}',
      path: {
        'organizationID': organizationId,
        'type': type,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Failed to decode authentication token`,
        404: `Entity Not found`,
        405: `Method Not Allowed`,
        409: `Data integrity violation`,
        417: `Expectation Failed`,
        500: `General I/O error`,
      },
    });
  }

  /**
   * Import groups from csv file
   * @returns CsvResponse OK
   * @throws ApiError
   */
  public postGroups({
    organizationId,
    type = 'BusinessPartnerGroups',
    formData,
  }: {
    organizationId: number,
    type?: 'BusinessPartnerGroups' | 'ItemGroups' | 'PriceLists' | 'Coupons' | 'Campaigns' | 'Prices' | 'LoyaltyLevels',
    formData?: {
      file: Blob;
    },
  }): CancelablePromise<CsvResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/manager/organization/{organizationID}/{type}/csv',
      path: {
        'organizationID': organizationId,
        'type': type,
      },
      formData: formData,
      mediaType: 'multipart/form-data',
      errors: {
        400: `Bad Request`,
        401: `Failed to decode authentication token`,
        404: `Entity Not found`,
        405: `Method Not Allowed`,
        409: `Data integrity violation`,
        417: `Expectation Failed`,
        500: `General I/O error`,
      },
    });
  }

  /**
   * Import relationship between either bps and bp groups or items and item groups from csv file
   * @returns any OK
   * @throws ApiError
   */
  public postGroupsAndBusinessPartners({
    organizationId,
    type = 'BusinessPartnerGroups',
    formData,
  }: {
    organizationId: number,
    type?: 'BusinessPartnerGroups' | 'ItemGroups',
    formData?: {
      file: Blob;
    },
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/manager/organization/{organizationID}/relationship/{type}/csv',
      path: {
        'organizationID': organizationId,
        'type': type,
      },
      formData: formData,
      mediaType: 'multipart/form-data',
      errors: {
        400: `Bad Request`,
        401: `Failed to decode authentication token`,
        404: `Entity Not found`,
        405: `Method Not Allowed`,
        409: `Data integrity violation`,
        417: `Expectation Failed`,
        500: `General I/O error`,
      },
    });
  }

  /**
   * Get bp group, price list, item group, coupon, campaign, price or loyalty level
   * @returns GroupResponse OK
   * @throws ApiError
   */
  public getItem({
    organizationId,
    typeId,
    type = 'BusinessPartnerGroups',
  }: {
    organizationId: number,
    typeId: number,
    type?: 'BusinessPartnerGroups' | 'ItemGroups' | 'Coupons' | 'Campaigns' | 'Prices' | 'LoyaltyLevels',
  }): CancelablePromise<GroupResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/manager/organization/{organizationID}/{type}/{typeID}',
      path: {
        'organizationID': organizationId,
        'type': type,
        'typeID': typeId,
      },
      errors: {
        400: `Bad Request`,
        401: `Failed to decode authentication token`,
        404: `Entity Not found`,
        405: `Method Not Allowed`,
        409: `Data integrity violation`,
        417: `Expectation Failed`,
        500: `General I/O error`,
      },
    });
  }

  /**
   * Modify description
   * @returns GroupResponse OK
   * @throws ApiError
   */
  public patchGroup({
    organizationId,
    type,
    typeId,
    requestBody,
  }: {
    organizationId: number,
    type: 'BusinessPartnerGroups' | 'ItemGroups' | 'PriceLists' | 'Coupons' | 'Campaigns' | 'Prices' | 'LoyaltyLevels',
    typeId: number,
    requestBody: GroupPatch,
  }): CancelablePromise<GroupResponse> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/manager/organization/{organizationID}/{type}/{typeID}',
      path: {
        'organizationID': organizationId,
        'type': type,
        'typeID': typeId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Failed to decode authentication token`,
        404: `Entity Not found`,
        405: `Method Not Allowed`,
        409: `Data integrity violation`,
        417: `Expectation Failed`,
        500: `General I/O error`,
      },
    });
  }

  /**
   * Get requested search
   * @returns GroupCollectionResponse OK
   * @throws ApiError
   */
  public getSearch({
    organizationId,
    registryType,
    request,
    numOfElement,
  }: {
    organizationId: number,
    registryType: 'BUSINESS_PARTNER_GROUP' | 'BUSINESS_PARTNER' | 'ITEM_GROUP' | 'ITEM' | 'PRICE_LIST' | 'COUPON' | 'CAMPAIGN' | 'PRICE' | 'LOYALTY_LEVEL',
    request: string,
    numOfElement: number,
  }): CancelablePromise<GroupCollectionResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/manager/organization/{organizationID}/search',
      path: {
        'organizationID': organizationId,
      },
      query: {
        'registryType': registryType,
        'request': request,
        'numOfElement': numOfElement,
      },
      errors: {
        400: `Bad Request`,
        401: `Failed to decode authentication token`,
        404: `Entity Not found`,
        405: `Method Not Allowed`,
        409: `Data integrity violation`,
        417: `Expectation Failed`,
        500: `General I/O error`,
      },
    });
  }

}