import { insertAt, removeAt, replaceAt } from "../../../../utils/Array";
import { StateMachine } from "../../../shared/state-machine/StateMachine";
import { PromoGiftRuleModel } from "../models/PromoModelCommons";
import { PromoRuleModelCondition, PromoRuleModelType } from "../models/PromoTypes";
import { GiftRulesFormState, PromoRule, RulesFormState } from "./RuleFormState";

export class RulesFormMachine<TType extends PromoRuleModelType> extends StateMachine<RulesFormState<TType>> {
  private readonly __initialType: TType;

  constructor({
    isReadOnly = false,
    initialType,
    initialRules = [],
  }: {
    isReadOnly?: boolean;
    initialType: TType;
    initialRules?: Array<PromoRule<TType>>;
  }) {
    super({
      isReadonly: isReadOnly,
      rules: initialRules,
    });

    this.__initialType = initialType;
  }

  changeRules(isReadonly: boolean, rules: Array<PromoRule<TType>>) {
    this.updateState({ ...this.state, isReadonly, rules });
  }

  /**
   * Adds a rule at the specified index
   * @param insertIndex, if missing insert it at the end of {@link RulesFormMachine.state.rules}
   * @param copyFrom optionally, add a rule by copying an already-existent rule
   */
  addRule({
    index,
    copyFrom,
  }: { index?: number; copyFrom?: number } = {}) {
    let newRule: PromoRule<TType>;

    if (copyFrom !== undefined) {
      newRule = this.state.rules[copyFrom];
    } else {
      newRule = RulesFormMachine.getInitialRule({ type: this.__initialType });
    }

    const rules = this.state.rules;
    this.updateState({
      ...this.state,
      rules: insertAt(rules, index ?? rules.length, newRule),
    });
  }

  /**
   * Removes a rule at the specified index
   *
   * @param index
   */
  removeRule(index: number) {
    this.updateState({
      ...this.state,
      rules: removeAt(this.state.rules, index),
    });
  }

  /**
   * Changes the type of the rule at the given index
   *
   * @param index
   * @param value
   */
  changeType(index: number, value: TType) {
    const rule: PromoRule<TType> = {
      ...this.state.rules[index],
      type: value,
    };
    this.updateState({
      ...this.state,
      rules: replaceAt(this.state.rules, index, rule),
    });
  }

  /**
   * Changes the condition of the rule at the given index
   *
   * @param index
   * @param value
   */
  changeCondition(index: number, value: PromoRuleModelCondition) {
    const rule: PromoRule<TType> = {
      ...this.state.rules[index],
      condition: value,
    };
    this.updateState({
      ...this.state,
      rules: replaceAt(this.state.rules, index, rule),
    });
  }

  /**
   * Changes the ids of a rule at the given index
   *
   * @param index
   * @param value
   */
  changeIds(index: number, value: Array<number>) {
    const rule: PromoRule<TType> = {
      ...this.state.rules[index],
      ids: value,
    };
    this.updateState({
      ...this.state,
      rules: replaceAt(this.state.rules, index, rule),
    });
  }

  changeValue(index: number, value: number | undefined) {
    const rule: PromoRule<TType> = {
      ...this.state.rules[index],
      value: value || undefined,
    };
    this.updateState({
      ...this.state,
      rules: replaceAt(this.state.rules, index, rule),
    });
  }
  
  static getInitialRule<TType extends PromoRuleModelType>({ type }: { type: TType }): PromoRule<TType> {
    return {
      type: type,
      condition: "EQUAL",
      ids: [],
    };
  }
}

export class GiftRulesFormMachine extends StateMachine<GiftRulesFormState> {
  static getInitialRule(): PromoGiftRuleModel {
    return {
      value: 0,
      itemID: undefined,
      conditions: [],
    };
  }

  constructor({ isReadonly = false, initialRules = [] as PromoGiftRuleModel[] }) {
    super({
      isReadonly,
      rules: initialRules,
    });
  }
  changeRules(isReadonly: boolean, rules: PromoGiftRuleModel[]) {
    this.updateState({ ...this.state, isReadonly, rules });
  }

  addRule({ index, copyFrom }: { index?: number; copyFrom?: number } = {}) {
    let rule;
    if (copyFrom !== undefined) {
      rule = this.state.rules[copyFrom];
    }
    else {
      rule = GiftRulesFormMachine.getInitialRule();
    }
    const rules = this.state.rules;
    this.updateState({
      ...this.state,
      rules: insertAt(rules, index ?? rules.length, rule),
    });
  }

  removeRule(index: number) {
    this.updateState({
      ...this.state,
      rules: removeAt(this.state.rules, index),
    });
  }

  changeItemID({ index, id }: { index: number; id: number | undefined }) {
    const rule = {
      ...this.state.rules[index],
      itemID: id,
    };
    this.updateState({
      ...this.state,
      rules: replaceAt(this.state.rules, index, rule),
    });
  }

  changeConditions({ index, conditions }: { index: number; conditions: PromoGiftRuleModel["conditions"] }) {
    const rule = {
      ...this.state.rules[index],
      conditions,
    };
    this.updateState({
      ...this.state,
      rules: replaceAt(this.state.rules, index, rule),
    });
  }

  changeValue({ index, value }: { index: number; value: number }) {
    const rule = {
      ...this.state.rules[index],
      value,
    };
    this.updateState({
      ...this.state,
      rules: replaceAt(this.state.rules, index, rule),
    });
  }
}
