import { ManagerApiToManageBusinessPartnerService } from "../../../generated/api";

export class BusinessPartnersRepository {
  constructor(private readonly service: ManagerApiToManageBusinessPartnerService) {}

  async readAll(organizationId: number, { ids } : {ids?: Array<number>} = {}) {
    return this.service.getBusinessPartners({
      organizationId,
      businessPartnerIDs: ids,
    });
  }
}