/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UserCollectionResponse } from '../models/UserCollectionResponse';
import type { UserPost } from '../models/UserPost';
import type { UserPut } from '../models/UserPut';
import type { UserResponse } from '../models/UserResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AdminApiToManageUserService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Active user
   * @returns void
   * @throws ApiError
   */
  public put3({
    email,
  }: {
    email: string,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/admin/user/{email}',
      path: {
        'email': email,
      },
      errors: {
        400: `Bad Request`,
        401: `Failed to decode authentication token`,
        404: `Entity Not found`,
        405: `Method Not Allowed`,
        409: `Data integrity violation`,
        417: `Expectation Failed`,
        500: `General I/O error`,
      },
    });
  }

  /**
   * Deactivate user
   * @returns void
   * @throws ApiError
   */
  public delete2({
    email,
  }: {
    email: string,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/admin/user/{email}',
      path: {
        'email': email,
      },
      errors: {
        400: `Bad Request`,
        401: `Failed to decode authentication token`,
        404: `Entity Not found`,
        405: `Method Not Allowed`,
        409: `Data integrity violation`,
        417: `Expectation Failed`,
        500: `General I/O error`,
      },
    });
  }

  /**
   * Update user authority
   * @returns void
   * @throws ApiError
   */
  public put4({
    email,
    authority,
  }: {
    email: string,
    authority: 'user' | 'manager',
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/admin/user/{email}/{authority}',
      path: {
        'email': email,
        'authority': authority,
      },
      errors: {
        400: `Bad Request`,
        401: `Failed to decode authentication token`,
        404: `Entity Not found`,
        405: `Method Not Allowed`,
        409: `Data integrity violation`,
        417: `Expectation Failed`,
        500: `General I/O error`,
      },
    });
  }

  /**
   * Change user password
   * @returns void
   * @throws ApiError
   */
  public put5({
    email,
    requestBody,
  }: {
    email: string,
    requestBody: UserPut,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/admin/user/{email}/password',
      path: {
        'email': email,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Failed to decode authentication token`,
        404: `Entity Not found`,
        405: `Method Not Allowed`,
        409: `Data integrity violation`,
        417: `Expectation Failed`,
        500: `General I/O error`,
      },
    });
  }

  /**
   * Create a new user
   * @returns UserResponse OK
   * @throws ApiError
   */
  public post3({
    requestBody,
  }: {
    requestBody: UserPost,
  }): CancelablePromise<UserResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/admin/user',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Failed to decode authentication token`,
        404: `Entity Not found`,
        405: `Method Not Allowed`,
        409: `Data integrity violation`,
        417: `Expectation Failed`,
        500: `General I/O error`,
      },
    });
  }

  /**
   * Create a new service user
   * @returns UserResponse OK
   * @throws ApiError
   */
  public postServiceUser({
    requestBody,
  }: {
    requestBody: UserPost,
  }): CancelablePromise<UserResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/admin/user/service',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Failed to decode authentication token`,
        404: `Entity Not found`,
        405: `Method Not Allowed`,
        409: `Data integrity violation`,
        417: `Expectation Failed`,
        500: `General I/O error`,
      },
    });
  }

  /**
   * Get all users info
   * @returns UserCollectionResponse OK
   * @throws ApiError
   */
  public get9(): CancelablePromise<UserCollectionResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/admin/users',
      errors: {
        400: `Bad Request`,
        401: `Failed to decode authentication token`,
        404: `Entity Not found`,
        405: `Method Not Allowed`,
        409: `Data integrity violation`,
        417: `Expectation Failed`,
        500: `General I/O error`,
      },
    });
  }

  /**
   * Get all users in organization
   * @returns UserCollectionResponse OK
   * @throws ApiError
   */
  public getServiceUser({
    organizationId,
  }: {
    organizationId: number,
  }): CancelablePromise<UserCollectionResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/admin/user/organization/{organizationID}',
      path: {
        'organizationID': organizationId,
      },
      errors: {
        400: `Bad Request`,
        401: `Failed to decode authentication token`,
        404: `Entity Not found`,
        405: `Method Not Allowed`,
        409: `Data integrity violation`,
        417: `Expectation Failed`,
        500: `General I/O error`,
      },
    });
  }

}