import { DateTime, FixedOffsetZone, IANAZone, SystemZone } from "luxon";

export class DateUtils {
  /**
   * Converts {@see seconds} with {@see timeZone} to local date without timezone and time
   */
  static fromSeconds = (seconds: number, timeZone: string): Date => {
    const zone = IANAZone.create(timeZone);

    const actualDateTime = DateTime.fromSeconds(seconds, { zone });

    const utcDateTime = actualDateTime.setZone(FixedOffsetZone.utcInstance, { keepLocalTime: true });
    const utcDateTimeWithoutTime = utcDateTime.startOf("day");

    const targetDateTime = utcDateTimeWithoutTime.setZone(SystemZone.instance);

    return targetDateTime.toJSDate();
  };

  /**
   * Convert the local {@see date} by removing the time and adding the {@see timezone}
   */
  static toSeconds = (date: Date, timeZone: string): number => {
    const zone = IANAZone.create(timeZone);

    const actualDateTime = DateTime.fromJSDate(date);

    const utcDateTime = actualDateTime.setZone(FixedOffsetZone.utcInstance);
    const utcDateTimeWithoutTime = utcDateTime.startOf("day");

    const targetDateTime = utcDateTimeWithoutTime.setZone(zone, { keepLocalTime: true });

    return targetDateTime.toSeconds();
  };
}