import { Failure } from "./Failure";
import { isError } from "../../../utils/Functions";

export const isAppError = (error: unknown): error is AppError => {
  return isError(error) ? error.name === "AppError" : false;
};

export class AppError extends Error {
  public readonly failure: Failure;

  constructor(failure: Failure) {
    super();

    this.name = "AppError";
    this.failure = failure;
    this.message = failure.message;
  }
}
