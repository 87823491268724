import { createTheme, Theme } from "@mui/material/styles";
import { lightBlue, red, grey } from "@mui/material/colors";

// A custom theme for this app
export const theme: Theme = createTheme({
  palette: {
    primary: {
      main: "#1661BE",
      light: "#00B9F2",
    },
    secondary: { main: "#19857b", light: lightBlue.A700 },
    error: { main: red.A400 },
    background: {
      default: "#fff",
      paper: grey[50],
    },
  },
  components: {
    MuiIconButton: {
      defaultProps: { color: "inherit" },
      styleOverrides: { colorInherit: "#000000" }, 
    }, 
  },
});
