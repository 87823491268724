import { DateTime } from "luxon";
import {
  OrganizationTreeResponse,
  PromotionPeriodPatch,
  PromotionPeriodPost,
  PromotionPeriodResponse,
} from "../../../generated/api";
import { DateTimeUtils } from "../../../utils/DateTimeUtils";
import { DateUtils } from "../../../utils/DateUtils";
import { TreeUtils } from "../../../utils/TreeUtils";
import { PromoPeriodModel } from "../models/PromoPeriodModel";
import { PromoPeriodSaveModel } from "../models/PromoPeriodSaveModel";
import { uniq } from "ramda";

export class PromoPeriodMappings {
  static responsesToModel = (
    timeZone: string,
    responses: Array<PromotionPeriodResponse>,
  ): Array<PromoPeriodModel> => {
    return responses.map((response) =>
      this.responseToModel(timeZone, response),
    );
  };

  static responseToModel = (
    timeZone: string,
    response: PromotionPeriodResponse,
  ): PromoPeriodModel => {
    const endDate = DateUtils.fromSeconds(response.endDate, timeZone);
    const fixedEndDate = DateTime.fromJSDate(endDate)
      .minus(DateTimeUtils.dayDuration)
      .toJSDate();
    return {
      id: response.id,
      promotionId: response.promotionID,
      active: response.active,
      startDate: DateUtils.fromSeconds(response.startDate, timeZone),
      endDate: fixedEndDate,
      activeIds: response.activeIDs ?? [],
      priority: response.priority,
    };
  };

  static saveModelToPost = (
    timeZone: string,
    model: PromoPeriodSaveModel,
  ): PromotionPeriodPost => {
    const fixedEndDate = DateTime.fromJSDate(model.endDate)
      .plus(DateTimeUtils.dayDuration)
      .toJSDate();
    return {
      startDate: DateUtils.toSeconds(model.startDate, timeZone),
      endDate: DateUtils.toSeconds(fixedEndDate, timeZone),
      activeIDs: model.activeIds,
    };
  };

  static modelsToPatches = (
    models: Array<PromoPeriodModel>,
  ): Array<PromotionPeriodPatch> => {
    return models.map((model, index) => {
      return {
        promotionPeriodID: model.id,
        priority: index,
      };
    });
  };

  static excludeChildSellingPoints = (
    toActivate: Array<number>,
    sellingPointsTree: OrganizationTreeResponse,
  ): Array<number> => {
    const allChildren = toActivate.flatMap((sellingPointId) => {
      const sellingPoint = TreeUtils.findNode(sellingPointsTree, sellingPointId)!;
      return TreeUtils.findChildren(sellingPoint);
    });

    // Removes the ids already present in the children
    return toActivate.filter((sellingPointId) => {
      return !allChildren.includes(sellingPointId);
    });
  };

  /**
   * If only the parent id is present inside {@activeIds}, adds all the children ids
   * @param activeIds
   * @param fullList
   */
  static includeChildren(activeIds: Array<number>, fullList: OrganizationTreeResponse): Array<number> {

    const pointsOfSale = activeIds.map((activeId) => {
      return TreeUtils.findNode(fullList, activeId)!;
    });

    let activeIdsWithChildren: number[] = [];
    pointsOfSale.forEach((pointOfSale) => {

      const isParent = pointOfSale.children !== undefined;

      activeIdsWithChildren.push(pointOfSale.id);
      if (isParent) {
        const childrenIds = pointOfSale.children!.map((it) => it.id);
        const shouldAddChildren = activeIds.every((it) => {
          return !childrenIds.includes(it);
        });

        if (shouldAddChildren) {
          activeIdsWithChildren = [ ...activeIdsWithChildren, ...childrenIds ];
        }
      }
    });

    return uniq(activeIdsWithChildren);
  }
}
