/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ConditionPost } from './ConditionPost';

export type RulePost = {
  ruleType: RulePost.ruleType;
  value?: number;
  repeatable: boolean;
  conditions: Array<ConditionPost>;
};

export namespace RulePost {

  export enum ruleType {
    APPLICABLE = 'APPLICABLE',
    MIN_QUANTITY = 'MIN_QUANTITY',
    MIN_AMOUNT = 'MIN_AMOUNT',
    PRICE_LIST = 'PRICE_LIST',
  }


}
