import { Container, Table, TableBody, TableHead, TableRow, Typography } from "@mui/material/";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { WrappedLoader } from "../../../shared/wrapped-loader/WrappedLoader";
import { StyledTableCell, StyledTableContainer } from "./PromotionsTable.styles";
import { promoActions } from "../../../shared/constants/Actions";
import { useAuth } from "../../../shared/hooks/authentication/useAuth";
import { PromotionTableRow } from "./PromotionTableRow";
import { isNullish } from "../../../../utils/Functions";
import { Pages } from "../../../shared/pages";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import StoreIcon from "@mui/icons-material/Store";
import { StyledContent, StyledHeading } from "../../../shared/heading/Heading.style";
import { theme } from "../../../../muiTheme";

export const PromotionsTable = () => {
  const { t:headingT } = useTranslation("pages");
  const navigate = useNavigate();
  const { t } = useTranslation("promotionsTable");

  const { user } = useAuth();
  const organizationId = user?.organizationId;

  const { isLoading, data, refetch: refetchPromotions } = useQuery("fetchPromotions", () => {
    return promoActions.readAll(organizationId!);
  }, { enabled: !isNullish(organizationId) });

  if (isLoading) {
    return <WrappedLoader />;
  }

  return (
    <Container disableGutters style={{ marginBottom: 0 }} maxWidth={false}>
      <StyledHeading sx={{ paddingBottom: 0, marginBottom: 0 }}>
        <StyledContent>
          <StoreIcon fontSize="large" />
          <Typography
            className="heading"
            mb={0}
            variant="h4"
            gutterBottom
            component="div"
          >
            {headingT(Pages.routeHome)}
          </Typography>
          <Button variant="contained" onClick={() => navigate(Pages.promoCreate)}>{headingT(Pages.routePromoCreate)}</Button>
        </StyledContent>
      </StyledHeading>
      <StyledTableContainer disableGutters style={{ height: window.innerHeight - 75, borderRight: 0, borderLeft: 0 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <StyledTableCell>{t("status")}</StyledTableCell>
              <StyledTableCell>{t("promo_code")}</StyledTableCell>
              <StyledTableCell>{t("promo_desc")}</StyledTableCell>
              <StyledTableCell>{t("operations") }</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((promotion) =>
              <PromotionTableRow key={promotion.id} promo={promotion} refetchPromos={refetchPromotions} />)}
          </TableBody>
        </Table>
      </StyledTableContainer>
    </Container>
  );
};
