import { OrganizationTreeResponse } from "../../../generated/api";
import { PointOfSaleModel } from "../models/PointOfSaleModel";

export abstract class PointOfSaleMappings {
  static responseToModel(pointOfSaleResponse: OrganizationTreeResponse): PointOfSaleModel {
    return {
      id: pointOfSaleResponse.id,
      parentId: pointOfSaleResponse.parentID,
      active: pointOfSaleResponse.active,
      code: pointOfSaleResponse.code,
      timeZone: pointOfSaleResponse.timeZone,
      description: pointOfSaleResponse.description,
      children: pointOfSaleResponse
        .children
        ?.map((child) => PointOfSaleMappings.responseToModel(child))
        ?? [],
    };
  }
}
