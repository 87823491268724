/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ConditionResponse = {
  id: number;
  filter: ConditionResponse.filter;
  value?: number;
  type: ConditionResponse.type;
  matches?: Array<string>;
  fieldIDs?: Array<number>;
};

export namespace ConditionResponse {

  export enum filter {
    EQUAL = 'EQUAL',
    NOT_EQUAL = 'NOT_EQUAL',
    CONTAIN = 'CONTAIN',
    NOT_CONTAIN = 'NOT_CONTAIN',
    MATCH = 'MATCH',
    NOT_MATCH = 'NOT_MATCH',
  }

  export enum type {
    BUSINESS_PARTNER_GROUP = 'BUSINESS_PARTNER_GROUP',
    BUSINESS_PARTNER = 'BUSINESS_PARTNER',
    ITEM_GROUP = 'ITEM_GROUP',
    ITEM = 'ITEM',
    PRICE_LIST = 'PRICE_LIST',
    COUPON = 'COUPON',
    CAMPAIGN = 'CAMPAIGN',
    PRICE = 'PRICE',
    LOYALTY_LEVEL = 'LOYALTY_LEVEL',
  }


}
