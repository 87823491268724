import { isNullish } from "../../utils/Functions";

interface Node<T extends Node<T>> {
  readonly id: number;
  readonly children?: Array<T>;
}

export class TreeUtils {
  static findNode = <T extends Node<T>>(tree: T, nodeId: number): undefined | T => {
    if (tree.id === nodeId) {
      return tree;
    }
    if (!isNullish(tree.children)) {
      for (const node of tree.children) {
        const result = this.findNode(node, nodeId);
        if (result !== undefined) return result;
      }
    }
    return undefined;
  };

  static findChildren = <T extends Node<T>>(tree: T): Array<number> => {
    if (!isNullish(tree.children)) {
      return tree.children.flatMap((node) => {
        return [
          node.id,
          ...this.findChildren(node),
        ];
      });
    }
    return [];
  };

  static findParent = <T extends Node<T>>(tree: T, nodeId: number): T | undefined => {
    if (!isNullish(tree.children)) {
      for (let i = 0, len = tree.children.length; i < len; i++) {
        const node = tree.children[i];
        if (node.id === nodeId) {
          return tree;
        }
        if (i === len - 1) {
          return this.findParent(node, nodeId);
        }
      }
    }
    return undefined;
  };
}