import React, { createContext, useContext, useEffect, useState } from "react";
import { AuthActions, AuthUserModel } from "../../../../domain/features/auth/actions/AuthActions";
import { UserModel } from "../../../../domain/features/users/models/UserModel";
import { isNullish, throwNoop } from "../../../../utils/Functions";
import { OrganizationModel } from "../../../../domain/features/organizations/models/OrganizationModel";

/**
 * A minimal API for the App components to interact with authentication
 */
export interface AuthState {
  /**
   * Indicate when the state is invalid, wait for it to be cleaned
   */
  readonly isDirty: boolean;
  /**
   * Whether the app is currently authenticated
   */
  readonly isAuthenticated: boolean;
  /**
   * indicates whether a signIn or signOut operation is in progress
   */
  readonly isLoading: boolean;
  /**
   * Current user
   */
  readonly user: undefined | UserModel;
  /**
   * Current user's organization
   */
  readonly organization: undefined | OrganizationModel;
  /**
   * Has a value only when {@link AuthState.signIn} fails
   */
  readonly error: undefined | unknown;
  /**
   * Action to end the current authentication session
   */
  readonly signOut: VoidFunction;
  /**
   * Action to trigger an authentication session
   * @param username
   * @param password
   */
  readonly signIn: (username: string, password: string) => void;
}

/**
 * Hook to be used inside App components in order to
 * - check authentication state
 * - retrieve current user and organization (when authenticated)
 * - sign in
 * - sign out
 *
 * @example
 * ```typescript
 * const authState = useAuth();
 *
 * if(!authState.authenticated) {
 *   return <p onClick={authState.signIn}>{unAuthenticatedMessage}</p>;
 * }
 *
 * return <p onClick={authState.signOut}>{authenticatedMessage}</p>;
 * ```
 */
export function useAuth(): AuthState {
  return useContext(authContext);
}

export interface ProvideAuthProps {
  authActions: AuthActions;
}

/**
 * Provider component, should wrap any component that needs to re-render when authentication state changes
 * @param authActions
 * @param children
 *
 * @example
 * ```jsx
 * <ProvideAuth>
 *     Any child that should re-render based on authentication... usually the routing
 * </ProvideAuth>
 * ```
 */
export const ProvideAuth: React.FC<ProvideAuthProps> = ({ authActions, children }) => {
  const auth = useProvideAuth(authActions);

  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

// ============================================================
// INTERNAL API
// ============================================================

const authContext = createContext<AuthState>({} as AuthState);

const useProvideAuth = (actions: AuthActions): AuthState => {

  // keep a useState to re-render our children
  const [ isDirty, setIsDirty ] = useState<boolean>(true);
  const [ isLoading, setIsLoading ] = useState<boolean>(true);
  const [ userData, setUserData ] = useState<undefined | AuthUserModel>(undefined);
  const [ error, setError ] = useState<undefined | unknown>(undefined);

  /**
   * Try to signIn, if so, your user data will be loaded otherwise you will receive an error
   */
  const handleSetUser = async (userDataReader: () => Promise<undefined | AuthUserModel>): Promise<void> => {
    try {
      const userData = await userDataReader();

      setUserData(userData);
      setError(undefined);
      setIsLoading(false);
    } catch (error) {
      setError(error);
      setIsLoading(false);
    }
  };

  const attemptInitialSignIn = async () => {
    const shouldReadCurrentUser = isNullish(userData);
    if(shouldReadCurrentUser) {
      await handleSetUser(() => actions.readCurrentUser());
    }
    setIsDirty(false);
  };

  const signIn = (username: string, password: string) => {
    setError(undefined);
    void handleSetUser(() => actions.signIn(username, password));
  };

  const signOut = () => {
    setError(undefined);
    actions.signOut();
    setUserData(undefined);
  };

  // try to auto-login only one time
  useEffect(() => {
    void attemptInitialSignIn();
  }, []);

  /**
   * wait for user before proceeding to authenticated sections, since we need a {@link UserModel.organizationId}
   * to work with the apis
   */
  if (isDirty) {
    return {
      isDirty: true,
      isAuthenticated: false,
      isLoading,
      user: undefined,
      organization: undefined,
      error,
      signIn: throwNoop,
      signOut: throwNoop,
    };
  }
  if (isNullish(userData)) {
    return {
      isDirty: false,
      isAuthenticated: false,
      isLoading,
      user: undefined,
      organization: undefined,
      error,
      signIn,
      signOut: throwNoop,
    };
  } else {
    return {
      isDirty: false,
      isAuthenticated: true,
      isLoading,
      user: userData.user,
      organization: userData.organization,
      error: undefined,
      signIn: throwNoop,
      signOut,
    };
  }
};

