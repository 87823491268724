import { AddBusiness, Store } from "@mui/icons-material";
import { Box, Button, colors, Fade, useTheme } from "@mui/material";
import { useRef } from "react";
import { PointOfSaleModel } from "../../../../../../domain/features/organizations/models/PointOfSaleModel";
import {CheckBoxUtils} from "../../organizations-tree/CheckBoxTree"
import { OrganizationTreeModal } from "../../organizations-tree/OrganizationsTreeModal";

interface OrganizationButtonProps {
    onClick: () => void;
    handleClose: () => void;
    setSelectedIds?: React.Dispatch<React.SetStateAction<number[]>>;
    isOpen: boolean;
    selectedIds: number[];
    isEdit: boolean;
    pointsOfSale: PointOfSaleModel | undefined;
    disabled: boolean;
    treeExpanded?: boolean;
}
export const OrganizationButton = ({ 
  onClick, 
  isOpen, 
  selectedIds, 
  isEdit, 
  pointsOfSale, 
  handleClose, 
  disabled,
  setSelectedIds,
  treeExpanded,
}: OrganizationButtonProps) => {
  const ref = useRef<HTMLButtonElement>(null);
  const theme = useTheme();
  const allIds = CheckBoxUtils.allIds(pointsOfSale!);
  const borderColor = theme.palette.grey[400]; 
  const bgColor = theme.palette.grey[50];
  const style: React.CSSProperties = { 
    position: "absolute", 
    background: bgColor, 
    top: -10, 
    bottom: -10, 
    left: 0,
    width: "100%",
    zIndex: 1301,
    borderRight: `1px solid ${borderColor}`,
    borderTop: `1px solid ${borderColor}`,
    borderLeft: `1px solid ${bgColor}`,
    borderBottom: `1px solid ${borderColor}`,
    borderBottomRightRadius: `${theme.shape.borderRadius}px`,
    borderTopRightRadius: `${theme.shape.borderRadius}px`,
    borderRadius: "none",
    boxShadow: "0px 0px 4px rgba(0,0,0,0.1)",
  };
  const patchStyle: React.CSSProperties = {
    position: style.position, 
    background: style.background,
    top: allIds?.length <= 1 ? -1 : 0,
    bottom: allIds?.length <= 1 ? -1 : 0,
    left: "-15%",
    width: "20%",
    borderTop: allIds?.length <= 1 ? style.borderTop : undefined,
    borderBottom: allIds?.length <= 1 ? style.borderBottom : undefined,
    zIndex: (style.zIndex as number)! + 1,
  };
  const Icon = isEdit ? AddBusiness : Store;
  const color = isEdit ? "primary" : "action";
  return <>
    <Button ref={ref} onClick={onClick} disableRipple>
      <Fade in={isOpen}>
        <Box sx={style} display="flex" alignItems="center" justifyContent="center">
          <Box sx={patchStyle} />
          <Icon color={color} />
        </Box>
      </Fade>
      <Icon color={color} />
    </Button>
    <OrganizationTreeModal
      pointsOfSale={pointsOfSale}
      isModalOpen={isOpen}
      handleClose={handleClose}
      disabled={disabled}
      anchorEl={ref.current!}
      selectedIds={selectedIds}
      setSelectedIds={setSelectedIds}
      isEdit={isEdit}
      treeExpanded={treeExpanded}
    />
  </>;
};