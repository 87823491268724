/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ConditionResponse } from './ConditionResponse';

export type RuleResponse = {
  id: number;
  ruleType: RuleResponse.ruleType;
  value: number;
  repeatable: boolean;
  conditions: Array<ConditionResponse>;
};

export namespace RuleResponse {

  export enum ruleType {
    APPLICABLE = 'APPLICABLE',
    MIN_QUANTITY = 'MIN_QUANTITY',
    MIN_AMOUNT = 'MIN_AMOUNT',
    PRICE_LIST = 'PRICE_LIST',
  }


}
