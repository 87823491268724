import { isAppError } from "./AppError";

export abstract class Failure {
  abstract get message(): string;

  static from(error: unknown): undefined | Failure {
    return isAppError(error) ? error.failure : undefined;
  }

  static or<T>(error: T): Failure | T {
    return isAppError(error) ? error.failure : error;
  }
}

export class InsufficientAuthorityFailure extends Failure {
  get message(): string {
    return "The user does not have the authority to perform this operation";
  }
}