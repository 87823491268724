import { isDevMode } from "../../../utils/PlatformEnv";
import { noop } from "../../../utils/Functions";

class AssertError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "AssertError";
  }
}

export const assert = isDevMode ? (isCorrect: boolean, message: string) => {
  if (!isCorrect) throw new AssertError(message);
} : noop;