import { Alert, Box, Button, Container, Tab, Typography } from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { SyntheticEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { isNullish } from "../../../../utils/Functions";
import { AppUtils } from "../../../shared/AppUtils";
import {
  organizationActions,
  promoActions,
  promoPeriodActions,
} from "../../../shared/constants/Actions";
import { useAuth } from "../../../shared/hooks/authentication/useAuth";
import { WrappedLoader } from "../../../shared/wrapped-loader/WrappedLoader";
import { ActivePeriodsTable } from "./active-periods-table/ActivePeriodsTable";
import { InactivePeriodsTable } from "./inactive-periods-table/InactivePeriodsTable";
import { useToggler } from "../../../shared/hooks/useToggler";
import { CreatePromoPeriod } from "./create-promo-period/CreatePromoPeriod";
import { PromoPeriodModel } from "../../../../domain/features/promotions/models/PromoPeriodModel";
import { StyledContent, StyledHeading } from "../../../shared/heading/Heading.style";
import { Pages } from "../../../shared/pages";
import { groupPromotionsById } from "../utils";
import { theme } from "../../../../muiTheme";

type PromoPeriodTypes = "active" | "inactive";

export const PeriodsPage = () => {
  const { t } = useTranslation("promoPeriods");
  const { t: headingT } = useTranslation("pages");

  const { isOpen, close, open } = useToggler();
  const [ tabValue, setTabValue ] = useState<PromoPeriodTypes>("active");

  const { organization } = useAuth();

  const {
    isLoading: isLoadingPeriods,
    data: periods = { actives: [], inactive: [] },
    error: periodsError,
    refetch: refetchPeriods,
  } = useQuery(
    "fetchPeriods",
    () => {
      return promoPeriodActions.readAllForCalendar(organization!);
    },
    { enabled: !isNullish(organization?.id) }
  );

  const organizationId = organization?.id;

  // fetch active promotions
  const {
    isLoading: isLoadingPromotions,
    data: promotions,
    error: promotionsError,
  } = useQuery(
    "fetchPromotions",
    () => {
      return promoActions.readAll(organizationId!, { status: true });
    },
    { enabled: !isNullish(organizationId) }
  );

  // fetch organization selling points
  const {
    isLoading: isLoadingPointsOfSale,
    data: pointsOfSale,
    error: pointsOfSaleError,
  } = useQuery(
    "fetchPointsOfSale",
    () => {
      return organizationActions.readPointsOfSale(organizationId!);
    },
    { enabled: !isNullish(organizationId) }
  );

  const groupedPromotions = groupPromotionsById(promotions ?? []);
  if (isLoadingPeriods || isLoadingPromotions || isLoadingPointsOfSale) {
    return <WrappedLoader />;
  }

  const onClose = (period?: PromoPeriodModel) => {
    close();
    if (period) {
      void refetchPeriods();
    }
  };

  const handleTabChange = (
    event: SyntheticEvent,
    newValue: PromoPeriodTypes
  ) => {
    setTabValue(newValue);
  };

  return (
    <Container disableGutters style={{ marginTop: 0 }} maxWidth={false}>
      <TabContext value={tabValue}>
        <StyledHeading sx={{ paddingBottom: 0, marginBottom: 0 }}>
          <StyledContent sx={{ borderBottom: `1px solid ${theme.palette.divider}` }}>
            <CalendarMonthIcon fontSize="large" />
            <Typography
              className="heading"
              mb={0}
              variant="h4"
              gutterBottom
              component="div"
            >
              {headingT(Pages.routeCalendar)}
            </Typography>
            <Button onClick={open} variant="contained">
              {t("add_period")}
            </Button>
          </StyledContent>
          <Box sx={{ borderBottom: 1, borderColor: "white" }}>
            <TabList onChange={handleTabChange}>
              <Tab
                label={`${t("active_periods")} (${
                  periods?.actives?.length ?? 0
                })`}
                value="active"
              />
              <Tab
                label={`${t("inactive_periods")} (${
                  periods?.inactive?.length ?? 0
                })`}
                value="inactive"
              />
            </TabList>
          </Box>
        </StyledHeading>

        {[ periodsError, promotionsError, pointsOfSaleError ].map((error) => {
          if (isNullish(error)) return null;

          const message = AppUtils.getErrorMessage(error);

          return <Alert key={message} severity="error">
            {message}
          </Alert>;
        })}

        <CreatePromoPeriod
          isModalOpen={isOpen}
          onClose={onClose}
          promotions={promotions ?? []}
        />
        <TabPanel value="active" sx={{ padding: 0 }}>
          <ActivePeriodsTable
            refetchPeriods={refetchPeriods}
            periods={periods?.actives ?? []}
            promotions={groupedPromotions}
            pointsOfSale={pointsOfSale}
          />
        </TabPanel>
        <TabPanel value="inactive" sx={{ padding: 0 }}>
          <InactivePeriodsTable
            refetchPeriods={refetchPeriods}
            periods={periods?.inactive ?? []}
            promotions={groupedPromotions}
            pointsOfSale={pointsOfSale}
          />
        </TabPanel>
      </TabContext>
    </Container>
  );
};
