import { Modal } from "@mui/material";
import { styled } from "@mui/material/styles";
import { IStyledTheme } from "../../../shared/interfaces";

export const StyledForm = styled("form")(() => ({
  padding: "2rem 3rem",

  "& .MuiTextField-root": {
    m: 1,
    marginRight: "1rem",
  },  
}));

export const StyledRow = styled("div")(() => ({
  marginBottom: "1rem",
  display: "flex",
  alignItems: "center",
}));

export const StyledModal = styled(Modal, ({ theme }: IStyledTheme) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  backgroundColor: theme.palette.secondary.light,
  border: `2px solid ${theme.palette.primary.main}`,
  boxShadow: 24,
  paddingTop: 2,
}));

export const StyledButtonsContainer= styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  margin: "2rem 0",
}));

