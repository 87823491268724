import { Add } from "@mui/icons-material";
import { Button } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

interface AddButtonProps {
  disabled?: boolean;
  onClick?: VoidFunction;
}

export const AddButton: React.FC<AddButtonProps> = ({ disabled, onClick }) => {
  const { t } = useTranslation("components");
  return (
    <Button disabled={disabled} sx={{ margin: "1rem 0" }} variant="outlined" onClick={onClick}>
      {t("add_btn")}
      <Add />
    </Button>
  );
};

