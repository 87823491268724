import { styled } from "@mui/material/styles";

export const StyledContent = styled("div")(() => (
  {
    display: "flex",
    gap: "1rem",
    alignItems: "center",
    ".heading" : { flex: 1 },
    paddingBottom: "1rem",

    "& .header-content": { marginLeft: "auto" },
  }
));
export const StyledHeading = styled("div")(() => (
  {
    marginBottom: "2rem",
    padding: "1rem 1rem 0",
    position: "sticky",
    top: 0,
    background: "rgba(255,255,255,0.8)",
    backdropFilter: "blur(4px)",
    zIndex: 10,
  }
));

export const StyledTypography = styled("div")(() => (
  { overflow: "hidden" }
 
));
