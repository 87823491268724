import { Slide, Snackbar } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { Duration } from "luxon";
import * as React from "react";
import { createContext, ReactNode, useContext, useState } from "react";
import { K } from "../../constants/K";

export interface SnackBarData {
  /**
   * If not defined it will be visible for {@see K.snackBarVisibilityTime}
   */
  duration?: Duration;
  /**
   * The contents of the snackbar
   */
  content: React.ReactElement;
}

interface SnackBarMessenger {
  /**
   * Identify if the snackbar is visible
   */
  readonly isOpen: boolean;
  /**
   * Show the snackbar, {@see SnackBarData}
   * Only one snackbar may be displayed at a time.
   */
  readonly show: (data: SnackBarData) => void;
  /**
   * Hide the current visible snackbar
   */
  readonly close: () => void;
}

/**
 * Use it to make the SnackBar appear wherever you want without having to declare it every time!
 * {@see SnackBarMessenger} and {@see SnackBarMessengerProvider}
 * ```typescript
 *   // Example: Show SnackBar
 *   const messenger = useSnackBarMessenger();
 *   const state = useQuery(() => {
 *     return // Call api
 *   }, {onSuccess: () => messenger.show({content: <>Promo is saved!</>})})
 *   // Example: Define SnackBar
 *   const AlertSnackBar = ({content}) => {
 *     const messenger = useSnackBarMessenger();
 *     return <Alert onClose={messenger.close}>{content}</Alert>
 *   }
 * ```
 */
export const useSnackBarMessenger = (): SnackBarMessenger => {
  return useContext(context);
};

interface SnackBarMessengerProviderProps {
  children: ReactNode;
}

/**
 * Allows to use {@see useSnackBarMessenger}
 * @param children
 * @constructor
 */
export const SnackBarMessengerProvider = ({ children }: SnackBarMessengerProviderProps) => {
  const [ data, setData ] = useState<SnackBarData>(emptySnackBar);
  // Whenever the snackbar is closed, the key must be changed in order to open the next one
  const [ index, setIndex ] = useState(0);
  const [ isOpen, setIsOpen ] = useState<boolean>(false);

  const show = (data: SnackBarData) => {
    if (isOpen) return;

    setData(data);
    setIndex(index + 1);
    setIsOpen(true);
  };

  const close = () => {
    setIsOpen(false);
  };

  const remove = () => {
    setData(emptySnackBar);
  };

  const buildSnackBarContent = () => {
    return data.content;
  };

  return <>
    <context.Provider value={{
      isOpen,
      show,
      close,
    }}>
      {children}
      <Snackbar
        key={index}
        open={isOpen}
        autoHideDuration={(data.duration ?? K.snackBarVisibilityTime).toMillis()}
        onClose={close}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        sx={{ minWidth: 600 }}
        TransitionProps={{ onExited: remove }}
        TransitionComponent={buildTransition}
      >
        <div style={{ width: "100%" }}>
          {buildSnackBarContent()}
        </div>
      </Snackbar>
    </context.Provider>
  </>;
};

// ============================================================
// INTERNAL CLASS/METHODS
// ============================================================

// The method of constructing the transaction must be static
const buildTransition = (props: TransitionProps & { children: React.ReactElement }) => {
  return <Slide
    {...props}
    direction="up"
    children={props.children}
  />;
};

const emptySnackBar: SnackBarData = {
  duration: undefined,
  content: <></>,
};

const context = createContext({} as SnackBarMessenger);