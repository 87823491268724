import { isError } from "../../../utils/Functions";

/**
 * Identifies a mapping not supported by the Mapping class
 *
 * You shouldn't catch this error, but prevent it from happening
 */
export class UnsupportedMappingError extends Error {
  constructor(inputName: string, inputValue: unknown, outputName: string) {
    super(`Not supported "${outputName}" with '${JSON.stringify(inputValue)}' in "${outputName}" field`);
    this.name = "InvalidFieldMappingError";
  }

  static isMine = (error: unknown): error is UnsupportedMappingError => {
    return isError(error) ? error.name === "InvalidFieldMappingError" : false;
  };
}