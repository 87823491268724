import { Card, CardContent, CardHeader, Divider, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { MinimumTotalReceipt } from "../minimum-total-receipt/MinimumTotalReceipt";
import { StyledWrapper } from "./ConditionsForm.styles";
import { MinimumQuantityItems } from "../minimum-quantity-items/MinimumQuantityItems";
import { LoyaltyPartnerField } from "../loyalty-partner/LoyaltyPartnerField";
import { ProposedField } from "../proposed-field/ProposedField";
import { RepeatArticlesFields } from "../repeat-articles-fields/RepeatArticlesFields";
export const ConditionsForm = ({ isGifts, isPoints, isTotal }: { isGifts: boolean; isPoints: boolean; isTotal: boolean }) => {
  const { t } = useTranslation("createPromo");
  
  return (
    <StyledWrapper>
      <Stack spacing={2}>
        {!isTotal && !isGifts && <Card variant="outlined">
          <CardHeader title={<Typography fontWeight="bold">{t("repeat_articles_label_pre").toUpperCase()}</Typography>} disableTypography />
          <CardContent sx={{ px: 4 }}>
            <Stack spacing={2}>
              <RepeatArticlesFields />
              <RepeatArticlesFields isAmount={true} />
            </Stack>
          </CardContent>
        </Card>}
        {!isGifts && 
          <Card variant="outlined">
            <CardHeader 
              title={<Typography fontWeight="bold">{String(t("minimum")).toUpperCase()}</Typography>} 
              disableTypography 
            />
            <CardContent sx={{ px: 4 }}>
              <Stack spacing={2}>
                <MinimumQuantityItems />
                <Divider />
                <MinimumTotalReceipt />
              </Stack>
            </CardContent>
          </Card>
        }

        <Card variant="outlined">
          <CardHeader />
          <CardContent sx={{ px: 4 }}>
            <LoyaltyPartnerField />
          </CardContent>
        </Card>

        {!isPoints && <Card variant="outlined">
          <CardHeader />
          <CardContent sx={{ px: 4 }}>
            <ProposedField />
          </CardContent>
        </Card>}
      </Stack>
    </StyledWrapper>
  );
};

