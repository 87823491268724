import { Box, Button, Container, Tab, Tabs, Typography } from "@mui/material";
import { PromoRuleModelType, promoRuleModelTypes } from "../../../domain/features/promotions/models/PromoTypes";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { StyledContent, StyledHeading } from "../../shared/heading/Heading.style";
import { GroupTable } from "./group-table/GroupTable";
import { TabContext, TabPanel } from "@mui/lab";
import { useAuth } from "../../shared/hooks/authentication/useAuth";
import { useToggler } from "../../shared/hooks/useToggler";
import { CreateGroupItem } from "./create-group-item/CreateGroupItem";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import { theme } from "../../../muiTheme";
export const GroupsPage = () => {
  const { user } = useAuth();
  const { t } = useTranslation([ "createPromo", "groupsTable" ]);
  const [ tab, setTab ] = useState<PromoRuleModelType>("ITEM_GROUP");
  const { isOpen, close, open } = useToggler();
  const types = promoRuleModelTypes.filter((type) => {
    return type !== "BUSINESS_PARTNER" && type !== "ITEM" && type !== "PRICE";
  });
  return <Container disableGutters maxWidth={false}>
    <CreateGroupItem isOpen={isOpen} close={close} types={types} user={user} />
    <TabContext value={tab}>
      <StyledHeading sx={{ paddingBottom: 0 }} style={{ marginBottom: 0 }}>
        <StyledContent sx={{ borderBottom: `1px solid ${theme.palette.divider}` }}>
          <LibraryBooksIcon fontSize="large" />
          <Typography
            className="heading"
            mb={0}
            variant="h4"
            gutterBottom
            component="div"
          >
            {t("createPromo:groups")}
          </Typography>
          <Button variant="contained" onClick={open}>
            {t("groupsTable:add_element")}
          </Button>
        </StyledContent>
        <Box sx={{ borderBottom: 1, borderColor: "white" }}>
          <Tabs 
            value={tab} 
            onChange={(e, i: PromoRuleModelType) => setTab(i)}
            variant="scrollable"
            scrollButtons="auto"
          >
            {types.map((type, i) => {
              const label = t(`createPromo:rule_types.${type}`);
              return <Tab
                key={type}
                id={`group-tab.${i}`} 
                label={label} 
                value={type}
                tabIndex={i}
              />;
            })}
          </Tabs>
        </Box>
      </StyledHeading>
      {types.map((type) => {
        return <TabPanel value={type} key={type} sx={{ padding: 0 }}>
          <GroupTable type={type} user={user} isOpen={isOpen} />
        </TabPanel>;
      })}
    </TabContext>
  </Container>;
};