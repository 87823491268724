/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type DataLoaderConfigurationPost = {
  reference: string;
  properties?: Array<string>;
  target: DataLoaderConfigurationPost.target;
  elements?: Array<DataLoaderConfigurationPost>;
  options?: Record<string, any>;
};

export namespace DataLoaderConfigurationPost {

  export enum target {
    QUERY = 'QUERY',
    ITEM = 'ITEM',
    DOCUMENT = 'DOCUMENT',
    ORDER = 'ORDER',
    INVOICE = 'INVOICE',
    UDO = 'UDO',
    BUSINESS_PARTNER = 'BUSINESS_PARTNER',
    WAREHOUSE = 'WAREHOUSE',
    BUSINESS_PARTNER_GROUP = 'BUSINESS_PARTNER_GROUP',
    ITEM_GROUP = 'ITEM_GROUP',
    PRICE_LIST = 'PRICE_LIST',
    INVENTORY_REPORT = 'INVENTORY_REPORT',
    WAREHOUSE_LOCATION = 'WAREHOUSE_LOCATION',
    LANGUAGE = 'LANGUAGE',
    GENERIC = 'GENERIC',
  }


}
