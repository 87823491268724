import { Controller, useWatch } from "react-hook-form";
import { Box, FormControl, InputLabel, MenuItem, Select, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";

import { useValueChanges } from "../../../../../shared/hooks/basic/UseValueChanges";
import { useMachineStateSelector } from "../../../../../shared/hooks/useMachineStateSelector";
import { usePromoStore } from "../../stores/PromoStore";
import {
  PromoAmountModelType,
  promoAmountModelTypes,
} from "../../../../../../domain/features/promotions/models/PromoTypes";
import { IFormValues } from "../../types";
import { NumericInput } from "../../../../../shared/numeric-input/NumericInput";

export const PromoAmountFields: React.FC = () => {
  const { t } = useTranslation("createPromo");

  const { machine } = usePromoStore();
  const [
    promoAmountModelTypesOptions,
    setPromoAmountModelTypesOptions,
  ] = useState<readonly PromoAmountModelType[]>(promoAmountModelTypes);

  const state = useMachineStateSelector(machine.select((state) => {
    return {
      isReadonly: state.amount.isReadOnly,
      type: state.type.value,
    };
  }));

  const promoAmount = useWatch<IFormValues, "promoAmount">({ name: "promoAmount" });

  const promoAmountType = useWatch<IFormValues, "promoAmountType">({ name: "promoAmountType" });

  useValueChanges(promoAmount, (value) => {
    machine.changeAmountValue(value);
  }, [ machine ]);

  useValueChanges(promoAmountType, (value) => {
    machine.changeAmountType(value);
  }, [ machine ]);

  useEffect(() => {
    if(state.type === "TOTAL") {
      setPromoAmountModelTypesOptions(promoAmountModelTypes.filter((it) => it !== "FORCED_PRICE"));
    } else {
      setPromoAmountModelTypesOptions(promoAmountModelTypes);
    }
  }, [ state.type ]);

  return (
    <Stack direction="row" alignItems="center" sx={{ width: "100%" }}>
      <Controller<IFormValues, "promoAmount">
        name="promoAmount"
        render={({ field }) =>
          <NumericInput
            sx={{ width: "70%" }}
            size="small"
            precision={2}
            decimalChar=","
            thousandChar="."
            label={t("promo_amount_label")}
            variant="outlined"
            InputProps={{ readOnly: state.isReadonly }}
            {...field}
          />}
      />
      <Controller<IFormValues, "promoAmountType">
        name="promoAmountType"
        render={({ field }) =>
          <FormControl sx={{ m: 1, width: "30%" }}>
            <InputLabel>{t("discount_type_label")}</InputLabel>
            <Select
              size="small"
              label={t("discount_type_label")}
              readOnly={state.isReadonly}
              {...field}
            >
              {promoAmountModelTypesOptions.map((item, i) =>
                <MenuItem key={i} value={item}>
                  {t(`amount_types.${item}`)}
                </MenuItem>
              )}
            </Select>
          </FormControl>}
      />
    </Stack>
  );
};

