import { BusinessPartnersRepository } from "../../../domain/features/business-partners/repositories/BusinessPartnersRepository";
import { ItemsRepository } from "../../../domain/features/items/repositories/ItemsRepository";
import { OrganizationsRepository } from "../../../domain/features/organizations/repositories/OrganizationsRepository";
import { PromoPeriodRepository } from "../../../domain/features/promotions/repositories/PromoPeriodRepository";
import { PromoRepository } from "../../../domain/features/promotions/repositories/PromoRepository";
import { UsersRepository } from "../../../domain/features/users/repositories/UsersRepository";
import { GroupRepository } from "../../../domain/features/groups/repositories/GroupRepository";
import { appClient } from "./clients/AppClient";

export const usersRepository = new UsersRepository(appClient.commonApiToManageUsers);

export const promoRepository = new PromoRepository(appClient.managerApiToManagePromotions);

export const promoPeriodRepository = new PromoPeriodRepository(appClient.managerApiToManagePromotions);

export const itemsRepository = new ItemsRepository(appClient.managerApiToManageItems);

export const groupRepository = new GroupRepository(appClient.managerApiToManageGroup);

export const organizationsRepository = new OrganizationsRepository(
  appClient.userApiToManageOrganizations,
  appClient.managerApiToManageOrganizationTree
);

export const businessPartnersRepository = new BusinessPartnersRepository(appClient.managerApiToManageBusinessPartner);
