import { StateMachineBase } from "../../../domain/shared/state-machine/StateMachine";
import { useLayoutEffect, useState } from "react";
import { isEqual } from "../../../utils/Functions";

/**
 * It will render the component whenever the state of the StateMachine changes and return it
 *
 * If you need to listen to a part of the state you can use {@see Machine.select}
 * If you don't need to render the component when the state changes {@see useMachineStateListener}
 *
 * Example: Rebuild a component when state changed
 * const MyComponent = ({machine}: {machine: StateMachine}) => {
 *   const state = useMachineStateSelector(machine)
 *   return <>`${state.value}`</>
 * }
 * @param machine The machine state that will be listened to
 */
export function useMachineStateSelector<TState>(
  machine: StateMachineBase<TState>,
): TState {
  const [ state, setState ] = useState<TState>(machine.state);

  useLayoutEffect(() => {
    let prev = machine.state;

    if (!isEqual(state, prev)) {
      setState(prev);
    }

    return machine.addListener((next) => {
      const curr = prev;
      prev = next;
      if (isEqual(curr, next)) return;
      setState(next);
    });
  }, [ machine, setState ]);

  return state;
}
