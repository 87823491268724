import { styled } from "@mui/material/styles";

export const StyledMinAmountWrapper = styled("div")(() => ({ "& .MuiFormControl-root": { } }));

export const StyledRow = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
}));

export const StyledGrid = styled("div")(() => ({
  display: "grid",
  gridTemplateColumns: "1fr 1fr 1fr",
  alignItems: "center",
}));