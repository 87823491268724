/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AllowedPromotionPost = {
  allowedPromotion: AllowedPromotionPost.allowedPromotion;
  requiredConditions?: Array<'BUSINESS_PARTNER_GROUP' | 'BUSINESS_PARTNER' | 'ITEM_GROUP' | 'ITEM' | 'PRICE_LIST' | 'COUPON' | 'CAMPAIGN' | 'PRICE' | 'LOYALTY_LEVEL'>;
};

export namespace AllowedPromotionPost {

  export enum allowedPromotion {
    TOTAL = 'TOTAL',
    BRACKETS = 'BRACKETS',
    CASHBACK = 'CASHBACK',
    PRICE_LIST = 'PRICE_LIST',
    OPERATOR_CHOICE = 'OPERATOR_CHOICE',
    LESS_PRICE = 'LESS_PRICE',
    HIGH_PRICE = 'HIGH_PRICE',
    ITEMS = 'ITEMS',
    POINTS = 'POINTS',
    GIFTS = 'GIFTS',
  }


}
