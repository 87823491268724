import { FormControlLabel } from "@mui/material";
import { Controller } from "react-hook-form";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CheckboxField } from "../../../../../shared/form/fields/basic/CheckboxField";
import { useValueChanges } from "../../../../../shared/hooks/basic/UseValueChanges";
import { useMachineStateSelector } from "../../../../../shared/hooks/useMachineStateSelector";
import { StyledRow } from "../../PromotionPage.styles";
import { usePromoStore } from "../../stores/PromoStore";
import { IFormValues } from "../../types";

export const ProposedField = () => {
  const { t } = useTranslation("createPromo");
  const { machine } = usePromoStore();

  const state = useMachineStateSelector(machine.select((state) => {
    return { 
      isReadonly: state.proposed.isReadOnly, 
      isPoints: state.type.value === "POINTS",
      isGifts: state.type.value === "GIFTS",
    };
  }));

  const proposed = useWatch<IFormValues, "proposed">({ name: "proposed" });

  useValueChanges(proposed, (value) => {
    machine.changeProposed(value);
  }, [ machine ]);

  return (
    <StyledRow>
      <Controller<IFormValues, "proposed">
        name="proposed"
        render={({ field }) => {
          return <FormControlLabel
            control={<CheckboxField readOnly={state.isReadonly} {...field} />}
            disabled={state.isReadonly || state.isPoints}
            label={t("propose")}
          />;
        }}
      />
    </StyledRow>
  );
};