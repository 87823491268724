import { ExpandLess, ExpandMore, Language, Settings } from "@mui/icons-material";
import { Collapse, List, ListItemButton, ListItemIcon, ListItemText, Radio, Stack } from "@mui/material";
import { useMemo } from "react";
import { useToggler } from "../../shared/hooks/useToggler";
import i18next from "i18next";
import { theme } from "../../../muiTheme";
import { useTranslation } from "react-i18next";

const LanguageItem = ({ lang }: {lang: string}) => {
  let src;
  let descr;
  switch (lang) {
  case "it": 
    src="/italy.png";
    descr="Italiano"; 
    break;
  case "en": 
    src="/uk.png";
    descr="English"; 
    break;
  case "de": 
    src="/germany.png";
    descr="Deutsche"; 
    break;
  }
  return (
    <Stack 
      direction="row" 
      alignItems="center" 
      spacing={1}>
      <img src={src} width={14} height={14} />
      <span>{descr}</span>
    </Stack>
  );
};

const LanguageMenu = () => {
  const { t } = useTranslation("pages");
  const { isOpen, toggle } = useToggler(false);
  const languages = useMemo(() => i18next.languages, []);
  return <List dense sx={{ background: theme.palette.grey[200] }}>
    <ListItemButton onClick={toggle} sx={{ marginLeft: 1 }}>
      <ListItemIcon>
        <Language fontSize="small" />
      </ListItemIcon>
      <ListItemText>{t("settings.language")}</ListItemText>
      {isOpen ? <ExpandLess fontSize="small" /> : <ExpandMore fontSize="small" />}
    </ListItemButton>
    <Collapse in={isOpen} unmountOnExit>
      <List dense>
        {languages.map((lang) => 
          <ListItemButton sx={{ marginLeft: 1 }} onClick={() => i18next.changeLanguage(lang)}>
            <ListItemIcon>
              <Radio size="small" checked={lang === i18next.language} />
            </ListItemIcon>
            <ListItemText>
              <LanguageItem lang={lang} />
            </ListItemText>
          </ListItemButton>
        )}
      </List>
    </Collapse>
  </List>;
};

export const SettingsMenu = () => {
  const { t } = useTranslation("pages");
  const { isOpen, toggle } = useToggler(false);
  return <List>
    <ListItemButton onClick={toggle} selected={isOpen}>
      <ListItemIcon>
        <Settings />
      </ListItemIcon>
      <ListItemText>{t("settings.title")}</ListItemText>
      {isOpen ? <ExpandLess /> : <ExpandMore />}
    </ListItemButton>
    <Collapse in={isOpen} unmountOnExit>
      <LanguageMenu />
    </Collapse>
  </List>;
};