import { OrganizationModel } from "../models/OrganizationModel";
import { OrganizationsRepository } from "../repositories/OrganizationsRepository";
import { PointOfSaleModel } from "../models/PointOfSaleModel";
import { PointOfSaleMappings } from "../mappings/PointOfSaleMappings";

export class OrganizationActions {
  constructor(private readonly organizationsRepository: OrganizationsRepository) {}

  async read(organizationId: number): Promise<OrganizationModel> {
    return this.organizationsRepository.read(organizationId);
  }
  async readPointsOfSale(organizationId: number): Promise<PointOfSaleModel> {
    const response = await this.organizationsRepository.readPointsOfSale(organizationId);

    return PointOfSaleMappings.responseToModel(response);
  }
}
