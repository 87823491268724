import { StateMachineBase, StateMachineListener } from "../../../domain/shared/state-machine/StateMachine";
import { useLayoutEffect, useRef } from "react";
import { isEqual } from "../../../utils/Functions";

/**
 * Listen to the StateMachine state changes
 *
 * If you need to listen to a part of the state you can use {@see Machine.select}
 * Does not render the component when state changes, if you need this {@see useMachineStateSelector}
 *
 * Example: Show a SnackBar when value in state changes
 * const MyComponent = ({machine}: {machine: StateMachine}) => {
 *   useMachineStateListener(machine.select((state) => state.value), (state) {
 *     showSnackBar({text: `state.value`})
 *   })
 *   return <>...</>
 * }
 * @param machine The machine state that will be listened to
 * @param listener The function that will receive state updates
 */
export function useMachineStateListener<TState>(
  machine: StateMachineBase<TState>,
  listener: StateMachineListener<TState>,
): void {
  const currRef = useRef<TState>(machine.state);

  useLayoutEffect(() => {

    if (!isEqual(currRef.current, machine.state)) {
      currRef.current = machine.state;
      listener(currRef.current);
    }

    return machine.addListener((next) => {
      if (isEqual(currRef.current, next)) return;

      currRef.current = machine.state;
      listener(currRef.current);
    });
  }, [ machine, listener, currRef ]);
}
