/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CsvResponse } from '../models/CsvResponse';
import type { OrganizationTreePatch } from '../models/OrganizationTreePatch';
import type { OrganizationTreePost } from '../models/OrganizationTreePost';
import type { OrganizationTreeResponse } from '../models/OrganizationTreeResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ManagerApiToManageOrganizationTreeService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Activate point of sale
   * @returns void
   * @throws ApiError
   */
  public putBusinessPartner({
    organizationId,
    pointOfSaleCode,
  }: {
    organizationId: number,
    pointOfSaleCode: string,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/manager/organization/{organizationID}/tree/pointOfSale/{pointOfSaleCode}',
      path: {
        'organizationID': organizationId,
        'pointOfSaleCode': pointOfSaleCode,
      },
      errors: {
        400: `Bad Request`,
        401: `Failed to decode authentication token`,
        404: `Entity Not found`,
        405: `Method Not Allowed`,
        409: `Data integrity violation`,
        417: `Expectation Failed`,
        500: `General I/O error`,
      },
    });
  }

  /**
   * Deactivate point of sale
   * @returns void
   * @throws ApiError
   */
  public deleteBusinessPartner({
    organizationId,
    pointOfSaleCode,
  }: {
    organizationId: number,
    pointOfSaleCode: string,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/manager/organization/{organizationID}/tree/pointOfSale/{pointOfSaleCode}',
      path: {
        'organizationID': organizationId,
        'pointOfSaleCode': pointOfSaleCode,
      },
      errors: {
        400: `Bad Request`,
        401: `Failed to decode authentication token`,
        404: `Entity Not found`,
        405: `Method Not Allowed`,
        409: `Data integrity violation`,
        417: `Expectation Failed`,
        500: `General I/O error`,
      },
    });
  }

  /**
   * Get organization tree from top or from bottom
   * @returns OrganizationTreeResponse OK
   * @throws ApiError
   */
  public getTreeTopBottom({
    organizationId,
    pointOfSaleCode,
    bottomTop,
  }: {
    organizationId: number,
    pointOfSaleCode?: string,
    bottomTop?: boolean,
  }): CancelablePromise<OrganizationTreeResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/manager/organization/{organizationID}/tree',
      path: {
        'organizationID': organizationId,
      },
      query: {
        'pointOfSaleCode': pointOfSaleCode,
        'bottomTop': bottomTop,
      },
      errors: {
        400: `Bad Request`,
        401: `Failed to decode authentication token`,
        404: `Entity Not found`,
        405: `Method Not Allowed`,
        409: `Data integrity violation`,
        417: `Expectation Failed`,
        500: `General I/O error`,
      },
    });
  }

  /**
   * Create new point of sale
   * @returns OrganizationTreeResponse OK
   * @throws ApiError
   */
  public postNewLevelInOrganization({
    organizationId,
    requestBody,
  }: {
    organizationId: number,
    requestBody: OrganizationTreePost,
  }): CancelablePromise<OrganizationTreeResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/manager/organization/{organizationID}/tree',
      path: {
        'organizationID': organizationId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Failed to decode authentication token`,
        404: `Entity Not found`,
        405: `Method Not Allowed`,
        409: `Data integrity violation`,
        417: `Expectation Failed`,
        500: `General I/O error`,
      },
    });
  }

  /**
   * Import point of sales from csv file
   * @returns CsvResponse OK
   * @throws ApiError
   */
  public postBusinessPartnerCsv({
    organizationId,
    formData,
  }: {
    organizationId: number,
    formData?: {
      file: Blob;
    },
  }): CancelablePromise<CsvResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/manager/organization/{organizationID}/tree/pointOfSales/csv',
      path: {
        'organizationID': organizationId,
      },
      formData: formData,
      mediaType: 'multipart/form-data',
      errors: {
        400: `Bad Request`,
        401: `Failed to decode authentication token`,
        404: `Entity Not found`,
        405: `Method Not Allowed`,
        409: `Data integrity violation`,
        417: `Expectation Failed`,
        500: `General I/O error`,
      },
    });
  }

  /**
   * Modify description point of sale
   * @returns OrganizationTreeResponse OK
   * @throws ApiError
   */
  public patch1({
    organizationId,
    pointOfSaleCode,
    requestBody,
  }: {
    organizationId: number,
    pointOfSaleCode: string,
    requestBody: OrganizationTreePatch,
  }): CancelablePromise<OrganizationTreeResponse> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/manager/organization/{organizationID}/tree/{pointOfSaleCode}',
      path: {
        'organizationID': organizationId,
        'pointOfSaleCode': pointOfSaleCode,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Failed to decode authentication token`,
        404: `Entity Not found`,
        405: `Method Not Allowed`,
        409: `Data integrity violation`,
        417: `Expectation Failed`,
        500: `General I/O error`,
      },
    });
  }

}