import { GroupCollectionResponse, ManagerApiToManageGroupService } from "../../../generated/api";
import { GroupSearchQuery } from "../dto/GroupSearchQuery";

const MAX_RESULTS = 50;

export class GroupRepository {
  constructor(private readonly service: ManagerApiToManageGroupService) {}

  readAll(
    organizationId: number,
    type: "ITEM_GROUP" | "BUSINESS_PARTNER_GROUP" | "COUPON" | "CAMPAIGN" | "PRICE" | "LOYALTY_LEVEL",
    { ids } : {ids?: Array<number>} = {},
  ): Promise<GroupCollectionResponse> {
    return this.service.getGroups({
      organizationId,
      type: GroupRepository.#mapReadAllType(type),
      groupIDs: ids,
    });
  }

  readOne(
    organizationId: number,
    type: "ITEM_GROUP" | "BUSINESS_PARTNER_GROUP" | "COUPON" | "CAMPAIGN" | "PRICE" | "LOYALTY_LEVEL",
    { id }: { id: number }
  ) {
    return this.service.getItem({
      organizationId,
      type: GroupRepository.#mapReadAllType(type),
      typeId: id,
    });
  }

  search(organizationId: number, {
    type,
    maxResults = MAX_RESULTS,
    userInput,
  }:GroupSearchQuery): Promise<GroupCollectionResponse> {
    return this.service.getSearch({
      organizationId,
      registryType: type,
      numOfElement: maxResults,
      request: userInput,
    });
  }

  changeDescription(organizationId: number, {
    type,
    typeId,
    description,
  } : {
    type: "ITEM_GROUP" | "BUSINESS_PARTNER_GROUP" | "COUPON" | "CAMPAIGN" | "PRICE" | "LOYALTY_LEVEL"; 
    typeId: number; 
    description: string;
  }) {
    return this.service.patchGroup({
      organizationId,
      type: GroupRepository.#mapReadAllType(type),
      typeId,
      requestBody: { description },
    });
  }

  addOne(organizationId: number, {
    type,
    code,
    description,
  }: {
    type: "ITEM_GROUP" | "BUSINESS_PARTNER_GROUP" | "COUPON" | "CAMPAIGN" | "PRICE" | "LOYALTY_LEVEL";
    code: string;
    description: string;
  }) {
    return this.service.postGroup({
      organizationId,
      type: GroupRepository.#mapReadAllType(type),
      requestBody: {
        code,
        description,
      },
    });
  }

  static #mapReadAllType(type: "ITEM_GROUP" | "BUSINESS_PARTNER_GROUP" | "COUPON" | "CAMPAIGN" | "PRICE" | "LOYALTY_LEVEL") {
    switch (type) {
    case "ITEM_GROUP":
      return "ItemGroups";
    case "BUSINESS_PARTNER_GROUP":
      return "BusinessPartnerGroups";
    case "COUPON":
      return "Coupons";
    case "CAMPAIGN":
      return "Campaigns";
    case "PRICE":
      return "Prices";
    case "LOYALTY_LEVEL":
      return "LoyaltyLevels";
    }
  }
}
