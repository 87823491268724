import { Alert, Button, Container, Stack, Typography } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { PromoFormState } from "../../../../domain/features/promotions/state-machines/PromoFormState";
import { isEmpty } from "../../../../utils/Functions";
import { AppUtils } from "../../../shared/AppUtils";
import { useSnackBarMessenger } from "../../../shared/components/snack-bar/SnackBarMessenger";
import { WrappedLoader } from "../../../shared/wrapped-loader/WrappedLoader";
import { CommonForm } from "./components/common-form/CommonForm";
import { IFormValues } from "./types";
import { StyledButtonsContainer, StyledForm } from "./PromotionPage.styles";
import { ConditionsForm } from "./components/conditions-form/ConditionsForm";
import { ApplyPromotionFields } from "./components/apply-promotion-fields/ApplyPromotionFields";
import { useMachineStateListener } from "../../../shared/hooks/useMachineStateListener";
import { PromoStoreProvider, useCreatePromoStore, usePromoStore } from "./stores/PromoStore";
import { FilterItemsTable } from "./components/filter-items-table/FilterItemsTable";
import { useStateMachineCreator } from "../../../shared/hooks/useStateMachineCreator";
import { GiftRulesFormMachine, RulesFormMachine } from "../../../../domain/features/promotions/state-machines/RulesFormMachine";
import {
  promoRuleModelItemTypes,
  PromoRuleModelType,
  promoRuleModelTypes,
} from "../../../../domain/features/promotions/models/PromoTypes";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import StoreIcon from "@mui/icons-material/Store";
import { ResultAlert } from "../../../shared/components/snack-bar/ResultAlert";
import { theme } from "../../../../muiTheme";
import { useMachineStateSelector } from "../../../shared/hooks/useMachineStateSelector";
import { GiftsForm } from "./components/gifts-form/GiftsForm";
import { StyledContent, StyledHeading } from "../../../shared/heading/Heading.style";
import {useEffect} from "react"

export const PromotionPage = () => {
  const { t } = useTranslation("createPromo");
  const messenger = useSnackBarMessenger();

  const store = useCreatePromoStore({
    onSaveFailed: (error) => messenger.show({
      content: <ResultAlert
        title={t("snack_bars.save.failed_title")}
        error={error}
      />,
    }),
    onSaved: () => messenger.show({
      content: <ResultAlert
        title={t("snack_bars.save.success_title")}
      />,
    }),
    onActiveFailed: (error) => messenger.show({
      content: <ResultAlert
        title={t("snack_bars.active.failed_title")}
        error={error}
      />,
    }),
    onActivated: () => messenger.show({
      content: <ResultAlert
        title={t("snack_bars.active.success_title")}
      />,
    }),
  });

  if (store.isDirty) {
    return <WrappedLoader />;
  }

  return <PromoStoreProvider store={store}>
    <PromotionForm />
  </PromoStoreProvider>;
};

const PromotionForm = () => {
  const { t } = useTranslation("createPromo");

  const {
    machine,
    errorOrFailure,
    promo,
    savePromo,
    activatePromo,
    isLoading,
    canSave,
    canActivate,
    isEdit,
  } = usePromoStore();

  // mode: onChange is auto validating form
  const methods = useForm<IFormValues>({
    defaultValues: stateToFormValues(machine.state),
    mode: "onChange",
  });

  // update inputs values with values from state
  useMachineStateListener(machine, (state) => {
    const values: IFormValues = stateToFormValues(state);

    for (const [ key, value ] of Object.entries(values)) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      methods.setValue(key as keyof IFormValues, value);
    }
  });

  // rules machine for Table at the bottom of the form
  const rulesMachine = useStateMachineCreator(() => {
    return new RulesFormMachine<PromoRuleModelType>({
      isReadOnly: machine.state.rules.isReadOnly,
      initialType: promoRuleModelItemTypes[0],
      initialRules: machine.state.rules.value,
    });
  });

  const giftRulesMachine = useStateMachineCreator(() => {
    return new GiftRulesFormMachine({
      isReadonly: machine.state.giftsRules.isReadOnly,
      initialRules: machine.state.giftsRules.value,
    });
  });

  const { isPoints, isGifts, isTotal } = useMachineStateSelector(machine.select((state) => {
    return { 
      isPoints: state.type.value === "POINTS", 
      isGifts: state.type.value === "GIFTS",
      isTotal: state.type.value === "TOTAL",
    };
  }));

  useMachineStateListener(machine.select((state) => state.rules), (rules) => {
    rulesMachine.changeRules(rules.isReadOnly, rules.value);
  });

  useMachineStateListener(machine.select((state) => state.giftsRules), (rules) => {
    giftRulesMachine.changeRules(rules.isReadOnly, rules.value);
  });

  useMachineStateListener(rulesMachine, (state) => {
    machine.changeRules(state.rules);
  });

  useMachineStateListener(giftRulesMachine, (state) => {
    machine.changeGiftRules(state.rules);
  });

  const handleSubmit = () => {
    void savePromo(machine.state);
  };

  const isReadOnly = !isEmpty(promo?.code ?? "");
  const formState = methods.formState;

  return <>
    <Container disableGutters>
      <StyledHeading sx={{ paddingBottom: 0, marginBottom: 0 }}>
        <StyledContent sx={{ borderBottom: `1px solid ${theme.palette.divider}` }}>
          {isEdit ? <StoreIcon fontSize="large" /> : <AddBusinessIcon fontSize="large" />}
          <Typography
            className="heading"
            mb={0}
            variant="h4"
            gutterBottom
            component="div"
          >
            {isEdit ? t("edit_promo_title", { title: promo?.description ?? "" }) : t("create_promo_title")}
          </Typography>
          {isReadOnly ? <Alert sx={{ whiteSpace: "nowrap" }} severity="info">{t("read_only")}</Alert> : null }
        </StyledContent>
      </StyledHeading> 
      <FormProvider {...methods}>
        <StyledForm
          onSubmit={methods.handleSubmit(handleSubmit)}
          sx={{
            backgroundColor: isReadOnly ? theme.palette.background.paper : "",
            pointerEvents: isReadOnly ? "none" : "default",
          }}
        >
          <Stack spacing={2} sx={{ mb: "2rem" }}>
            <CommonForm />
            <ApplyPromotionFields />
          </Stack>
          {isGifts && <GiftsForm machine={giftRulesMachine} />}
          <ConditionsForm isGifts={isGifts} isPoints={isPoints} isTotal={isTotal} />
          <FilterItemsTable machine={rulesMachine} types={promoRuleModelTypes} />

          {errorOrFailure ? <Alert sx={{ margin: "2rem auto" }} severity="error">{AppUtils.getErrorMessage(errorOrFailure)}</Alert> : null}

          <StyledButtonsContainer>
            <Stack direction="row" spacing={2}>
              <Button
                disabled={!formState.isValid || isLoading || !canSave}
                type="submit"
                variant="contained"
              >
                {t("save_draft")}
              </Button>
              <Button
                onClick={activatePromo}
                disabled={!formState.isValid || isLoading || !canActivate}
                variant="outlined"
              >
                {t("save_active")}
              </Button>
            </Stack>
          </StyledButtonsContainer>
        </StyledForm>
      </FormProvider>
    </Container>
  </>;
};

const stateToFormValues = (state: PromoFormState): IFormValues => {
  return {
    promoName: state.name.value,
    promoType: state.type.value,
    promoAmountType: state.amount.type,
    promoAmount: state.amount.value,
    applyToPromoType: state.applyTo.type,
    repeatArticlesCheck: state.repeatArticles.isChecked,
    repeatAmountsCheck: state.repeatAmounts.isChecked,
    repeatArticles: state.repeatArticles.data.value,
    repeatAmounts: state.repeatAmounts.data.value,
    repeatAmountsRules: state.repeatAmounts.data.rules,
    repeatArticlesRules: state.repeatArticles.data.rules,
    minTotalExpenseCheck: state.minTotalExpense.isChecked,
    minTotalExpense: state.minTotalExpense.data.value,
    minTotalExpenseRules: state.minTotalExpense.data.rules,
    loyaltyPartner: state.loyaltyPartner.value,
    proposed: state.proposed.value,
    minTotalArticlesCheck: state.minTotalArticles.isChecked,
    minTotalArticles: state.minTotalArticles.data.value,
    minTotalArticlesRules: state.minTotalArticles.data.rules,
    giftsRules: state.giftsRules.value,
  };
};

