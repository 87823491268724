import LogoutIcon from "@mui/icons-material/Logout";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { Divider, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Toolbar } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../shared/hooks/authentication/useAuth";
import { Pages } from "../../shared/pages";
import { AppRoutes } from "../routes/AppRoutes";
import { Env } from "../../shared/Env";
import { StyledAuthenticatedContent, StyledTypography } from "./AuthenticatedArea.style";
import logoImg from "../../../img/sinnefo_logo.png";
import { Store } from "@mui/icons-material";
import { SettingsMenu } from "./SettingsMenu";

export const DRAWER_WIDTH = 250;
export const AuthenticatedArea = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation("pages");
  const { signOut, isLoading } = useAuth();

  const getActiveRouteColor = (page: string) => {
    if (location.pathname === page) {
      return "primary";
    }
  };

  return (
    <>
      <Drawer
        anchor="left"
        open={true}
        variant="permanent"
        sx={{
          width: DRAWER_WIDTH,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: DRAWER_WIDTH,
            boxSizing: "border-box",
            flexDirection: "column",
            justifyContent: "space-between",
          },
        }}>
        <Toolbar style={{ minHeight: "74px", backgroundColor: "white", boxShadow: "0px 0px 4px rgba(0,0,0,0.1)" }}>
          <img
            src={logoImg}
            loading="lazy"
            style={{ width: "100%", padding: "1rem" }}
          />
        </Toolbar>
        <Divider />
        <List>
          <ListItemButton 
            onClick={() => navigate(Pages.home)} 
            selected={Pages.home === location.pathname || location.pathname.includes("promotion")}
          >
            <ListItemIcon>
              <Store color={getActiveRouteColor(Pages.home) || getActiveRouteColor(Pages.promoCreate)} />
            </ListItemIcon>
            <ListItemText>{t(Pages.routeHome)}</ListItemText>
          </ListItemButton>
          <ListItemButton onClick={() => navigate(Pages.calendar)} selected={location.pathname === Pages.calendar}>
            <ListItemIcon>
              <CalendarMonthIcon color={getActiveRouteColor(Pages.calendar)} />
            </ListItemIcon>
            <ListItemText>{t(Pages.routeCalendar)}</ListItemText>
          </ListItemButton>
          <ListItemButton onClick={() => navigate(Pages.groups)} selected={location.pathname === Pages.groups}>
            <ListItemIcon>
              <LibraryBooksIcon color={getActiveRouteColor(Pages.groups)} />
            </ListItemIcon>
            <ListItemText>{t("/groups")}</ListItemText>
          </ListItemButton>
        </List>
        <Divider />
        <SettingsMenu />
        <span style={{ flex: 1 }} />
        <Divider />
        <List>
          <ListItem button onClick={signOut} disabled={isLoading}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText>{t("signOut_drawerItem")}</ListItemText>
          </ListItem>
        </List>

      </Drawer>
      <StyledAuthenticatedContent>
        <div className="content">
          <AppRoutes />
        </div>
        <StyledTypography align="right" gutterBottom={true}>
          <code>v {Env.appVersion}</code>
        </StyledTypography>
      </StyledAuthenticatedContent>
    </>
  );
};
