/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LicenseCollectionResponse } from '../models/LicenseCollectionResponse';
import type { LicenseExceptionPatch } from '../models/LicenseExceptionPatch';
import type { LicensePost } from '../models/LicensePost';
import type { LicenseResponse } from '../models/LicenseResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AdminApiToManageOrganizationLicenseService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Reset start exception in license organization
   * @returns void
   * @throws ApiError
   */
  public resetException({
    organizationId,
  }: {
    organizationId: number,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/admin/license/{organizationID}',
      path: {
        'organizationID': organizationId,
      },
      errors: {
        400: `Bad Request`,
        401: `Failed to decode authentication token`,
        404: `Entity Not found`,
        405: `Method Not Allowed`,
        409: `Data integrity violation`,
        417: `Expectation Failed`,
        500: `General I/O error`,
      },
    });
  }

  /**
   * Create or update a license in the organization
   * @returns LicenseResponse OK
   * @throws ApiError
   */
  public post5({
    organizationId,
    requestBody,
  }: {
    organizationId: number,
    requestBody: LicensePost,
  }): CancelablePromise<LicenseResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/admin/license/{organizationID}',
      path: {
        'organizationID': organizationId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Failed to decode authentication token`,
        404: `Entity Not found`,
        405: `Method Not Allowed`,
        409: `Data integrity violation`,
        417: `Expectation Failed`,
        500: `General I/O error`,
      },
    });
  }

  /**
   * Revoke license in organization
   * @returns void
   * @throws ApiError
   */
  public deactivate({
    organizationId,
  }: {
    organizationId: number,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/admin/license/{organizationID}',
      path: {
        'organizationID': organizationId,
      },
      errors: {
        400: `Bad Request`,
        401: `Failed to decode authentication token`,
        404: `Entity Not found`,
        405: `Method Not Allowed`,
        409: `Data integrity violation`,
        417: `Expectation Failed`,
        500: `General I/O error`,
      },
    });
  }

  /**
   * Update license exception in organization
   * @returns LicenseResponse OK
   * @throws ApiError
   */
  public patchException({
    organizationId,
    requestBody,
  }: {
    organizationId: number,
    requestBody: LicenseExceptionPatch,
  }): CancelablePromise<LicenseResponse> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/admin/license/{organizationID}',
      path: {
        'organizationID': organizationId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Failed to decode authentication token`,
        404: `Entity Not found`,
        405: `Method Not Allowed`,
        409: `Data integrity violation`,
        417: `Expectation Failed`,
        500: `General I/O error`,
      },
    });
  }

  /**
   * Get all licenses and organizations
   * @returns LicenseCollectionResponse OK
   * @throws ApiError
   */
  public get11(): CancelablePromise<LicenseCollectionResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/admin/licenses',
      errors: {
        400: `Bad Request`,
        401: `Failed to decode authentication token`,
        404: `Entity Not found`,
        405: `Method Not Allowed`,
        409: `Data integrity violation`,
        417: `Expectation Failed`,
        500: `General I/O error`,
      },
    });
  }

}