/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ActivePromotionResponse } from '../models/ActivePromotionResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class UserApiToManagePromotionsService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get all active promotions in organization
   * @returns ActivePromotionResponse OK
   * @throws ApiError
   */
  public get1({
    licenseKey,
    organizationId,
    pointOfSaleCode,
    daysToAdd,
  }: {
    licenseKey: string,
    organizationId: number,
    pointOfSaleCode: string,
    daysToAdd?: number,
  }): CancelablePromise<ActivePromotionResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/user/organization/{organizationID}/active',
      path: {
        'organizationID': organizationId,
      },
      headers: {
        'license-key': licenseKey,
      },
      query: {
        'pointOfSaleCode': pointOfSaleCode,
        'daysToAdd': daysToAdd,
      },
      errors: {
        400: `Bad Request`,
        401: `Failed to decode authentication token`,
        404: `Entity Not found`,
        405: `Method Not Allowed`,
        409: `Data integrity violation`,
        417: `Expectation Failed`,
        500: `General I/O error`,
      },
    });
  }

}