import {
  ManagerApiToManagePromotionsService, PromotionPeriodCollectionResponse, PromotionPeriodPatch,
  PromotionPeriodPost,
  PromotionPeriodResponse,
} from "../../../generated/api";
import { isApiClientError } from "../../../utils/AppClientUtils";

export class PromoPeriodRepository {
  constructor(private readonly service: ManagerApiToManagePromotionsService) {}

  save(
    organizationId: number,
    promotionId: number,
    periodId: undefined | number,
    { requestBody }: {requestBody: PromotionPeriodPost},
  ): Promise<PromotionPeriodResponse> {
    return this.service.postPromotionPeriod({
      organizationId,
      promotionId,
      promotionPeriodId: periodId,
      requestBody,
    });
  }

  async active(organizationId: number, promoPeriodId: number): Promise<void> {
    await this.service.activePromotionPeriod({
      organizationId,
      promotionPeriodId: promoPeriodId,
    });
  }

  async deactivate(organizationId: number, promoPeriodId: number): Promise<void> {
    await this.service.deactivatePromotionPeriod({
      organizationId,
      promotionPeriodId: promoPeriodId,
    });
  }

  delete(organizationId: number, promoPeriodId: number): Promise<void> {
    return this.service.deletePromotionPeriod({
      organizationId,
      promotionPeriodId: promoPeriodId,
    });
  }

  async readAll(organizationId: number, params: {active?: boolean; futureActive?: boolean}): Promise<PromotionPeriodCollectionResponse> {
    try {
      return await this.service.getPromotionPeriodsOfPromotion({
        organizationId,
        active: params.active,
        includeFutureActive: params.futureActive,
      });
    } catch (error) {
      if (!isApiClientError(error)) throw error;
      switch (error.status) {
      case 404: // list is empty
        return { promotionPeriods: [] };
      default:
        throw error;
      }
    }
  }

  readAllByPromo(organizationId: number, promotionId: number): Promise<PromotionPeriodCollectionResponse> {
    return this.service.getPromotionPeriods({ organizationId, promotionId });
  }

  async updateActiveOrder(organizationId: number, periods: Array<PromotionPeriodPatch>): Promise<void> {
    await this.service.patch2({ organizationId, requestBody: { newPriorities: periods } });
  }
}
