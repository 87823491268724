/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AllowedPromotionResponse } from './AllowedPromotionResponse';
import type { PGInterval } from './PGInterval';

export type LicenseResponse = {
  organizationID: number;
  expirationDate: number;
  availableLicenses: number;
  maxRequestPerDay: number;
  intervalAfterExpiration: PGInterval;
  allowMoreRequestAfterExpiration: boolean;
  onlyTodayAfterExpiration: boolean;
  automaticRenewal: boolean;
  elapsedMonths: number;
  expirationType: LicenseResponse.expirationType;
  valid: boolean;
  allowedPromotions?: Array<AllowedPromotionResponse>;
};

export namespace LicenseResponse {

  export enum expirationType {
    REQUEST = 'REQUEST',
    DATE = 'DATE',
    NUMBER = 'NUMBER',
    MANUAL = 'MANUAL',
  }


}
