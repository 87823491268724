import {
  ManagerApiToManageOrganizationTreeService,
  OrganizationResponse,
  OrganizationTreeResponse,
  UserApiToManageOrganizationsService,
} from "../../../generated/api";

export class OrganizationsRepository {
  constructor(
    private readonly organizationsService: UserApiToManageOrganizationsService,
    private readonly pointsOfSaleService: ManagerApiToManageOrganizationTreeService,
  ) {}

  async read(organizationId: number): Promise<OrganizationResponse> {
    return this.organizationsService.getOrganization({ organizationId });
  }
  async readPointsOfSale(organizationId: number): Promise<OrganizationTreeResponse> {
    return this.pointsOfSaleService.getTreeTopBottom({ organizationId });
  }
}
