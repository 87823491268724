import { Route, Routes } from "react-router-dom";
import React from "react";
import { Pages } from "../../shared/pages";
import { PromotionsTable } from "../promotions/promotions-table/PromotionsTable";
import { PeriodsPage } from "../promotions/periods/PeriodsPage";
import { PromotionPage } from "../promotions/create-promotion/PromotionPage";
import { GroupsPage } from "../groups/GroupsPage";

export const AppRoutes: React.FC = () => {
  return <>
    <Routes>
      <Route path={Pages.routeHome} element={<PromotionsTable />} />
      <Route path={Pages.routeCalendar} element={<PeriodsPage />} />
      <Route path={Pages.routePromoCreate} element={<PromotionPage />} />
      <Route path={Pages.routePromoEdit} element={<PromotionPage />} />
      <Route path={Pages.groups} element={<GroupsPage />} />
    </Routes>
  </>;
};
