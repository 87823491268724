import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { DRAWER_WIDTH } from "./AuthenticatedArea";

export const StyledAuthenticatedContent = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  height: "100vh",
  marginLeft: `${DRAWER_WIDTH}px`,
  ".content" : {
    flex: 1,
    overflow: "auto",
  },
}));

export const StyledTypography = styled(Typography)(() => ({
  fontSize: 10,
  padding: "1em",
  position: "absolute",
  bottom: 0,
  right: "20px",
  backgroundColor: "#fff",
}));
