import { UserResponse } from "../../../generated/api";
import { AppError } from "../../../shared/failures/AppError";
import { InsufficientAuthorityFailure } from "../../../shared/failures/Failure";
import { MissingOrganizationFailure } from "../failures/Failures";
import { UserMappings } from "../mappings/UserMappings";
import { UserModel } from "../models/UserModel";
import { UsersRepository } from "../repositories/UsersRepository";
import { isNullish } from "../../../../utils/Functions";

export class UserActions {
  constructor(private readonly usersRepository: UsersRepository) {}

  async readCurrent(): Promise<UserModel> {
    const userResponse = await this.usersRepository.readCurrent();

    switch (userResponse.authority) {
    case UserResponse.authority.ADMIN:
    case UserResponse.authority.MANAGER: {
      const organizationId = userResponse.organizationID;

      if (isNullish(organizationId)) {
        throw new AppError(new MissingOrganizationFailure());
      }

      return UserMappings.responseToModel(userResponse, organizationId);
    }
    case UserResponse.authority.SERVICE:
    case UserResponse.authority.USER:
      throw new AppError(new InsufficientAuthorityFailure());
    }
  }
}
