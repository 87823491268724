import { CircularProgress, Box } from "@mui/material";

export const WrappedLoader = () => {
  return (
    <Box sx={{
      display: "grid",
      placeItems: "center",
      width: "100%",
      height: "100%",
      minHeight: "50vh",
    }}>
      <CircularProgress />
    </Box>
  );
};
