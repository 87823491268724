import {
  ManagerApiToManagePromotionsService,
  PromotionCollectionResponse, PromotionPost, PromotionResponse,
} from "../../../generated/api";

export class PromoRepository {
  constructor(private readonly service: ManagerApiToManagePromotionsService) {}

  async read(organizationId: number, promotionId: number): Promise<PromotionResponse> {
    return this.service.get4({ organizationId, promotionId });
  }

  readAll(organizationId: number, { status }: {status?: boolean} = {}): Promise<PromotionCollectionResponse> {
    return this.service.get3({ organizationId, status });
  }

  async activate(organizationId: number, promotionId: number): Promise<void> {
    await this.service.activePromotion({
      organizationId,
      promotionId,
    });
  }

  async deactivate(organizationId: number, promotionId: number): Promise<void> {
    await this.service.deactivatePromotion({
      organizationId,
      promotionId,
    });
  }

  async save(
    organizationId: number,
    promotionId: undefined | number,
    { requestBody }: {requestBody: PromotionPost}
  ): Promise<PromotionResponse> {
    return this.service.post({ organizationId, promotionId, requestBody }); 
  }
}