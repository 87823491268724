import { Box, Collapse, Divider, FormControl, InputLabel, MenuItem, Select, Stack, TextField } from "@mui/material";
import { isEmpty } from "ramda";
import { Controller, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  promoApplyToModelTypes,
  PromoRuleModelItemType,
  promoRuleModelItemTypes,
} from "../../../../../../domain/features/promotions/models/PromoTypes";
import { RulesFormMachine } from "../../../../../../domain/features/promotions/state-machines/RulesFormMachine";
import { ExpandFiltersButton } from "../../../../../shared/expand-button/ExpandButton";
import { useValueChanges } from "../../../../../shared/hooks/basic/UseValueChanges";
import { useMachineStateListener } from "../../../../../shared/hooks/useMachineStateListener";
import { useMachineStateSelector } from "../../../../../shared/hooks/useMachineStateSelector";
import { useStateMachineCreator } from "../../../../../shared/hooks/useStateMachineCreator";
import { useToggler } from "../../../../../shared/hooks/useToggler";
import { usePromoStore } from "../../stores/PromoStore";
import { IFormValues } from "../../types";
import { FilterItemsTable } from "../filter-items-table/FilterItemsTable";

export const ApplyPromotionFields = () => {
  const { t } = useTranslation("createPromo");
  const { machine } = usePromoStore();
  const { isOpen, toggle } = useToggler();
  const state = useMachineStateSelector(machine.select((state) => {
    // if filter has items inside and mode is read only -> open table
    const mustShowRules = !isEmpty(state.applyTo.rules) && state.applyTo.isReadOnly;
    return {
      isPoints: state.type.value === "POINTS",
      isReadonly: state.applyTo.isReadOnly,
      isVisible: state.applyTo.isVisible,
      isRulesEditable: state.applyTo.canSelect,
      mustShowRules,
      filters: state.applyTo.rules.length,
    };
  }));

  const applyToPromoType = useWatch<IFormValues, "applyToPromoType">({ name: "applyToPromoType" });
  const promoAmount = useWatch<IFormValues, "promoAmount">({ name: "promoAmount" });

  const rulesMachine = useStateMachineCreator(() => {
    const type = machine.state.applyTo.type;
    return new RulesFormMachine<PromoRuleModelItemType>({
      isReadOnly: state.isReadonly,
      initialType: promoApplyToModelTypes[0],
      initialRules: type === "ENTIRE_RECEIPT" ? [] : machine.state.applyTo.rules.map((rule) => ({
        ...rule,
        type,
      })),
    });
  });

  useValueChanges(applyToPromoType, (value) => {
    machine.changeApplyToType(value);
  }, [ machine ]);

  useValueChanges(promoAmount, (value) => {
    machine.changeAmountValue(value);
  }, [ machine ]);

  useMachineStateListener(machine.select((state) => {
    return {
      isReadOnly: state.applyTo.isReadOnly,
      type: state.applyTo.type,
      rules: state.applyTo.rules,
    };
  }), (data) => {
    const applyType = data.type;
    const rules = applyType !== "ENTIRE_RECEIPT" ? data.rules.map((rule) => {
      return { ...rule, type: applyType };
    }) : [];
    rulesMachine.changeRules(data.isReadOnly, rules);
  });

  useMachineStateListener(rulesMachine, (state) => {
    machine.changeApplyToRules(state.rules);
  });

  if (state?.isVisible === false) {
    return null;
  }

  return (
    <Stack alignItems="start" sx={{ width: "100%" }} style={{ marginTop: "1.5rem" }}>
      <Stack alignItems="center" direction="row">
        {state.isPoints && 
        <>
          <Controller 
            name="promoAmount"
            defaultValue={0}
            render={({ field }) => <TextField 
              {...field} 
              sx={{ width: "120px" }}  
              type="number" 
              size="small" 
              label={t("quantity")}
            />}
          />
          <Divider orientation="vertical" flexItem sx={{ mr: 2 }} />
        </>
        }
        <Controller<IFormValues>
          name="applyToPromoType"
          render={({ field }) => {
            const label = state.isPoints ? t("accelerators") : t("apply_promo_to");
            return <FormControl sx={{ minWidth: "200px", marginRight: "1rem" }}>
              <InputLabel>{label}</InputLabel>
              <Select
                size="small"
                label={label}
                readOnly={state.isReadonly}
                {...field}
              >
                {promoApplyToModelTypes
                  .filter((item) => state.isPoints ? item !== "ENTIRE_RECEIPT" : item)
                  .map((item, i) => 
                    <MenuItem key={i} value={item}>
                      {t(`apply_${item}`)}
                    </MenuItem>
                  )}
              </Select>
            </FormControl>;
          }}
        />
        {applyToPromoType !== "ENTIRE_RECEIPT" &&
        <ExpandFiltersButton
          disabled={state.isReadonly}
          onClick={toggle}
          isOpen={isOpen}
          filtersLength={state.filters}
        />}
      </Stack>
      {applyToPromoType !== "ENTIRE_RECEIPT" && 
      <Collapse in={isOpen || state.mustShowRules} unmountOnExit appear={false} sx={{ width: "100%" }}>
        <Box sx={{ mt: "1rem", width: "100%" }}>
          <FilterItemsTable
            currentType={applyToPromoType}
            types={promoRuleModelItemTypes}
            machine={rulesMachine}
            isPoints={state.isPoints}
            withoutArticleField
          />
        </Box>
      </Collapse>}
    </Stack>
  );
};
