/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { RulePost } from './RulePost';

export type DiscountPost = {
  discount: number;
  max?: number;
  rules: Array<RulePost>;
  dtype: DiscountPost.dtype;
};

export namespace DiscountPost {

  export enum dtype {
    AMOUNT = 'AMOUNT',
    PERCENTAGE = 'PERCENTAGE',
    FORCED_PRICE = 'FORCED_PRICE',
  }


}
