/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BridgeCollectionResponse } from '../models/BridgeCollectionResponse';
import type { OrganizationCollectionResponse } from '../models/OrganizationCollectionResponse';
import type { OrganizationPost } from '../models/OrganizationPost';
import type { OrganizationResponse } from '../models/OrganizationResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AdminApiToManageOrganizationService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Add users to organization
   * @returns void
   * @throws ApiError
   */
  public put6({
    organizationId,
    emails,
  }: {
    organizationId: number,
    emails: Array<string>,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/admin/organization/{organizationID}/user',
      path: {
        'organizationID': organizationId,
      },
      query: {
        'emails': emails,
      },
      errors: {
        400: `Bad Request`,
        401: `Failed to decode authentication token`,
        404: `Entity Not found`,
        405: `Method Not Allowed`,
        409: `Data integrity violation`,
        417: `Expectation Failed`,
        500: `General I/O error`,
      },
    });
  }

  /**
   * Remove users from organization
   * @returns void
   * @throws ApiError
   */
  public delete4({
    organizationId,
    emails,
  }: {
    organizationId: number,
    emails: Array<string>,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/admin/organization/{organizationID}/user',
      path: {
        'organizationID': organizationId,
      },
      query: {
        'emails': emails,
      },
      errors: {
        400: `Bad Request`,
        401: `Failed to decode authentication token`,
        404: `Entity Not found`,
        405: `Method Not Allowed`,
        409: `Data integrity violation`,
        417: `Expectation Failed`,
        500: `General I/O error`,
      },
    });
  }

  /**
   * Create a new organization
   * @returns OrganizationResponse OK
   * @throws ApiError
   */
  public post4({
    requestBody,
  }: {
    requestBody: OrganizationPost,
  }): CancelablePromise<OrganizationResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/admin/organization',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Failed to decode authentication token`,
        404: `Entity Not found`,
        405: `Method Not Allowed`,
        409: `Data integrity violation`,
        417: `Expectation Failed`,
        500: `General I/O error`,
      },
    });
  }

  /**
   * Find tenant
   * @returns void
   * @throws ApiError
   */
  public postSyncList({
    tenant,
  }: {
    tenant: string,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/admin/organization/{tenant}/syncTenant',
      path: {
        'tenant': tenant,
      },
      errors: {
        400: `Bad Request`,
        401: `Failed to decode authentication token`,
        404: `Entity Not found`,
        405: `Method Not Allowed`,
        409: `Data integrity violation`,
        417: `Expectation Failed`,
        500: `General I/O error`,
      },
    });
  }

  /**
   * Create a new organization and request tenant to bridge
   * @returns OrganizationResponse OK
   * @throws ApiError
   */
  public postOrganizationBridge({
    requestBody,
  }: {
    requestBody: OrganizationPost,
  }): CancelablePromise<OrganizationResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/admin/organization/bridge',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Failed to decode authentication token`,
        404: `Entity Not found`,
        405: `Method Not Allowed`,
        409: `Data integrity violation`,
        417: `Expectation Failed`,
        500: `General I/O error`,
      },
    });
  }

  /**
   * Deactivate organization
   * @returns void
   * @throws ApiError
   */
  public delete3({
    organizationId,
  }: {
    organizationId: number,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/admin/organization/{organizationID}',
      path: {
        'organizationID': organizationId,
      },
      errors: {
        400: `Bad Request`,
        401: `Failed to decode authentication token`,
        404: `Entity Not found`,
        405: `Method Not Allowed`,
        409: `Data integrity violation`,
        417: `Expectation Failed`,
        500: `General I/O error`,
      },
    });
  }

  /**
   * Active organization
   * @returns void
   * @throws ApiError
   */
  public patch5({
    organizationId,
  }: {
    organizationId: number,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/admin/organization/{organizationID}',
      path: {
        'organizationID': organizationId,
      },
      errors: {
        400: `Bad Request`,
        401: `Failed to decode authentication token`,
        404: `Entity Not found`,
        405: `Method Not Allowed`,
        409: `Data integrity violation`,
        417: `Expectation Failed`,
        500: `General I/O error`,
      },
    });
  }

  /**
   * Get all organizations
   * @returns OrganizationCollectionResponse OK
   * @throws ApiError
   */
  public get10(): CancelablePromise<OrganizationCollectionResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/admin/organizations',
      errors: {
        400: `Bad Request`,
        401: `Failed to decode authentication token`,
        404: `Entity Not found`,
        405: `Method Not Allowed`,
        409: `Data integrity violation`,
        417: `Expectation Failed`,
        500: `General I/O error`,
      },
    });
  }

  /**
   * Get all bridges of organization
   * @returns BridgeCollectionResponse OK
   * @throws ApiError
   */
  public getBridges({
    organizationId,
  }: {
    organizationId: number,
  }): CancelablePromise<BridgeCollectionResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/admin/organization/{organizationID}/bridges',
      path: {
        'organizationID': organizationId,
      },
      errors: {
        400: `Bad Request`,
        401: `Failed to decode authentication token`,
        404: `Entity Not found`,
        405: `Method Not Allowed`,
        409: `Data integrity violation`,
        417: `Expectation Failed`,
        500: `General I/O error`,
      },
    });
  }

  /**
   * Deactivate subscriptions for organization
   * @returns void
   * @throws ApiError
   */
  public deactivateSubscriptions({
    organizationId,
  }: {
    organizationId: number,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/admin/organization/{organizationID}/deactivateSubscriptions',
      path: {
        'organizationID': organizationId,
      },
      errors: {
        400: `Bad Request`,
        401: `Failed to decode authentication token`,
        404: `Entity Not found`,
        405: `Method Not Allowed`,
        409: `Data integrity violation`,
        417: `Expectation Failed`,
        500: `General I/O error`,
      },
    });
  }

  /**
   * Activate subscriptions for organization
   * @returns void
   * @throws ApiError
   */
  public activateSubscriptions({
    organizationId,
  }: {
    organizationId: number,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/admin/organization/{organizationID}/activateSubscriptions',
      path: {
        'organizationID': organizationId,
      },
      errors: {
        400: `Bad Request`,
        401: `Failed to decode authentication token`,
        404: `Entity Not found`,
        405: `Method Not Allowed`,
        409: `Data integrity violation`,
        417: `Expectation Failed`,
        500: `General I/O error`,
      },
    });
  }

}