/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { FetchHttpRequest } from './core/FetchHttpRequest';

import { AdminApiToManageBridgeConnectionService } from './services/AdminApiToManageBridgeConnectionService';
import { AdminApiToManageOrganizationService } from './services/AdminApiToManageOrganizationService';
import { AdminApiToManageOrganizationLicenseService } from './services/AdminApiToManageOrganizationLicenseService';
import { AdminApiToManagePromotionsService } from './services/AdminApiToManagePromotionsService';
import { AdminApiToManageSynchronisationService } from './services/AdminApiToManageSynchronisationService';
import { AdminApiToManageUserService } from './services/AdminApiToManageUserService';
import { CommonApiToManageUsersService } from './services/CommonApiToManageUsersService';
import { ManagerApiToGetSynchronisationService } from './services/ManagerApiToGetSynchronisationService';
import { ManagerApiToManageBusinessPartnerService } from './services/ManagerApiToManageBusinessPartnerService';
import { ManagerApiToManageGroupService } from './services/ManagerApiToManageGroupService';
import { ManagerApiToManageItemsService } from './services/ManagerApiToManageItemsService';
import { ManagerApiToManageOrganizationLicenseService } from './services/ManagerApiToManageOrganizationLicenseService';
import { ManagerApiToManageOrganizationTreeService } from './services/ManagerApiToManageOrganizationTreeService';
import { ManagerApiToManagePromotionsService } from './services/ManagerApiToManagePromotionsService';
import { ManagerApiToManageUsersService } from './services/ManagerApiToManageUsersService';
import { ManagerApiToTestFileService } from './services/ManagerApiToTestFileService';
import { UserApiToManageOrganizationsService } from './services/UserApiToManageOrganizationsService';
import { UserApiToManagePromotionsService } from './services/UserApiToManagePromotionsService';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class AppClient {

  public readonly adminApiToManageBridgeConnection: AdminApiToManageBridgeConnectionService;
  public readonly adminApiToManageOrganization: AdminApiToManageOrganizationService;
  public readonly adminApiToManageOrganizationLicense: AdminApiToManageOrganizationLicenseService;
  public readonly adminApiToManagePromotions: AdminApiToManagePromotionsService;
  public readonly adminApiToManageSynchronisation: AdminApiToManageSynchronisationService;
  public readonly adminApiToManageUser: AdminApiToManageUserService;
  public readonly commonApiToManageUsers: CommonApiToManageUsersService;
  public readonly managerApiToGetSynchronisation: ManagerApiToGetSynchronisationService;
  public readonly managerApiToManageBusinessPartner: ManagerApiToManageBusinessPartnerService;
  public readonly managerApiToManageGroup: ManagerApiToManageGroupService;
  public readonly managerApiToManageItems: ManagerApiToManageItemsService;
  public readonly managerApiToManageOrganizationLicense: ManagerApiToManageOrganizationLicenseService;
  public readonly managerApiToManageOrganizationTree: ManagerApiToManageOrganizationTreeService;
  public readonly managerApiToManagePromotions: ManagerApiToManagePromotionsService;
  public readonly managerApiToManageUsers: ManagerApiToManageUsersService;
  public readonly managerApiToTestFile: ManagerApiToTestFileService;
  public readonly userApiToManageOrganizations: UserApiToManageOrganizationsService;
  public readonly userApiToManagePromotions: UserApiToManagePromotionsService;

  public readonly request: BaseHttpRequest;

  constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = FetchHttpRequest) {
    this.request = new HttpRequest({
      BASE: config?.BASE ?? 'https://promotion.test.sinnefo.eu/api',
      VERSION: config?.VERSION ?? 'test',
      WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
      CREDENTIALS: config?.CREDENTIALS ?? 'include',
      TOKEN: config?.TOKEN,
      USERNAME: config?.USERNAME,
      PASSWORD: config?.PASSWORD,
      HEADERS: config?.HEADERS,
      ENCODE_PATH: config?.ENCODE_PATH,
    });

    this.adminApiToManageBridgeConnection = new AdminApiToManageBridgeConnectionService(this.request);
    this.adminApiToManageOrganization = new AdminApiToManageOrganizationService(this.request);
    this.adminApiToManageOrganizationLicense = new AdminApiToManageOrganizationLicenseService(this.request);
    this.adminApiToManagePromotions = new AdminApiToManagePromotionsService(this.request);
    this.adminApiToManageSynchronisation = new AdminApiToManageSynchronisationService(this.request);
    this.adminApiToManageUser = new AdminApiToManageUserService(this.request);
    this.commonApiToManageUsers = new CommonApiToManageUsersService(this.request);
    this.managerApiToGetSynchronisation = new ManagerApiToGetSynchronisationService(this.request);
    this.managerApiToManageBusinessPartner = new ManagerApiToManageBusinessPartnerService(this.request);
    this.managerApiToManageGroup = new ManagerApiToManageGroupService(this.request);
    this.managerApiToManageItems = new ManagerApiToManageItemsService(this.request);
    this.managerApiToManageOrganizationLicense = new ManagerApiToManageOrganizationLicenseService(this.request);
    this.managerApiToManageOrganizationTree = new ManagerApiToManageOrganizationTreeService(this.request);
    this.managerApiToManagePromotions = new ManagerApiToManagePromotionsService(this.request);
    this.managerApiToManageUsers = new ManagerApiToManageUsersService(this.request);
    this.managerApiToTestFile = new ManagerApiToTestFileService(this.request);
    this.userApiToManageOrganizations = new UserApiToManageOrganizationsService(this.request);
    this.userApiToManagePromotions = new UserApiToManagePromotionsService(this.request);
  }
}
