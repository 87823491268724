import { Checkbox } from "@mui/material";
import { CheckboxProps } from "@mui/material/Checkbox/Checkbox";
import React from "react";

export interface CheckboxFieldProps extends CheckboxProps{
  value:boolean;
}

const CheckboxFieldComponent:React.ForwardRefRenderFunction<HTMLButtonElement, CheckboxFieldProps> = ({ value, ...params }, ref) => {
  return <Checkbox
    ref={ref}
    {...params}
    checked={value}
  />;
};

export const CheckboxField = React.forwardRef(CheckboxFieldComponent);
