import { withoutUndefined } from "../../../../utils/Functions";
import { log } from "../../../../utils/Logger";
import { UnsupportedMappingError } from "../../../shared/failures/MappingsError";
import { PromoMappings } from "../mappings/PromoMappings";
import { PromoModel } from "../models/PromoModel";
import { PromoSaveModel } from "../models/PromoSaveModel";
import { PromoRepository } from "../repositories/PromoRepository";

export class PromoActions {
  constructor(private readonly promoRepository: PromoRepository) {}

  async read(organizationId: number, promotionId: number): Promise<PromoModel> {
    const response = await this.promoRepository.read(organizationId, promotionId);

    return PromoMappings.responseToModel(response);
  }

  /**
   * @param organizationId
   * @param status If true, it returns only active promotions
   */
  async readAll(organizationId: number, { status }: {status?: boolean} = {}): Promise<Array<PromoModel>> {
    const response = await this.promoRepository.readAll(organizationId, { status });

    const promotions = response.promotions;
    if (promotions === undefined) {
      return [];
    }

    return withoutUndefined(promotions.map((response) => {
      try {
        return PromoMappings.responseToModel(response);
      } catch (error) {
        if (UnsupportedMappingError.isMine(error)) {
          log.warn(`Unsupported promo '${response.id}' mapping`, error);
          return undefined;
        }
        throw error;
      }
    }));
  }

  async activate(organizationId: number, promotionId: number) {
    await this.promoRepository.activate(organizationId, promotionId);
  }

  async deactivate(organizationId: number, promotionId: number) {
    await this.promoRepository.deactivate(organizationId, promotionId);
  }

  /**
   * Create or update a promotion that has not yet been activated
   * @param organizationId
   * @param promotionId If it is not passed the promotion will be created
   * @param saveModel
   */
  async save(organizationId: number, promotionId: undefined | number, saveModel: PromoSaveModel): Promise<PromoModel> {
    const requestBody = PromoMappings.saveModelToResponse(saveModel);
    //console.log(requestBody);
    const response = await this.promoRepository.save(organizationId, promotionId, { requestBody });
    return PromoMappings.responseToModel(response);
  }

  // TODO: Implement
  // async saveActive(organizationId: number, promotionId: number, saveModel: PromoActiveSaveModel);
}
