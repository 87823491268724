import React, { Suspense } from "react";
import ReactDOM from "react-dom";

import reportWebVitals from "./reportWebVitals";
import { I18nextProvider } from "react-i18next";
import { i18nInstance } from "./i18n";
import { WrappedLoader } from "./presentation/shared/wrapped-loader/WrappedLoader";
import { LocalizedApp } from "./LocalizedApp";

ReactDOM.render(
  <Suspense fallback={<WrappedLoader />}>
    <I18nextProvider i18n={i18nInstance}>
      <LocalizedApp />
    </I18nextProvider>
  </Suspense>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
