/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LicenseResponse } from '../models/LicenseResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ManagerApiToManageOrganizationLicenseService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get license by organization
   * @returns LicenseResponse OK
   * @throws ApiError
   */
  public getByOrganizationId({
    organizationId,
  }: {
    organizationId: number,
  }): CancelablePromise<LicenseResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/manager/license/{organizationID}',
      path: {
        'organizationID': organizationId,
      },
      errors: {
        400: `Bad Request`,
        401: `Failed to decode authentication token`,
        404: `Entity Not found`,
        405: `Method Not Allowed`,
        409: `Data integrity violation`,
        417: `Expectation Failed`,
        500: `General I/O error`,
      },
    });
  }

}