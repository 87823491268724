import {
  Container,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { isEmpty } from "ramda";
import { useTranslation } from "react-i18next";

import { PromoPeriodModel } from "../../../../../domain/features/promotions/models/PromoPeriodModel";
import { AsyncVoidFunction } from "../../../../../utils/Functions";
import {
  StyledAlertSpacer,
  StyledTableCell,
  StyledTableContainer,
} from "../../promotions-table/PromotionsTable.styles";
import { InactivePeriodRow } from "./InactivePeriodRow";
import { PromoModel } from "../../../../../domain/features/promotions/models/PromoModel";
import React from "react";
import { PointOfSaleModel } from "../../../../../domain/features/organizations/models/PointOfSaleModel";

interface ActivePeriodsTableProps {
  periods: Array<PromoPeriodModel>;
  refetchPeriods: AsyncVoidFunction;
  promotions: Record<number, PromoModel>;
  pointsOfSale: undefined | PointOfSaleModel;
}

export const InactivePeriodsTable: React.FC<ActivePeriodsTableProps> = ({
  periods,
  refetchPeriods,
  promotions,
  pointsOfSale,
}) => {
  const { t } = useTranslation("promoPeriods");

  if (isEmpty(periods)) {
    return (
      <Typography p={4} align="center">
        {t("empty_list")}
      </Typography>
    );
  }

  return (
    <Container disableGutters maxWidth={false}>
      <StyledAlertSpacer />
      <StyledTableContainer style={{ height: window.innerHeight - 125, borderRight: 0, borderLeft: 0 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <StyledTableCell>{t("promo_code")}</StyledTableCell>
              <StyledTableCell>{t("promo_desc")}</StyledTableCell>
              <StyledTableCell>{t("valid_from")}</StyledTableCell>
              <StyledTableCell>{t("valid_until")}</StyledTableCell>
              <StyledTableCell><Typography noWrap={true} fontSize="small">{t("unit_org")}</Typography></StyledTableCell>
              <StyledTableCell>{t("operations")}</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {periods?.map((period) =>
              <InactivePeriodRow
                key={period.id}
                period={period}
                refetchPeriods={refetchPeriods}
                promotion={promotions[period.promotionId]}
                pointsOfSale={pointsOfSale}
              />
            )}
          </TableBody>
        </Table>
      </StyledTableContainer>
    </Container>
  );
};
