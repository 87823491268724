import React, { useEffect } from "react";
import TreeView from "@mui/lab/TreeView";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TreeItem from "@mui/lab/TreeItem";
import { Checkbox, FormControlLabel } from "@mui/material";
import { TreeUtils } from "../../../../../domain/utils/TreeUtils";
import { without } from "../../../../../utils/Array";
import { PointOfSaleModel } from "../../../../../domain/features/organizations/models/PointOfSaleModel";
import { uniq } from "ramda";

export interface CheckBoxTreeProps {
  pointsOfSale: undefined | PointOfSaleModel;
  disabled: boolean;
  selectedIds: number[];
  setSelectedIds?: React.Dispatch<React.SetStateAction<number[]>>;
  defaultExpanded?: string[];
  expanded?: boolean;
}

export class CheckBoxUtils {
  constructor(private __ids: number[]) {}
  isSelected(node: PointOfSaleModel) {
    return this.__ids.includes(node.id);
  }
  isEveryChildSelected(node: PointOfSaleModel) {
    if (node.children && node.children.length > 0) {
      return node.children.every((child) => this.isSelected(child));
    }
    return false;
  }
  isEveryChildUnselected(node: PointOfSaleModel) {
    if (node.children && node.children.length > 0) {
      return node.children.every((child) => !this.isSelected(child));
    }
    return true;
  }
  isSomeChildUnselected(node: PointOfSaleModel) {
    if (node.children && node.children.length > 0) {
      return node.children.some((child) => !this.isSelected(child));
    }
    return true;
  }
  isSomeChildSelectedDeep(node: PointOfSaleModel): boolean {
    if (node.children?.length) {
      const isSomeChildSelected = node.children.some((child) => this.__ids.includes(child.id));
      if (isSomeChildSelected) {
        return true;
      }
      return node.children.some((child) => this.isSomeChildSelectedDeep(child));
    }
    return false;
  }
  static allIds(node: PointOfSaleModel, ids: number[] = []) {
    if (!Number.isNaN(node.id)) {
      ids.push(node.id);
    }
    if (node.children && node.children.length > 0) {
      node.children.forEach((child) => this.allIds(child, ids));
    }
    return ids;
  }
}

export const CheckBoxTree: React.FC<CheckBoxTreeProps> = ({
  pointsOfSale,
  disabled,
  selectedIds,
  setSelectedIds,
  defaultExpanded,
  expanded,
}) => {
  const onCheckBoxChange = (isSelected: boolean, node: PointOfSaleModel) => {
    if (!setSelectedIds) {
      return;
    }
    const childrenIds = TreeUtils.findChildren(node);
    if (isSelected) {
      const newIds = uniq([ ...selectedIds, ...childrenIds, node.id ]);
      setSelectedIds(newIds);
    } else {
      setSelectedIds(without(selectedIds, node.id, ...childrenIds));
    }
  };
  const renderTree = (node: PointOfSaleModel) => {
    const utils = new CheckBoxUtils(selectedIds);
    const isIndeterminate = utils.isSomeChildSelectedDeep(node) && !utils.isEveryChildSelected(node);
    return (
      <TreeItem
        key={node.id}
        nodeId={node.id.toString()}
        label={
          <FormControlLabel
            control={<Checkbox
              size="small"
              checked={utils.isSelected(node)}
              indeterminate={isIndeterminate}
              onChange={(e, status) => onCheckBoxChange(status, node)}
              color={disabled ? "default" : "primary"}
              onClick={(e) => e.stopPropagation()}
              readOnly={disabled}
              disabled={disabled}
              disableRipple={disabled}
            />}
            label={<>{node.description}</>}
            key={node.id}
          />
        }
      >
        {Array.isArray(node.children)
          ? node.children.map((child) => renderTree(child))
          : null}
      </TreeItem>
    );
  };
  useEffect(() => {
    if (!setSelectedIds || !pointsOfSale) {
      return;
    }
    const utils = new CheckBoxUtils(selectedIds);
    CheckBoxUtils.allIds(pointsOfSale).forEach((id) => {
      const node = TreeUtils.findNode(pointsOfSale, id);
      if (node?.parentId) {
        const parent = TreeUtils.findNode(pointsOfSale, node.parentId);
        if (!parent) {
          return;
        }
        if (!utils.isSelected(parent) && utils.isEveryChildSelected(parent)) {
          setSelectedIds(uniq([ ...selectedIds, parent.id ]));
        }
        if (utils.isSelected(parent) && utils.isSomeChildUnselected(parent)) {
          setSelectedIds(without(selectedIds, parent.id));
        }
      }
    });
  }, [ selectedIds, pointsOfSale, setSelectedIds ]);

  return (
    <>
      <TreeView
        sx={{ direction: "ltr" }}
        defaultExpanded={defaultExpanded}
        defaultChecked={true}
        expanded={expanded ? CheckBoxUtils.allIds(pointsOfSale!).map((id) => String(id)) : undefined}
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
      >
        {pointsOfSale && renderTree(pointsOfSale)}
      </TreeView>
    </>
  );
};
